$iconsArray: (
    email: '\e028'
);

.icon {
    display: inline-block;
    position: relative;

    &::before {
        font-family: 'icons-default';
        font-style: normal;
        font-weight: $normal;
        font-variant: normal;
        line-height: 1;
        color: inherit;
        speak: none;
        font-smoothing: antialiased;
        text-transform: none;
    }

    @each $icon, $alias in $iconsArray {
        &-#{$icon}::before {
            content: $alias;
        }
    }
}
