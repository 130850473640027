// RESET
* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
}

*,
*:after,
*:before {
    box-sizing: inherit;
}

body {
    line-height: 1.3;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ul {
    list-style: none;
}

ol {
    list-style: decimal inside none;
}

blockquote,
q {
    quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: '';
    content: none;
}

.italic,
em,
i {
    font-style: italic;
}

.strong,
b,
strong {
    font-weight: $bold;
}

b em,
b i,
em b,
em strong,
i b,
i strong,
strong em,
strong i {
    font-weight: $bold;
    font-style: italic;
}

img {
    vertical-align: bottom;
	@include breakpoint(medium down) {
        max-width: 100%;
        height: auto;
	}
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
    &:focus, &:hover {
        text-decoration: underline;
    }
    img {
        border: 0;
    }
}

abbr,
acronym,
dfn {
    cursor: help;
    border-bottom: 1px dotted #000;
    a {
        display: inline !important;
    }
}

kbd {
    border-color: grey;
    border-style: solid;
    border-width: 1px 2px 2px 1px;
    padding: 0 0.4em;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    overflow: visible;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

input[type="button"] {
    vertical-align: middle;
    width: auto;
    border: none;
    //padding: 0; remove for specificity selector in .button-1 & 2
    cursor: pointer;
}

mark {
    background: yellow;
    color: #000;
}

iframe {
    border: none;
}

// Remove focus inner to Firefox
// http://stackoverflow.com/questions/11605875/how-to-style-button-inputs-to-be-identical-in-chrome-and-firefox
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
    padding: 0 !important;
    border: 0 none !important;
}
