// @name Powermail Form navigation
.powermail_tab_navigation.tx_powermail_pi1_formconfirmation_back {
    overflow: hidden;
    display: block;
    float: none;
    .button-1 {
        float: left;
        &.powermail_tab_navigation_next {
            float: right;
            &:before {
                //float: right;
                content: "\e027";
                //margin: 0 0 0 5px;
            }
        }
    }
}

// @name Powermail tabmenu
// @description override powermail stylesheet
.powermail_tabmenu {
    display: none;
    li {
        span {
            cursor: pointer;
            color: #000;
            &:hover {
                color: #00727e;
            }
        }
        .act {
            color: #00727e;
            text-decoration: underline;
        }
    }
}
