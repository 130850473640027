// @name Search box
// @description Search module
.search-box {
    //float: right;
    padding: 0;
    //@include breakpoint(medium down) {
    //    width: auto;
    //    margin-right: 4em;
    //}

    form {
        margin: 0;
    }
    label {
        font-family: $typo-2;
        font-size: 2rem;
        line-height: 1;
        font-weight: $bold;
        color: $color-3--3;
        white-space: nowrap;
        margin-right: em(1, 1.8);
    }
    input, select {
        font-size: 1.6rem;
        line-height: 2rem;
        color: rgba($color-black, .7);
        border: .1rem solid #c8c8c8;
    }

    .search-box__fields-wrapper,
    .search-box__button-wrapper {
        display: inline-block;
        vertical-align: bottom;
        //@include breakpoint(medium down) {
        //    display: table-cell;
        //    vertical-align: middle;
        //}
    }

    //.search-box__fields-wrapper {
        //@include breakpoint(medium down) {
        //        width: 100%;
        //}
    //}

    .search-box__button-wrapper {
        margin-left: 2rem;
        //@include breakpoint(medium down) {
        //    padding-left: 1em;
        //}

        .button-1 {
            width: 5rem;
            height: 5rem;
            line-height: 5rem;
            text-align: center;
            border-radius: 50%;
            padding: 0;
            background: $color-3--2;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -0.6rem 0.6rem 0 0 rgba($color-3--4, 0.17);
            @include breakpoint(large up) {
                width: 7.2rem;
                height: 7.2rem;
                line-height: 7.2rem;
                transition: none;
            }

            > svg {
                fill: $color-white;
                width: 1.6rem;
                height: 1.5rem;
                transition: all 300ms ease;
                @include breakpoint(large up) {
                    width: 1.8rem;
                    height: 2rem;
                }
            }

            &:hover, &:focus {
                background: $color-1--3;

                > svg {
                    fill: $color-white;
                }
            }
        }
    }

    // @name Search Field wrapper
    // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
    .search-box__field-wrapper {
        &.search-box__field-wrapper--in-line {
            display: table;
            width: 100%;
            label {
                display: table-cell;
                vertical-align: middle;
                text-align: right;
                padding-right: 1em;
            }
            input, select {
                display: table-cell;
                vertical-align: middle;
                //width: 100%;
                width: 40rem;
                //@include breakpoint(large up) {
                //    padding: 2.5rem 2rem;
                //}
            }
        }
    }
    // Drop down menu
    .ddm {
        position: static;
        > .ddm__title {
            width: 7.2rem;
            height: 7.2rem;

            .button-1 {
                width: 7.2rem;
                height: 7.2rem;
                line-height: 7.2rem;
                text-align: center;
                border-radius: 50%;
                padding: 0;
                background: $color-3--2;
                box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -0.6rem 0.6rem 0 0 rgba($color-3--4, 0.17);

                > svg {
                    fill: $color-3--4;
                    width: 1.8rem;
                    height: 2rem;
                    transition: all 300ms ease;
                }

                &:hover, &:focus {
                    background: $color-1--3;

                    > svg {
                        fill: $color-white;
                    }
                }
            }
        }

        > .ddm__sub-level {
            position: absolute;
            top: 3.3rem;
            right: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            height: calc(100% - 3.3rem);
            padding: 2rem 5.5rem 2rem 40rem;
            background: $color-white;
            //@include breakpoint(medium down) {
            //    z-index: 102;
            //    padding: 1em;
            //    background: $color-3--2;
            //}
        }

        &.ddm--active > .ddm__sub-level {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            //justify-content: flex-start;
            justify-content: flex-end;
        }
    }
}

// @name Nav Search
.nav__search {
    background: $color-white;
    padding: 3rem 2rem;
    width: 100%;
    border-bottom: .1rem solid $color-3--3;

    .search-box__fields-wrapper {
        width: calc(100% - 6rem);

        input {
            width: 100%;
        }
    }

    .search-box__button-wrapper {
        margin-left: 1rem;
    }
}

// @name Search list
$pertinence__font-size: 1.2;
.pertinence {
    font-size: #{$pertinence__font-size}em;
    span {
        font-size: em(1, 1.2);
    }
}
.pertinence__metter {
    display: inline-block;
    vertical-align: middle;
    width: em(10, $pertinence__font-size);
    height: em(1, $pertinence__font-size);
    padding: em(.2, $pertinence__font-size);
    background: $color-3--2;
    margin: 0 em(1, $pertinence__font-size);
    > span {
        display: block;
        height: em(.8, $pertinence__font-size);;
        background: $color-1--2;
    }
}
