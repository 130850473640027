.offer-form {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    margin: 0;
    @include breakpoint(medium down) {
        margin: 0 -2rem;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
    }
    @include breakpoint(small down) {
        margin: 0;
    }

    &__control {
        width: calc((100% - 6.7rem)/2);
        padding: 0 2.2rem .5rem 0;
        margin: 0;
        @include breakpoint(medium down) {
            width: 50%;
            padding: 0 2rem 4rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            padding: 0 0 1.5rem;
        }

        &-title {
            display: block;
            font-weight: $bold;
            font-size: 2.2rem;
            line-height: 1.2em;
            margin-bottom: 1.4rem;
            @include breakpoint(medium down) {
                font-size: 1.8rem;
            }
            @include breakpoint(small down) {
                font-size: 1.6rem;
                margin: .3rem 0 .6rem;
            }
        }

        input, select {
            font-size: 1.6rem;
            padding: 1.5rem 2.5rem;
            background-color: $color-white;
            border-color: $color-white;
            box-shadow: -0.8rem 0.8rem 0.8rem 0 rgba(0, 0, 0, 0.04);
            margin: 0;
            @include breakpoint(medium down) {
                font-size: 1.4rem;
            }
        }
    }

    &__valider {
        width: 6.7rem;
        height: 6.7rem;
        border-radius: 50%;
        background: $color-1--3;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.6rem 0.6rem 0 0 rgba(#100517, .21);
        transition: all 300ms ease;
        position: relative;
        overflow: hidden;
        @include breakpoint(small down) {
            width: 5rem;
            height: 5rem;
        }

        &:hover, &:focus {
            background: $color-3--3;
        }

        &:before {
            content: "\e057";
            font-family: "icons-default";
            font-size: 2rem;
            line-height: 1;
            color: $color-white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.offer-tabs {

    &__btn-wrapper {
        width: 100%;
        max-width: 71.6rem;
        height: 5.3rem;
        position: relative;
        z-index: 38;
        @include breakpoint(medium down) {
            max-width: 100%;
        }
        @include breakpoint(small down) {
            height: 4rem;
            padding: 0 3rem;
        }

        &:before {
            @include breakpoint(medium up) {
                content: "";
                display: block;
                width: 200rem;
                height: 100%;
                background-color: $color-3--1;
                box-shadow: inset 0 -.2rem .8rem 0 rgba($color-black, .1);
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -100rem;
                z-index: -1;
            }
        }

        .slick-arrow {
            display: block;
            z-index: 10;
            position: absolute;
            top: 50%;
            left: -1rem;
            width: 4rem;
            height: 4rem;
            background: $color-3--1;
            margin-top: -2rem;
            text-indent: -9999px;
            overflow: hidden;
            &:before {
                font-family: "icons-default";
                font-size: 1.4rem;
                line-height: 1;
                color: $color-1--3;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-indent: 0;
                transition: all 300ms ease;
            }
            &:hover:before, &:focus:before {
                color: $color-2--4;
            }
            &.slick-prev {
                &:before {
                    content: "\e026";
                }
            }
            &.slick-next {
                left: auto;
                right: -1rem;
                &:before {
                    content: "\e027";
                }
            }
            //&.slick-disabled {
            //    pointer-events: none;
            //    background: $color-3--5;
            //    &:before {
            //        color: $color-3--2;
            //    }
            //}
        }
    }

    &__btn-item {
        border-left: .1rem solid rgba($color-white, .5);
        border-right: .1rem solid rgba($color-white, .5);
        outline: none;
        @include breakpoint(small down) {
            border-left: none;
            border-right: none;
        }

        &.current .offer-tabs__btn {
            text-decoration: none;
            color: $color-2--4;
            background-color: $color-3--12;
        }
    }

    &__btn {
        display: block;
        height: 5.3rem;
        font-family: $typo-2;
        font-style: italic;
        font-weight: $bold;
        font-size: 1.8rem;
        line-height: 3.3rem;
        color: $color-1--3;
        text-transform: uppercase;
        text-align: center;
        padding: 1rem;
        transition: all 300ms ease;
        @include breakpoint(small down) {
            height: 4rem;
            font-size: 1.4rem;
            line-height: 2rem;
        }

        &:hover, &:focus, &.current {
            text-decoration: none;
            color: $color-2--4;
            background-color: $color-3--12;
        }
    }

    &__content {
        position: relative;
        z-index: 42;
        padding-top: 5.8rem;
        @include breakpoint(medium down) {
            padding-top: 4.7rem;
        }
        @include breakpoint(small down) {
            padding-top: 2.5rem;
        }
    }

}

.home-offer {
    padding: 7.5rem 0 4rem;
    @include breakpoint(medium down) {
        position: relative;
        padding: 4.5rem 0 4rem;
    }
    @include breakpoint(small down) {
        padding: 3.2rem 0 0;
        margin-bottom: 1rem;
    }

    &:before {
        @include breakpoint(medium down) {
            content: "";
            display: block;
            width: 150rem;
            height: calc(100% - 15rem);
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -75rem;
            z-index: -1;
            background: $color-3--5;
            box-shadow: 0 0 7rem 5rem rgba($color-3--5, 1);
        }
        @include breakpoint(small down) {
            height: calc(100% - 2.5rem);
            background: #f8f7fa;
            box-shadow: none;
        }
    }

    .section-title {
        margin: 0 0 4.6rem;
        @include breakpoint(medium down) {
            margin-bottom: 3rem;
        }
    }

    &__tabs {
        min-height: 15rem;
    }
}
