.contact-card {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 3rem;

    &__wrapper {
        margin-bottom: -3rem;
    }

    &__content {
        width: 43.5%;
        padding-right: 3rem;
        @include breakpoint(medium down) {
            width: 50%;
        }
        @include breakpoint(small down) {
            width: 100%;
            padding-right: 0;
        }
    }

    &__title {
        font-weight: $bold;
        font-size: 1.6rem;
        line-height: 1.2;
        color: $color-1--3;
        text-transform: uppercase;
        margin: 0 0 0.5rem;
    }

    &__address {
        font-weight: $light;
        font-size: 1.4rem;
        line-height: 1.25;
        color: $color-black;
        text-transform: uppercase;
        margin: 0;
    }

    &__tel {
        display: inline-block;
        vertical-align: top;
        font-weight: $light;
        font-size: 1.6rem;
        line-height: 1;
        letter-spacing: 0.1rem;
        color: $color-black;
        margin: 1rem 0 0 2.5rem;
        @include default-icons-absolute-before('\e02b', 1.2rem, $color-1--3, 0, inherit, inherit, -2.5rem);
    }

    &__control {
        margin-top: .2rem;
        @include breakpoint(small down) {
            margin-top: 1.8rem;
        }

        .button-1 {
            font-size: 1.6rem;
            line-height: 1.25;
            padding: 1.5rem 1.5rem 1.5rem 5.8rem;

            &:before {
                content: "\e02a";
                font-size: 1.3rem;
                line-height: 5rem;
                width: 5rem;
                height: 5rem;
                margin-top: -2.5rem;
            }
        }
    }
}
