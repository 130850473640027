// @name Main section heading
// @description Default css
$heading__h1__font-size: 4.5;
.heading {
    background: $color-3--5;
    padding: 6em 0 9em;
    position: relative;
    z-index: 5;
    @include breakpoint(medium down) {
        padding: 3em 0;
    }
    @include breakpoint(small down) {
        padding: 2.5em 0;
    }

    &:before {
        content: "";
        display: block;
        width: 46rem;
        height: calc(100% + 1.6rem);
        background-image: url(../Images/custom/bg/bg-h-letter-grey.svg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 46rem auto;
        position: absolute;
        top: -1.6rem;
        left: 50%;
        margin-left: 38rem;
        z-index: -1;
        @include breakpoint(medium down) {
            margin-left: 0;
            left: auto;
            right: -21.5rem;
        }
        @include breakpoint(medium down) {
            width: 36rem;
            height: calc(100% - 30%);
            background-size: 36rem auto;
            top: 30%;
            right: -18rem;
        }
    }

    .headding__wrapper-title {
        @extend .wrapper-main;
        margin-bottom: 2em;
    }
    .heading__wrapper {
        @extend .wrapper-main;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        z-index: 10;
        @include breakpoint(small down) {
            //padding: 0 10px;
            display: block;
        }
        > *:last-child {
            margin-bottom: 0;
        }
        .heading__wrapper-figure {
            flex-basis: auto;
        }
        .heading__wrap {
            flex-basis: 100%;
        }
    }
    .heading__figure {
        margin-right: 2em;
        display: table;
        @include breakpoint(small down) {
            width: 100%;
            float: none;
            margin-right: 0;
            margin-bottom: 2em;
        }
    }
    .heading__picture {
        display: block;
        margin-bottom: .5em;
        img {
            //max-width: 100%;
            height: auto;
        }
    }
    .heading__caption {
        display: table-caption;
        caption-side: bottom;
    }
    .page-title-wrapper {
        position: relative;
        padding-right: 5rem;
        h1, h2 {
            margin: 0;
        }
        .list__rss {
            top: .2rem;
            margin-top: 0;
            @include breakpoint(small down) {
                top: -.4rem;
            }
        }
    }
    h1 {
        font-family: $typo-1;
        font-weight: $thin;
        font-size: #{$heading__h1__font-size}em;
        line-height: 1.2;
        color: $color-3--4;
        @include breakpoint(small down) {
            font-size: 3.0em;
            line-height: em(3.2, 3);
        }
    }
    .page-title-wrapper + .teaser-2, h1 + .teaser-2 {
        margin-top: em(2, $teaser-2__font-size);
        @include breakpoint(medium down) {
            margin-top: em(1, 1.8);
        }
    }
    .page-title-wrapper + .status, h1 + .status {
        margin-top: em(1, $teaser-2__font-size);
    }
    .subtitle {
        $category__font-size: 1.8;
        font-size: #{$category__font-size}em;
        @include breakpoint(small down) {
            font-size: 1.6rem;
        }
    }
    .subtitle + h1, .subtitle + .page-title-wrapper {
        margin-top: em(0.7, $heading__h1__font-size);
    }
    .date {
        font-size: 1.4em;
    }
    .status {
        $status__font-size: 1.5;
        background: #fff;
        font-size: #{$status__font-size}em;
        margin-top: em(0.5, $status__font-size);
        display: inline-block;
        &.status--new {
            background: $color-1--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
        &.status--in-progress {
            background: $color-2--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
    }
    .heading__media {
        margin-top: 1em;
        padding-top: 1em;
        //border-top: 1px solid darken($color-3--1, 20%);
        border-top: 1px solid #c5c5c5;
        .date, .media {
            font-size: 1.6em;
        }
        .media {
            font-weight: $bold;
            border: none;
            & + .date {
                &:before {
                    content: " - ";
                    display: inline;
                    font-size: 1.2em;
                    color: $color-3--3;
                }
            }
        }
    }
    .information {
        color: $color-3--3;
        text-transform: uppercase;
        font-size: 1.4em;
        line-height: 1.2;
        margin: .5rem 0 .8rem;
    }
    // @name picture to right
    &.heading--picture-right {
        .heading__wrapper {
            .heading__wrapper-figure {
                order: 2;
            }
            .heading__wrap {
                order: 1;
            }
        }
        .heading__figure {
            margin-right: 0;
            margin-left: 2em;
            @include breakpoint(small down) {
                margin-left: 0;
            }
        }
    }
}

// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
            @include breakpoint(medium down) {
                width: auto;
                max-width: 100%;
            }
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper {
        display: flex;
        flex-wrap: inherit;
        @include breakpoint(small down) {
            display: block;
        }
    }
    .heading__figure {
        margin-right: 0;
    }
    .heading__figure-buttons-row {
        text-align: center;
    }
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: table-cell;
        vertical-align: top;
        @include breakpoint(small down) {
            display: block;
        }
    }
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
            @include breakpoint(medium down) {
                width: 235px;
            }
        }
    }
    .heading__wrapper-date {
        width: 16em;
        border-right: 1px solid $color-3--3;
        padding: 0 2rem;
        @include breakpoint(small down) {
            border-right: none;
            width: 100%;
            border-bottom: 1px solid $color-3--3;
            padding: 0 0 2rem;
            margin-bottom: 2em;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
        }

        .date-1 {
            width: 11rem;
            height: 11rem;
            border-radius: 50%;
            background: $color-1--3;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.6rem 0.6rem 0 0 rgba(#111212, 0.17);
            line-height: 10.5rem;
            margin: 0 auto 1.5rem;
            padding: 0;
            color: $color-white;
            @include breakpoint(small down) {
                margin: 0;
            }

            time {
                margin-top: .5rem;

                & + span + time:before {
                    color: $color-white;
                }

                &:only-of-type {
                    margin-top: 0;
                }
            }
        }

        .hour-place {
            @include breakpoint(small down) {
                max-width: calc(100% - 11rem);
                padding: 0 0 0 1rem;
            }
        }
    }
    .heading__wrapper-calendar-button {
        margin: 1em 1em 0;
        line-height: normal;
    }
    .heading__wrap {
        padding-left: 2em;
        @include breakpoint(small down) {
            padding-left: 0;
        }
    }
    .button-1 {
        margin-top: 1.2em;
        padding-right: 0;
        cursor: pointer;
        @include breakpoint(small down) {
            margin: 0 1rem 1.5rem;
        }
    }
}

// @name Heading single publications
// @dependency .heading
.heading--single-publications, .heading--single-public-market {
    .heading__picture {
        img {
            width: 220px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single directory
// @dependency .heading
.heading--single-directory {
    .list-infos {
        margin-top: 2em;
    }
    .list-infos__wrapper {
        .list-infos__listitems:first-child {
            flex: 1 1 0;
        }
    }
    .list-infos__item {
        padding-left: em(2, 1.6);
        font-size: 1.6em;
    }
}

