.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3em;
}

.ddm.ddm__content {
    margin: 4em 0 4em 0;
    .ddm__title {
        font-size: 1em;
        margin-bottom: 0;
    }
    .ddm__sub-level {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        //transition: all ease .2s;
    }
    &.ddm--active {
        .ddm__sub-level {
            padding: 3em 0 2em;
            max-height: 100%;
            //transition: all ease-in-out 1s;
        }
    }
    &:last-child .ddm__sub-level {
        padding-bottom: 0;
    }
}

.ddm__content {
    .ddm__button {
        display: block;
        width: calc(100% - 2.5rem);
        text-align: left;
        font-family: $typo-1;
        font-weight: $light;
        font-size: 2rem;
        line-height: 1em;
        color: $color-3--4;
        background: $color-2--6;
        padding: em(1.2, 2) em(4, 2) em(1.2, 2) em(2.3, 2);
        margin-right: 1rem;
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        @include breakpoint(small down) {
            width: 100%;
            padding: em(1.1, 2) em(5, 2) em(1.1, 2) em(1.3, 2);
        }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: -2.5rem;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            box-shadow: -.4rem 0 .8rem 0 rgba($color-black, .05), inset -0.4rem 0.4rem 0 0 rgba($color-2--5, 0.07);
            transform: translateY(-50%);
            background: $color-2--3;
            z-index: 1;
            transition: all 300ms ease;
            @include breakpoint(small down) {
                right: -1rem;
            }
        }
        @include default-icons-absolute-before('\e08a', 1.8rem, $color-black, 50%, -2.5rem, inherit, inherit);
        &:before {
            width: 5rem;
            height: 5rem;
            text-align: center;
            font-weight: $light;
            line-height: 5rem;
            z-index: 2;
            transform: translateY(-50%);
            @include breakpoint(small down) {
                right: -1rem;
            }
        }
        &:hover {
            background: $color-1--3;
            color: $color-white;

            &:before {
                color: $color-white;
            }

            &:after {
                background: $color-1--2;
                box-shadow: -.4rem 0 .8rem 0 rgba($color-black, .05), inset -0.4rem 0.4rem 0 0 rgba($color-2--5, 0.14);
            }
        }
    }
    &.ddm--active {
        .ddm__button {
            background: $color-1--3;
            color: $color-white;
            &:before {
                content: "\e023";
                color: $color-white;
            }
            &:after {
                background: $color-1--2;
                box-shadow: -.4rem 0 .8rem 0 rgba($color-black, .05), inset -0.4rem 0.4rem 0 0 rgba($color-2--5, 0.14);
            }

            &:hover {
                background: $color-2--6;
                color: $color-3--4;

                &:before {
                    color: $color-black;
                }

                &:after {
                    background: $color-2--3;
                    box-shadow: -.4rem 0 .8rem 0 rgba($color-black, .05), inset -0.4rem 0.4rem 0 0 rgba($color-2--5, 0.14);
                }
            }
        }
    }
}
