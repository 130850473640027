.annuaire-card {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 3rem;
    @include breakpoint(medium down) {
        flex-wrap: wrap;
    }

    &__wrapper {
        margin-bottom: -3rem;
    }

    &__content {
        width: 43.5%;
        flex-shrink: 0;
        padding-right: 3rem;
        @include breakpoint(medium down) {
            width: 50%;
        }
        @include breakpoint(small down) {
            width: 100%;
            padding-right: 0;
        }
    }

    &__title {
        font-weight: $bold;
        font-size: 1.6rem;
        line-height: 1.2;
        color: $color-2--3;
        text-transform: uppercase;
        margin: 0 0 0.5rem;
    }

    &__name-object, &__address {
        font-weight: $light;
        font-size: 1.4rem;
        line-height: 1.25;
        color: $color-black;
        margin: 0;
    }

    &__list-infos {
        //display: flex;
        //flex-wrap: nowrap;
        //justify-content: flex-start;
        flex: 1 1 auto;
        padding-right: 2rem;
        overflow: hidden;
        @include breakpoint(medium down) {
            width: 50%;
            padding-right: 0;
        }
        @include breakpoint(small down) {
            width: 100%;
            margin-top: 1.3rem;
        }

        .list-infos__item:first-child {
            margin-top: 0.3rem;
        }
    }

    &__control {
        margin-top: .3rem;
        font-size: 1.6rem;
        line-height: 1.25;
        padding: 1.5rem 0 1.5rem 5.8rem;
        white-space: nowrap;
        flex-shrink: 0;
        @include breakpoint(medium down) {
            margin: 1.2rem 0 0 50%;
        }
        @include breakpoint(small down) {
            width: 100%;
            margin-left: 0;
        }

        &:before {
            content: "\e041";
            font-size: 1.3rem;
            line-height: 5rem;
            width: 5rem;
            height: 5rem;
            margin-top: -2.5rem;
        }
    }
}
