.list-type-1--doctors {

    .list-type-1__item {
        margin: 2rem 0;
        padding-bottom: 2em;
        border-bottom: .1rem solid $color-black;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }

        &__wrapper {
            margin-bottom: 5em;
        }
    }
}
