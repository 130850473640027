// Newsletter inscription
.newsletter {
    .newsletter__title {
        font-weight: $thin;
        font-size: 2.4rem;
        line-height: 1.2em;
        color: $color-3--3;
        letter-spacing: em(-0.05, 2.4);
        text-transform: uppercase;
        margin: 0 0 .5em;
        @include breakpoint(medium only) {
            font-size: 2.1rem;
            margin-bottom: 1.7rem;
        }
        @include breakpoint(small down) {
            margin-bottom: 1.4rem;
        }
    }
    form {
        margin: 0;
    }
    fieldset {
        margin-bottom: .5em;
    }
    /*
    input {
      padding-top: em(1, $input__font-size);
      padding-bottom: em(1, $input__font-size);
    }
    */
}

.newsletter__fields {
    position: relative;
    padding-right: 1.7rem;
    width: 27.7rem;
}

.newsletter__field-wrapper {
    label {
        font-weight: $bold;
        font-size: 1.6rem;
        line-height: 1.2em;
        color: $color-3--3;
        margin: 0 0 .5em;
        @include breakpoint(medium down) {
            font-size: 1.5rem;
        }
    }

    input {
        padding: 1.2rem 1.8rem;
        background: $color-3--1;
        box-shadow: -0.8rem 0.8rem 0.8rem 0 rgba($color-black, 0.1);
    }
}

.newsletter__button-wrapper {
    position: absolute;
    bottom: .3rem;
    right: 0;
}

.newsletter__button-submit {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
    background: $color-2--3;
    box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.4rem 0.4rem 0 0 rgba($color-3--4, 0.14);
    transition: all 300ms ease;
    padding-top: .2rem;

    &:hover, &:focus {
        background: $color-3--3;
        svg {
            fill: $color-white;
        }
    }

    svg {
        width: 1.3rem;
        height: 1.6rem;
        fill: #010101;
        transition: all 300ms ease;
    }
}

.newsletter__links {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 1.9rem;

    &-item {
        font-family: $typo-2;
        font-weight: $bold;
        font-size: 1.4em;
        line-height: 1.2em;
        color: $color-3--2;
        text-transform: uppercase;
        padding-left: 2.3rem;
        position: relative;

        &:not(:first-child) {
            margin-left: 1.6rem;
            @include breakpoint(small down) {
                margin-left: 1rem;
            }
        }

        &:before {
            content: "";
            font-family: "icons-default";
            font-size: 1.1rem;
            line-height: 1.2em;
            color: $color-2--3;
            position: absolute;
            top: 0;
            left: 0.5rem;
        }

        a {
            color: inherit;
        }

        &--archives:before {
            content: "\e03d";
        }

        &--unsubscribe:before {
            content: "\e058";
        }
    }
}
