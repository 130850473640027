// @name site informations
.site-infos {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    @include breakpoint(medium only) {
        padding-left: 3.5rem;
    }
    @include breakpoint(small down) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &__left-col {
        //padding-right: 10%;
        padding-right: 6rem;
        position: relative;
        z-index: 6;
        @media(max-width: 1430px) {
            padding-right: 4rem;
        }
        @include breakpoint(medium down) {
            //padding-right: 3.5rem;
            padding-right: 2.5rem;
            width: 57%;
        }
        @include breakpoint(small down) {
            width: 100%;
            padding-right: 0;
        }
    }

    &__right-col {
        flex: 0 1 auto;
        position: relative;
        z-index: 4;
        @include breakpoint(medium only) {
            margin-top: 3rem;
        }
        @include breakpoint(small down) {
            margin-top: 5.3rem;
            padding-left: 2.6rem;
        }

        &:before {
            content: "";
            display: block;
            width: 21rem;
            height: 21rem;
            border-radius: 50%;
            box-shadow: inset -1.1rem 0.7rem 0 0 rgba($color-3--10, .43);
            position: absolute;
            top: -5.5rem;
            right: 100%;
            margin-right: -5rem;
            z-index: -1;
            @include breakpoint(medium down) {
                width: 11.5rem;
                height: 11.5rem;
                top: -2.3rem;
                margin-right: -3.7rem;
                box-shadow: inset -0.7rem 0.7rem 0 0 rgba($color-3--10, .43);
            }
            @include breakpoint(small down) {
                margin-right: -6.3rem;
            }
        }
    }

    //.site-infos__img-wrap {
    //    float: left;
    //    margin-right: 2em;
    //}

    .site-infos__wrapper {
        overflow: hidden;
        font-style: normal;
    }


    //p, ul {
    //    font-weight: $light;
    //    font-size: 1.6em;
    //    line-height: 1.2em;
    //    color: $color-3--3;
    //    margin: em(.5, 1.3) 0 em(.8, 1.3);
    //    &:first-child {
    //        margin-top: 0;
    //    }
    //}

    &__name-organization {
        font-weight: $bold;
        font-size: 2rem;
        line-height: 1.2em;
        color: $color-3--3;
        margin: 0;
        //white-space: nowrap;
        @include breakpoint(medium only) {
            font-size: 1.8rem;
        }
    }

    &__address {
        font-weight: $light;
        font-size: 1.6rem;
        line-height: 1.2em;
        color: $color-3--3;
        //white-space: nowrap;
        //margin: em(.5, 1.3) 0 em(.8, 1.3);
        @include breakpoint(medium only) {
            font-size: 1.5rem;
        }
    }

    //ul {
    //    > li {
    //        margin: 0.2em 0;
    //    }
    //}

    //a {
    //    color: $color-black;
    //    text-decoration: underline;
    //    &:hover, &:focus {
    //        text-decoration: none;
    //    }
    //}

    &__useful-numbers {
        margin-top: 1.3rem;
        font-family: $typo-1;
        font-weight: $bold;
        font-style: normal;
        color: $color-3--3;
        @include breakpoint(small down) {
            margin-top: .6rem;
        }

        &:before {
            content: "\e08a";
            color: $color-3--3;
        }

        &:hover:before, &:focus:before {
            color: $color-white;
        }
    }

    &__contacter {
        vertical-align: middle;
        color: $color-3--3;
        font-size: 1.6em;
        line-height: 1.2em;
        padding: 2.4rem 0 2.4rem 8rem;
        white-space: nowrap;
        @include breakpoint(medium down) {
            line-height: 1.25em;
            padding: 1.5rem 0 1.5rem 5.8rem
        }

        &:before {
            content: "\e02a";
            font-size: 2rem;
            font-weight: normal;
            line-height: 6.7rem;
            //color: $color-3--3;
            color: #343336;
            width: 6.7rem;
            height: 6.7rem;
            margin-top: -3.3rem;
            @include breakpoint(medium down) {
                font-size: 1.6rem;
                line-height: 5rem;
                width: 5rem;
                height: 5rem;
                margin-top: -2.5rem;
            }
        }

        &:hover:before, &:focus:before {
            color: $color-white;
        }
    }
}
