.list-type-1--home-news {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    margin: 0 -1.8rem -2rem;
    @include breakpoint(medium only) {
        margin: 0 -1rem -2rem;
    }
    @include breakpoint(small down) {
        margin: 0 0 -1rem;
    }

    .list__item {
        width: calc((100% / 4) - 3.6rem);
        margin: 0 1.8rem 2rem;
        padding: 0;
        display: block;
        overflow: hidden;
        @include breakpoint(medium only) {
            width: calc((100% / 2) - 2rem);
            margin: 0 1rem 2rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            margin: 0 0 1rem;
        }
    }

    .list-type-1__picture {
        float: none;
        width: 100%;
        margin: 0;
        //background: $color-1--3;
        background: $color-1--7;

        a {
            display: block;
            width: 100%;

            &:hover, &:focus {
                img {
                    opacity: .6;
                }
            }
        }

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: none;
            margin: 0;
            //transition: opacity 300ms ease;
        }
    }

    .list-type-1__wrapper {
        padding: 1.2rem 1.5rem 1rem;
        text-align: center;
        overflow: visible;
        @include breakpoint(medium down) {
            padding: 1.5rem 2rem 1.5rem;
        }
        @include breakpoint(small down) {
            padding: 1.2rem 2rem 1.5rem;
        }
    }

    .list-type-1__category {
        font-family: $typo-2;
        font-style: italic;
        font-weight: $bold;
        font-size: 1.8rem;
        line-height: 1.2em;
        color: $color-2--10;
        margin-bottom: 1rem;
        @include breakpoint(medium down) {
            font-size: 1.6rem;
        }
        @include breakpoint(small down) {
            margin-bottom: 0;
        }
    }

    .list-type-1__title {
        font-weight: $light;
        font-size: 2rem;
        line-height: 1.2em;
        color: $color-3--4;
        margin: 0;
        @include breakpoint(medium down) {
            font-size: 1.8rem;
            line-height: 2.2rem;
        }
        @include breakpoint(small down) {
            font-size: 1.6rem;
            line-height: 2rem;
        }

        a {
            color: inherit;
        }
    }
}
