.home-news {

    + .home-events {
        padding-top: 4rem;
        @include breakpoint(medium down) {
            padding-top: 5rem;
        }
    }

    &__list {
        @include breakpoint(small down) {
            width: 100%;
            max-width: 38rem;
            margin: 0 auto -1rem;
        }
    }

    &__view-more {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.8rem;
        @include breakpoint(medium down) {
            justify-content: center;
            margin-top: 1.2rem;
        }
        @include breakpoint(small down) {
            justify-content: flex-end;
            width: 100%;
            max-width: 38rem;
            margin: 2rem auto 0;
            padding-right: 2rem;
        }

        .link-view-more {
            margin-right: -3.2rem;
            @include breakpoint(medium down) {
                margin: 0;
            }
        }
    }
}
