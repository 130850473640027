.list-type-2--albums, .list-type-2--albums-video {
    .list-type-2__picture {
        display: table;
        max-width: 100%;
        background: $color-1--7;

        &.has-blocked-elements {
            display: block;

            &::after,
            &::before {
                display: none;
            }
        }

        a {
            display: block;
            width: 100%;
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 5;
            }

            &:hover, &:focus {
                img {
                    opacity: .6;
                }
            }
        }

        .cookies-denied {
            align-items: center;
            background-color: #c5c5c5;
            border: 0;
            border-radius: 0;
            color: #fff;
            cursor: pointer;
            display: flex;
            font-family: "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif;
            font-size: 1.4rem;
            justify-content: center;
            padding: 0;
            position: relative;
            overflow: hidden;
            width: 100%;

            &::after {
                bottom: auto;
                left: auto;
                position: relative;
                right: auto;
                top: auto;
                content: '';
                display: block;
                padding-top: 76%;
                width: 100%;
            }

            span {
                background-color: #1b870b;
                border-radius: 4px;
                display: inline-block;
                left: 0;
                margin: 10px;
                max-width: calc(100% - 20px);
                padding: 5px;
                position: absolute;
                top: 50%;
                transform: translateY(calc(-50% - 10px));
                white-space: normal;
            }
        }
    }
}

.list-type-2--albums-video {
    .list-type-2__title {
        margin: 0 0 1rem;
    }
}
