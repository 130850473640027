.link-view-more {
    display: inline-block;
    vertical-align: middle;
    transition: all ease .3s;
    text-decoration: none;
    outline-color: $color-3--4;
    width: 6.7rem;
    height: 6.7rem;
    border-radius: 50%;
    background: $color-2--8;
    box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -.6rem .6rem 0 0 rgba($color-2--5, 0.07);
    overflow: hidden;
    @include default-icons-absolute-before('\e08a', 2.1rem, $color-black, 50%, auto, auto, 50%);
    &:before {
        transform: translate(-50%, -50%);
        transition: all ease .3s;
    }
    @include breakpoint(medium down) {
        width: 5rem;
        height: 5rem;

        &:before {
            font-size: 2rem;
        }
    }
    @include breakpoint(small down) {
        width: 6rem;
        height: 6rem;
    }

    &:hover, &:focus {
        background: $color-3--3;
        &:before {
            color: $color-white;
        }
    }

    &--variation-1 {
        background: $color-1--3;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -.6rem .6rem 0 0 rgba($color-black, 0.17);
        &:before {
            color: $color-white;
        }
        &:hover, &:focus {
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -.6rem .6rem 0 0 rgba($color-2--5, 0.07);
        }
    }

    &--variation-2 {
        background: $color-1--7;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -.6rem .6rem 0 0 rgba($color-3--4, 0.17);
        &:before {
            color: $color-white;
        }
    }

    &--variation-3 {
        background: $color-2--3;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -.6rem .6rem 0 0 rgba($color-2--5, 0.17);
        &:before {
            color: $color-white;
        }
    }

    &--variation-4 {
        background: $color-3--3;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -.6rem .6rem 0 0 rgba($color-3--4, 0.17);
        &:before {
            color: $color-white;
        }
    }
}
