.home-know-how {
    //background: $color-1--3;
    //margin-left: -40%;
    //padding-left: 54%;
    padding-left: 14%;
    padding-bottom: 4.5rem;
    margin-bottom: 11.2rem;
    position: relative;
    @media(max-width: 1490px) {
        padding-left: 4.5rem;
    }
    @include breakpoint(medium down) {
        min-height: 33.6rem;
        margin: 0 -2rem;
        padding: 4.3rem 2rem 7rem 35rem;
        background: $color-1--3;
        background-color: transparent;
        background-image: url(../Images/custom/bg/bg-know-how.svg);
        background-position: 7rem 0.5rem;
        background-repeat: no-repeat;
        background-size: 132rem 102rem;
    }
    @include breakpoint(small down) {
        //margin: -8.3rem -1rem 0;
        margin: 0 -1rem;
        padding: 7.8rem 1rem 4.7rem;
        //background-position: -34rem 0;
        background-position: -34rem calc(100% + 50rem);
    }

    &:before {
        @include breakpoint(medium only) {
            content: "";
            display: block;
            width: 20rem;
            height: calc(100% - 0.7rem);
            max-height: 40.7rem;
            background: $color-1--3;
            position: absolute;
            top: .7rem;
            left: 100%;
        }
    }

    .section-title {
        margin: 0 0 4rem;
        color: $color-white;
        @include breakpoint(medium down) {
            margin-bottom: 4.6rem;
        }
        @include breakpoint(small down) {
            text-align: left;
            margin-bottom: 1.7rem;
            &:after {
                left: auto;
                right: calc(100% - 5rem);
            }
        }
    }

    &__article {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        @include breakpoint(small down) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            //width: 22rem;
            width: 24rem;
        }

        &-picture {
            flex: 0 0 auto;
            display: block;
            //width: 22rem;
            width: 53%;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05);
            margin-right: 1.7rem;
            @media(max-width: 1490px) {
                width: 40%;
            }
            @include breakpoint(medium down) {
                width: 19.5rem;
            }
            @include breakpoint(small down) {
                width: 100%;
                margin: 0 0 1.2rem;
                height: 14.6rem;
            }

            img {
                display: block;
                width: 100%;
                max-width: 100%;
                height: auto;
                max-height: none;
                @include breakpoint(small down) {
                    display: inline;
                    vertical-align: top;
                    height: inherit;
                    min-height: 100%;
                    max-height: none;
                    width: inherit;
                    min-width: 100%;
                    max-width: none;
                    object-fit: cover;
                    font-family: 'object-fit: cover;';
                }
            }
        }

        &-content {
            flex: 1 1 auto;
        }

        &-category {
            font-family: $typo-2;
            font-style: italic;
            font-weight: $bold;
            font-size: 1.6rem;
            line-height: 1.2em;
            color: $color-white;
            margin-bottom: 1.3rem;
        }

        &-title {
            font-family: $typo-1;
            font-weight: $light;
            font-size: 2.3rem;
            line-height: 1.2em;
            color: $color-white;
            @include breakpoint(medium down) {
                font-size: 2rem;
            }
        }
    }

    &__view-more {
        position: absolute;
        right: -3.3rem;
        bottom: -3.3rem;
        @media(max-width: 1490px) {
            right: 0;
        }
        @include breakpoint(medium down) {
            //right: calc((100% - 55rem) / 2);
            //left: 43rem;
            right: 8rem;
            bottom: 1.5rem;
        }
        @include breakpoint(small down) {
            position: static;
            right: auto;
            bottom: auto;
            text-align: center;
            padding-top: 1.4rem;
        }

        //.link-view-more {
        //    @include breakpoint(medium down) {
        //        width: 3.8rem;
        //        height: 3.8rem;
        //
        //        &:before {
        //            font-size: 1.5rem;
        //        }
        //    }
        //}
    }
}
