// section in content - "Aller plus loin"
.go-on-read {

    &__list {
        li:first-child {
            margin-top: -.3rem;
        }

        li:last-child {
            margin-bottom: 0;
        }
    }
}
