.sidebar {
    //z-index: 200;
    //padding: 1.5rem 0 0;

    &__title {
        font-family: $typo-2;
        font-weight: $bold;
        font-style: italic;
        font-size: 2rem;
        line-height: 1.2em;
        color: $color-3--9;
        text-transform: uppercase;
        margin-bottom: 0.7rem;
    }
}
