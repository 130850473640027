// @name Tools
// @description Tools on page and sharing on social media network
.tools {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 100%;
    text-align: left;
    //@include breakpoint(small down) {
    //    text-align: center;
    //}
    @include breakpoint(large up) {
        padding-bottom: 2rem;
    }

    svg {
        width: 1.6em;
        height: 1.6em;
        fill: $color-white;
    }
    .button-1 {
        &:before {
            content: "\e02d";
            font-size: 1.2rem;
            box-shadow: none;
        }

        &:hover:before, &:focus:before {
            background: $color-2--3;
            box-shadow: none;
        }
    }
}

.tools__listitems {
    //margin: 0 -.5em;
}

.tools__item {
    display: inline-block;
    vertical-align: middle;
    //margin: 0 .5em;
    margin-right: .4rem;
    > a,
    > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        padding: 0;
        background: $color-1--3;
        font-size: 1em;
        color: $color-white;
        text-decoration: none;
        transition: all 300ms ease;

        &:before {
            background: $color-2--3;
        }

        &:after {
            border-left-color: $color-2--3;
        }

        &:hover, &:focus {
            background: $color-2--3;
        }

        .icon {
            font-size: 1.6rem;
            line-height: 1.2rem;
        }

        .ghost {
            left: 0!important;
            z-index: -1!important;
            opacity: 0!important;
        }
    }
}

// @name Share page
.ddm.share-page {
    .ddm__sub-level {
        display: block;
        visibility: hidden;
        top: 100%;
        left: inherit;
        right: 0;
        overflow: hidden;
        z-index: 2;

    }
    .ddm__wrapper {
        transform: translateY(-110%);
        transition: all ease .1s;
    }
    &.ddm--active {
        .ddm__sub-level {
            visibility: visible;
        }
        .ddm__wrapper {
            transform: translateY(0);
            transition: all ease .4s;
        }
    }
}

.share-page {
    .ddm__sub-level {
        text-align: left;
        width: 13em;
        margin-top: 0.5rem;
    }
    .ddm__wrapper {
        background: $color-2--3;
        color: $color-white;
        padding: 1em 1.5em;
        a {
            color: $color-white;
        }
    }
}

.share-page__item {
    font-size: 1.3em;
    margin: .5em 0;
    @include default-icons-before('\e098', 0 5px 0 0, 1.4rem, $color-white, -.2rem);
    &.email {
        &:before {
            content: "\e028";
        }
    }
    &.facebook {
        &:before {
            content: "\e098";
        }
    }
    &.twitter {
        &:before {
            content: "\e09a";
        }
    }
    &.google {
        &:before {
            content: "\e0a4";
        }
    }
    &.instagram {
        &:before {
            content: "\e0a1";
        }
    }
    &.linkedin {
        &:before {
            content: "\e0a2";
        }
    }
    &.viadeo {
        &:before {
            content: "\e0a3";
        }
    }
    &.youtube {
        &:before {
            content: "\e09d";
        }
    }
}
