.establishment-form {
    margin: 0;

    &__title {
        font-weight: $bold;
        font-size: 1.4rem;
        line-height: 1.2em;
        color: $color-3--4;
        margin: 0 0 1.1rem;
    }

    &__fields {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border: .1rem solid $color-3--1;

        select {
            border: none;
        }
    }

    &__valider {
        width: 4rem;
        height: 4rem;
        position: relative;
        overflow: hidden;

        &:hover:before, &:focus:before {
            color: $color-2--4;
        }

        &:before {
            content: "\e057";
            font-family: "icons-default";
            font-size: 1.7rem;
            line-height: 1;
            color: $color-1--3;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 300ms ease;
        }
    }
}

.home-establishment {
    position: relative;
    padding: 7.5rem 0 1.7rem;
    @include breakpoint(medium down) {
        //padding: 3rem 0 6rem;
        padding: 3rem 0 4rem;
        width: 100%;
        max-width: 51rem;
        margin: 0 auto;
    }
    @include breakpoint(small down) {
        padding: 2.5rem 0 1.4rem;
    }

    &__image-box {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        padding-left: 7.5rem;
        @include breakpoint(medium down) {
            text-align: center;
            //padding: 6.5rem 0 11rem 0;
            padding: 6.5rem 0 9rem 6rem;
        }
        @include breakpoint(small down) {
            padding: 8.5rem 0 10rem;
        }

        img {
            vertical-align: top;
            width: auto;
            max-width: none;
            height: auto;
            max-height: 100%;
        }
    }

    .section-title {
        margin: 0 0 21.4rem;
        @include breakpoint(medium down) {
            display: table;
            margin-left: auto;
            margin-right: auto;

            &:after {
                width: 150rem;
            }
        }
        @include breakpoint(small down) {
            display: block;
            text-align: left;
            margin-bottom: 26rem;

            &:after {
                width: 15rem;
                left: auto;
                right: calc(100% - 5rem);
            }
        }
    }

    &__form {
        width: 100%;
        max-width: 30.5rem;
    }
}
