.list-type-1--home-events {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -0.8rem -1.5rem;
    @include breakpoint(small down) {
        margin: 0 0 -1.6rem;
        padding: 0 1.7rem;
    }

    .list__item {
        width: calc((100% / 5) - 1.6rem);
        margin: 6rem 0.8rem 1.5rem;
        padding: 0;
        display: block;
        overflow: visible;
        position: relative;
        @include breakpoint(medium down) {
            width: calc((100% / 3) - 1.6rem);
        }
        @include breakpoint(small down) {
            width: 100%;
            margin: 6rem 0 1.6rem;
        }
    }

    .list-type-1__picture {
        float: none;
        width: 100%;
        margin: 0;
        background: $color-1--7;

        a {
            display: block;
            width: 100%;

            &:hover, &:focus {
                img {
                    opacity: .6;
                }
            }
        }

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: none;
            margin: 0;
            //transition: opacity 300ms ease;
        }
    }

    .list-type-1__wrapper {
        text-align: center;
        overflow: visible;
        margin: 0;
        padding: 0;
        @include breakpoint(large up) {
            min-height: 9rem;
        }
    }

    .list-type-1__wrapper-date {
        position: absolute;
        top: -6rem;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
        border-right: none;

        .date-1 {
            width: 10.5rem;
            height: 10.5rem;
            border-radius: 50%;
            background: $color-1--3;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.6rem 0.6rem 0 0 rgba(#111212, 0.17);
            line-height: 10em;
            margin: 0;
            padding: 0;
            color: $color-white;

            time {
                margin-top: .5rem;

                & + span + time:before {
                    color: $color-white;
                }

                &:only-of-type {
                    margin-top: 0;
                }
            }
        }

        &:first-child + .list-type-1__wrapper {
            margin-top: 6rem;
        }
    }

    .list-type-1__category {
        font-family: $typo-2;
        font-style: italic;
        font-weight: $bold;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: $color-white;
        display: block;
        width: 100%;
        background: $color-1--3;
        padding: .4rem 1rem;
        @include breakpoint(medium down) {
            font-size: 1.4rem;
        }
        @include breakpoint(small down) {
            font-size: 1.7rem;
            line-height: 2rem;
            padding: .2rem 1rem;
        }
    }

    .list-type-1__title {
        font-family: $typo-1;
        font-weight: $normal;
        font-size: 2rem;
        line-height: 1.2em;
        color: $color-black;
        margin: 1.5rem 1rem 1rem;
        @include breakpoint(medium down) {
            margin-left: 0;
            margin-right: 0;
        }
        @include breakpoint(small down) {
            margin: .3rem 1rem 1rem;
            font-size: 1.7rem;
            line-height: 2.8rem;
        }

        a {
            color: inherit;
        }
    }
}
