// Galerie classique
.classical-gallery {
    max-width: 70rem;
}

.classical-gallery__title {
    $classical-gallery__title_font-size: 1.6;
    font-family: $typo-1;
    font-size: #{$classical-gallery__title_font-size}em;
    font-weight: $light;
    line-height: 1.2;
    text-align: left;
    color: $color-3--3;
    margin-bottom: em(1, $classical-gallery__title_font-size);
    @include breakpoint(small down) {
        font-size: 1.2em;
        text-align: right;
        padding: 0 10px;
    }
}

.classical-gallery {
    margin: 5em 0;
    @include breakpoint(medium down) {
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
    @include breakpoint(small down) {
        // Galerie classique (avec vignettes) + Galerie album
        margin: 4em -1em;
        //margin-left: -10px;
        //margin-right: -10px;
    }
    &__nb-items {
        display: none;
        @include breakpoint(small down) {
            display: block;
            min-width: 5rem;
            min-height: 5rem;
            padding: 1.6rem .8rem;
            background: $color-2--3;
            color: $color-black;
            position: absolute;
            z-index: 10;
            bottom: 0;
            right: 0;
            font-weight: $light;
            font-size: 1.6rem;
            line-height: 1.2;
            margin: 0;
            text-align: center;
        }
    }
}

.slider-galerie {
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 10px;
    @include breakpoint(small down) {
        margin-bottom: 0;
    }
    .slider-galerie__item {
        width: 100%;
        height: 100%;
        z-index: 1 !important;
        border: none;
        background: $color-3--1;
        @include breakpoint(small down) {
            height: auto;
            display: none;
            &:first-child {
                display: block;
            }
        }
    }
    .infos-img {
        position: absolute;
        z-index: 1;
        top: 100%;
        right: 0;
        width: calc(100% - 500px);
        display: block;
        padding: 8px 10px 10px 20px;
        font-family: $typo-1;
        //font-weight: $light;
        font-weight: $medium;
        font-style: normal;
        color: $color-3--8;
        font-size: 1.3em;
        text-align: left;
        white-space: normal;
        @include breakpoint(small down) {
            display: none;
            position: static;
            right: inherit;
            bottom: inherit;
            background: #000;
        }
        &__nbItems {
            display: table-cell;
            vertical-align: bottom;
            white-space: nowrap;
            width: 70px;
        }
        &__wrap {
            display: table-cell;
            vertical-align: top;
        }
        &__legend {
            display: block;
        }
    }
    a {
        display: block;
        width: 100%;
        height: auto;
        text-align: center;
        position: relative;
        white-space: nowrap;
        padding: 68.08510% 0 0 0; // 100/(940/640)
        @include breakpoint(small down) {
            display: inline-block;
            text-decoration: none;
            padding: 0;
        }
    }
    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%) translateY(-50%);
        @include breakpoint(small down) {
            max-height: inherit;
            position: static;
            top: inherit;
            bottom: inherit;
            left: inherit;
            right: inherit;
            transform: inherit;
            display: inline;
        }
    }
}

.carousel-galerie {
    width: 500px;
    display: inline-block;
    text-align: right;
    position: relative;
    z-index: 1;
    .item {
        border: none;
    }
}

.carousel-galerie__thumb {
    width: 340px;
    height: 74px;
    z-index: 2;
    margin-left: 80px;
    text-align: left;
    @include breakpoint(small down) {
        display: none;
    }
    &.no-carousel {
        position: relative;
        .carousel-galerie__item {
            display: inline-block;
            margin: 0 3px;
        }
    }
}

.carousel-galerie__item {
    z-index: 1 !important;
    text-align: center;
    &.cycle-slide-active button, &.cycle-pager-active button, button:hover, button:focus {
        &:before {
            background: rgba(0, 0, 0, 0);
        }
    }
    button {
        position: relative;
        &:before {
            transition: all ease .2s;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($color-2--7, .3);
        }
        img {
            width: 74px;
            height: 74px;
        }
    }
}

.carousel-galerie__pager {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 74px;
    width: 500px;
    z-index: 1;
    @include breakpoint(small down) {
        display: none;
    }
}

.carousel-galerie__prev, .carousel-galerie__next {
    position: absolute;
    top: 0;
    padding: 0;
    button {
        width: 74px;
        height: 74px;
        border-radius: 50%;
        background: $color-2--3;
        box-shadow: inset -0.6rem 0.6rem 0 0 rgba($color-2--5, 0.14);
        text-indent: -9999px;
        transition: all ease .2s;
        overflow: hidden;
        @include default-icons-absolute-before('\e026', 2rem, $color-white, 50%, inherit, inherit, 50%);
        &:before {
            text-indent: 0;
            transform: translateY(-50%) translateX(-50%);
        }
        &:hover, &:focus {
            background: $color-3--3;
            &:before {
                color: #ffdc36;
            }
        }
    }
    &:before {
        content: "";
        speak: none;
    }
}

.carousel-galerie__prev {
    left: 0;
}

.carousel-galerie__next {
    right: 0;
    button {
        &:before {
            content: "\e027";
        }
    }
}
