.service-contact {
    &__title {
        font-family: $typo-2;
        font-style: italic;
        font-weight: $bold;
        font-size: 1.8rem;
        line-height: 1.2em;
        color: $color-white;
        text-transform: uppercase;
        margin: 0 0 .2rem;

        a {
            color: inherit;
            outline-color: $color-white;

            &:focus {
                outline-color: $color-white;
            }
        }
    }

    &__subtitle {
        font-family: $typo-2;
        font-weight: $light;
        font-size: 1.4em;
        line-height: 1.2em;
        position: relative;
        padding-left: 1.8rem;

        &:before {
            content: "\e053";
            font-family: "icons-default";
            font-size: 1.3rem;
            font-weight: normal;
            line-height: 1;
            color: $color-2--3;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__infos {
        padding-left: 2rem;
        @include breakpoint(medium only) {
            padding-left: 0;
        }

        &-item {
            margin: 1rem 0;
        }

        &-title {
            font-size: 1.4rem;
            line-height: 1.2em;
            color: $color-white;
            text-transform: uppercase;
        }

        .list-infos {

            &__item {
                font-size: 1.4rem;
                font-weight: $bold;
                color: $color-white;
                margin: 0.1rem 0 .3rem;
                padding-left: 1.8rem;

                a {
                    color: inherit;
                    text-decoration: none;
                    outline-color: $color-white;

                    &:hover, &:focus {
                        text-decoration: underline;
                    }

                    &:focus {
                        outline-color: $color-white;
                    }
                }

                &.list-infos__email:before {
                    content: "\e028";
                }
            }
        }
    }
}

.service-contact-container {
    background: $color-1--3;
    color: $color-white;
    margin-left: -3rem;
    padding: 4rem 0 4rem 2.5rem;
    align-items: stretch;
    position: relative;
    @include breakpoint(medium down) {
        margin-left: -2rem;
        margin-right: -2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @include breakpoint(small down) {
        margin-left: 0;
        margin-right: -1rem;
        padding: 2.5rem 1rem 2.5rem 0;
    }

    &:before {
        @include breakpoint(medium up) {
            content: "";
            display: block;
            width: 50rem;
            height: 100%;
            background: $color-1--3;
            position: absolute;
            top: 0;
            left: 100%;
            margin-left: -.5rem;
        }
    }

    &__title {
        flex: 0 0 auto;
        width: 25rem;
        max-height: 20.5rem;
        padding: .5rem 2rem 0 0;
        display: flex;
        align-items: center;
        border-right: .1rem solid rgba($color-white, .3);
        @include breakpoint(small down) {
            width: 100%;
            display: block;
            padding: 0 0 0 3rem;
            border-right: none;
            position: relative;

            &:before {
                content: "";
                display: block;
                width: calc(100% - 10rem);
                height: .1rem;
                background: rgba($color-white, .3);
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .section-title {
            font-weight: $thin;
            font-size: 3.4rem;
            line-height: 1.2em;
            color: $color-white;
            padding-bottom: 2rem;
            @include breakpoint(small down) {
                font-size: 2.5rem;
                max-width: 17rem;
            }

            &:after {
                left: -6.5rem;
                @include breakpoint(small down) {
                    left: -7rem;
                }
            }
        }
    }

    &__content {
        flex: 1 0 auto;
        width: calc(100% - 25rem);
        padding: .8rem 0 0 2rem;
        margin-bottom: -2rem;
        @extend .clear-fix;
        @include breakpoint(medium down) {
            padding-left: 0;
        }
        @include breakpoint(small down) {
            width: 100%;
            padding-top: 2.6rem;
            margin-bottom: -3rem;
        }

        .service-contact {
            //display: inline-block;
            //vertical-align: top;
            //margin-right: -.4rem;
            float: left;
            padding-left: 2rem;
            padding-bottom: 2rem;
            width: 50%;
            @include breakpoint(small down) {
                width: 100%;
                float: none;
            }

            &:nth-child(even) {
                float: right;
                @include breakpoint(small down) {
                    float: none;
                }
            }
        }
    }
}
