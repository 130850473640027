.enimages {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(medium down){
        padding-left: 5rem;
        padding-right: 5rem;
    }
    @include breakpoint(small down){
        margin: 0 -1rem;
        padding-left: 0;
        padding-right: 0;
    }

    &-lg {
        width: 33.5%;
        height: 34.8rem;
        @include breakpoint(medium down){
            width: 100%;
            height: 46.2rem;
        }
        @include breakpoint(small down){
            height: auto;
        }

        .enimages-box__content {
            padding-top: 6rem;
            @include breakpoint(small down){
                padding: 1.2rem 1rem 2.7rem;
            }
        }

        .enimages-box__content-category {
            font-size: 1.6rem;
            line-height: 1.2;
            @include breakpoint(small down) {
                font-size: 1.4rem;
            }
        }

        .enimages-box__content-title {
            font-size: 2.2rem;
            line-height: 2.4rem;
            margin-top: 1.1rem;
            max-width: 27rem;
            @include breakpoint(medium down){
                max-width: 33rem;
            }
            @include breakpoint(small down){
                font-size: 1.8rem;
                line-height: 2.2rem;
                margin-top: 0.7rem;
            }
        }

        //.enimages-box__content-amount {
        //    font-size: 1.5rem;
        //    line-height: 1.8rem;
        //    margin-top: 1.7rem;
        //    @include breakpoint(small down){
        //        font-size: 1.4rem;
        //        line-height: 1.2em;
        //        margin-top: 0.9rem;
        //    }
        //}
    }

    &-sm {
        width: 66.5%;
        height: 34.8rem;
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(medium down){
            width: 100%;
            height: auto;
        }

        .enimages__box {
            width: 25%;
            height: 50%;
            @include breakpoint(medium down){
                width: 50%;
                height: 23.3rem;
            }
            @include breakpoint(small down){
                height: auto;
            }
        }

        .enimages-box__content-magnifier {
            //display: none;
            @include breakpoint(large up){
                //display: block;
                width: 4rem;
                height: 4rem;
                margin-top: .8rem;
            }
        }
    }
}


.enimages-box {
    display: block;
    position: relative;
    height: 100%;
    overflow: hidden;
    text-decoration: none;
    @include breakpoint(small down){
        height: auto;
    }

    &:hover, &:focus {
        text-decoration: none;

        .enimages-box__img img {
            opacity: .2;
            @include breakpoint(small down) {
                opacity: .5;
            }
        }

        .enimages-box__content {
            top: 0;
        }

        .enimages-box__content-title {
            @include breakpoint(small down) {
                text-decoration: underline;
            }
        }

        & .enimages-box__img:before {
            transform: translate(-50%, -50%) scale(0);
        }
    }

    &--video .enimages-box__img {
        &:before {
            content: "\e027";
            font-family: "icons-default";
            font-size: 1.5rem;
            line-height: 4.8rem;
            color: #7a5093;
            text-align: center;
            padding-left: 0.2rem;
            position: absolute;
            top: 50%;
            right: inherit;
            bottom: inherit;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            width: 4.7rem;
            height: 4.7rem;
            border-radius: 50%;
            background: rgba(#d0b1e3, .9);
            transition: all 100ms ease;
            z-index: 1;
        }
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: $color-1--7;
        position: relative;
        @include breakpoint(small down){
            height: auto;
        }

        img {
            vertical-align: top;
            height: inherit;
            min-height: 100%;
            max-height: none;
            width: inherit;
            min-width: 100%;
            max-width: none;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            transition: all 400ms ease;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 2rem;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 400ms ease;
        z-index: 2;
        @include breakpoint(small down){
            justify-content: flex-start;
            width: 100%;
            height: auto;
            position: relative;
            top: auto;
            left: auto;
            padding: 1.3rem .5rem 3.2rem;
        }

        &-category {
            font-family: $typo-2;
            font-weight: $bold;
            font-size: 1.3rem;
            line-height: 1.2;
            text-transform: uppercase;
            color: $color-white;
            margin: 0;
            max-width: 100%;
            transition: all 400ms ease;
        }

        &-title {
            font-weight: $light;
            font-size: 1.6rem;
            line-height: 1.8rem;
            color: $color-white;
            margin: 0.8rem 0 0;
            max-width: 100%;
            @include breakpoint(small down){
                line-height: 1em;
                margin-top: .4rem;
            }
        }

        //&-amount {
        //    font-weight: $light;
        //    font-size: 1.3rem;
        //    line-height: 1em;
        //    color: $color-white;
        //    margin: 1.2rem 0 0;
        //    max-width: 100%;
        //}

        &-magnifier {
            flex: 0 0 auto;
            display: block;
            width: 4.8rem;
            height: 4.8rem;
            border-radius: 50%;
            background: $color-1--3;
            position: relative;
            overflow: hidden;
            text-indent: -9999px;
            margin-top: 1.5rem;
            @include breakpoint(small down){
                display: none;
            }

            &:before {
                content: "\e033";
                font-family: "icons-default";
                font-size: 1.2rem;
                line-height: 1;
                color: $color-white;
                text-indent: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all 300ms ease;
            }
        }
    }
}


.home-enimages {
    position: relative;
    z-index: 18;
    //background: rgba(#19191a, .8);
    background: #343336;
    padding: 9rem 0 18rem;
    @include breakpoint(medium down){
        padding: 4.5rem 0 5rem;
    }
    @include breakpoint(small down){
        padding-bottom: 4rem;
    }

    &:before {
        content: '';
        display: block;
        width: 150%;
        height: 100%;
        background: $color-3--4;
        position: absolute;
        top: 0;
        left: calc(53% + 1rem);
        transform-origin: 0 0;
        transform: skewX(-24deg);
        @include breakpoint(medium only){
            top: auto;
            bottom: 0;
            left: 4.5rem;
            transform-origin: 0 100%;
        }
        @include breakpoint(small down){
            left: 8.1rem;
            transform: skewX(-15deg);
        }
    }

    .section-title {
        color: $color-white;
        margin: 0 0 4.5rem;
        @include breakpoint(medium down){
            margin-bottom: 3rem;
        }
        @include breakpoint(small down){
            margin-bottom: 3.5rem;
        }
    }

    .wrapper-wide {
        position: relative;
    }

    .link-view-more {
        position: absolute;
        right: -0.4rem;
        bottom: -3.4rem;
        width: 6.7rem;
        height: 6.7rem;
        z-index: 5;
        @include breakpoint(medium down){
            position: relative;
            right: auto;
            bottom: auto;
            display: block;
            margin: 4rem auto 0;
        }
        @include breakpoint(small down){
            position: absolute;
            right: 3rem;
            bottom: -7rem;
            width: 6rem;
            height: 6rem;
            margin: 0;
        }

        &:hover, &:focus {
            background: rgba(#d0b1e3, .9);
        }
    }
}
