// @name List infos
.list-infos {
    font-family: $typo-1;
}

.list-infos__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    @include breakpoint(medium down) {
        display: block;
    }
    > * {
        flex: 1 1 0;
    }
    .list-infos__listitems:first-child {
        flex: 1.9 1 0;
    }
    .list-infos__listitems + .list-infos__listitems {
        border-left: 1px solid $color-3--3;
        padding-left: 5%;
        margin-left: 5%;
        @include breakpoint(medium down) {
            border-left: 0;
            border-top: 1px solid $color-3--3;
            padding-left: 0;
            margin-left: 0;
            padding-top: 5%;
            margin-top: 5%;
        }
    }
}

.list-infos__item {
    $list-infos__item__font-size: 1.3;
    margin: .7em 0;
    font-size: #{$list-infos__item__font-size}em;
    line-height: 1.2em;
    @include default-icons-absolute-before('\e053', 1.4rem, $color-2--3, 0, inherit, inherit, 0);
    padding-left: em(2.5, $list-infos__item__font-size);
    a {
        color: $color-black;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
    &.list-infos__phone, &.list-infos__fax {
        white-space: nowrap;
    }
    &.list-infos__address {
        &:before {
            content: "\e053";
        }
    }
    &.list-infos__website {
        &:before {
            content: "\e089";
        }
    }
    &.list-infos__phone {
        &:before {
            content: "\e02b";
        }
    }
    &.list-infos__fax {
        &:before {
            content: "\e09f";
        }
    }
    &.list-infos__email {
        &:before {
            content: "\e029";
        }
    }
    &.list-infos__infos {
        &:before {
            content: "\e083";
        }
    }
    &.list-infos__hours {
        &:before {
            content: "\e05b";
        }
    }
}
