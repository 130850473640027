// Publications block on content page
.publication-card {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-bottom: 4rem;
    //@include breakpoint(medium down) {
    //    flex-wrap: wrap;
    //}

    &__wrapper {
        margin-bottom: -4rem;
        @include breakpoint(large up) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    &__banner {
        width: 16.2rem;
        flex-shrink: 0;
        margin-right: 5rem;
        background: $color-3--8;
        @include breakpoint(small down) {
            margin-right: 2rem;
            width: 11.3rem;
        }

        a {
            display: block;
            text-decoration: none;

            &:hover img {
                opacity: 0.7;
            }
        }

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: none;
            transition: opacity 300ms ease;
        }
    }

    &__content {
        flex: 1 1 auto;
    }

    &__category {
        display: block;
        font-weight: $bold;
        font-size: 1.2em;
        line-height: 1.2em;
        text-transform: uppercase;
        color: $color-1--6;
        margin: 0 0 1rem;
    }

    &__title {
        font-weight: $normal;
        font-size: 1.6rem;
        line-height: 1em;
        color: $color-3--4;
        margin: 0 0 0.5rem;

        a {
            color: inherit;
        }
    }

    &__parametrs-info {
        font-family: $typo-2;
        font-weight: $normal;
        font-size: 1.3rem;
        line-height: 1.2em;
        color: $color-3--3;
        margin: 0 0 0.5rem;
    }

    &__controls-box {
        text-align: left;
        margin-top: 1.8rem;
        @include breakpoint(small down) {
            margin-top: 0.7rem;
        }
    }

    &__control {
        display: inline-block;
        vertical-align: middle;
        transition: all ease .3s;
        text-decoration: none;
        background: $color-3--2;
        padding: 0.5rem;
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 50%;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -0.4rem 0.4rem 0 0 rgba($color-3--4, 0.13);
        overflow: hidden;
        position: relative;
        //@include breakpoint(small down) {
        //    width: 100%;
        //    margin-left: 0;
        //}

        &:not(:first-child) {
            margin-left: .4rem;
        }

        &--variation-1 {
            background: $color-3--1;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -0.4rem 0.4rem 0 0 rgba($color-3--4, 0.07);
        }

        &:hover, &:focus {
            background: $color-1--3;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -0.4rem 0.4rem 0 0 rgba(0, 69, 82, 0.14);
            &:before {
                color: $color-white;
            }
        }

        &:before {
            content: "\e026";
            font-family: 'icons-default';
            font-size: 1.3rem;
            color: $color-black;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 300ms ease;
        }

        &--download:before {
            content: "\e063";
        }

        &--read:before {
            content: "\e06e";
        }
    }
}

// Publications on service page
.service-page-publication {
    @extend .clear-fix;

    .publication-card__wrapper {
        padding-left: 0;
        padding-right: 0;
        @include breakpoint(small down) {
            padding-left: 10%;
        }
    }

    .publication-card__banner {
        width: 14rem;
        margin-right: 3.5rem;
        @include breakpoint(medium down) {
            margin-right: 3rem;
        }
        @include breakpoint(small down) {
            width: 11.3rem;
            margin-right: 2rem;
        }
    }

    .publication-card__content {
        margin-top: -.2rem;
    }

    .publication-card__category {
        font-family: $typo-2;
        font-style: italic;
        font-size: 1.6rem;
        color: $color-2--10;
    }
}

// Publications focus block on home page
.publication-card--focus {
    @include breakpoint(small down) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .publication-card__banner {
        width: 20.2rem;
        border: .1rem solid $color-3--1;
        background: $color-1--7;
        margin-right: 2rem;
        @include breakpoint(small down) {
            margin: 0 auto 1rem;
        }

        img {
            transition: none;
        }
    }

    .publication-card__category {
        font-family: $typo-2;
        font-style: italic;
        font-size: 1.6rem;
        color: $color-2--10;
        margin: .6rem 0 .9rem;
    }

    .publication-card__title {
        font-weight: $normal;
        font-size: 2.4rem;
        line-height: 1em;
        @include breakpoint(medium down) {
            font-size: 2rem;
            line-height: 2.4rem;
        }
        @include breakpoint(small down) {
            line-height: 1em;
        }
    }

    .publication-card__controls-box {
        margin-top: 2rem;
        @include breakpoint(small down) {
            text-align: center;
        }
    }

    .publication-card__control {
        background: $color-1--3;
        width: 6.7rem;
        height: 6.7rem;
        //box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -0.4rem 0.4rem 0 0 rgba($color-3--4, 0.13);

        &:before {
            font-size: 1.8rem;
            color: $color-white;
        }

        &--variation-1 {
            background: $color-2--3;
            //box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -0.4rem 0.4rem 0 0 rgba($color-3--4, 0.07);

            &:before {
                color: $color-3--4;
            }
        }

        &:hover, &:focus {
            background: $color-3--3;
            &:before {
                color: $color-white;
            }
        }
    }
}

// Publications block on home page - documents list
.publication-card--documents {

    .publication-card__banner {
        width: 10rem;
        border: .1rem solid $color-3--1;
        background: $color-1--7;
        margin-right: 2rem;

        img {
            transition: none;
        }
    }

    .publication-card__category {
        font-family: $typo-2;
        font-style: italic;
        font-size: 1.4rem;
        color: $color-2--10;
        margin: .2rem 0 .9rem;
        @include breakpoint(small down) {
            margin: .2rem 0 .5rem;
        }
    }

    .publication-card__title {
        font-weight: $normal;
        font-size: 1.6rem;
        line-height: 1em;
    }

    .publication-card__controls-box {
        margin-top: 1.2rem;
        @include breakpoint(small down) {
            margin-top: .7rem;
        }
    }
}

