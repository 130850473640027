.parallax {
    height: 540px;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    @include breakpoint(medium down) {
        height: 400px;
    }
    @include breakpoint(small down) {
        width: 100%;
        height: 50vw;
        background-attachment: inherit;
        background-size: 100% auto;
        background-repeat: no-repeat;
    }
}
