// @name buttons

// @name button-1 (light)
// @state button-1--big - Big button-1
// @state button-1--small - Small button-1
// @state .button-1--no-icon - Button-1 without icon
// @state .button-1--svg - Button-1 with svg content
// @state .button-1--no-text-in-mobile - Indicates button-1 without content text in smartphone view
// @state .button-1--variation-1 - Button-1 with variation-1 (bold, color grey, background blue)

$button-1__font-size: 1.4;
$button-1--big__font-size: 2;
$button-1--small__font-size: 1.2;
input.button-1, .rte input.button-1 {
    padding-left: 1.2rem;
    &.button-1--big {
        padding-left: 1.2rem;
    }
    &.button-1--small {
        padding-left: 1rem;
    }

    &:hover, &:focus {
        text-decoration: underline;
    }
}

.button-1,
.rte .button-1 {
    font-family: $typo-2;
    display: inline-block;
    vertical-align: middle;
    transition: all ease .3s;
    text-decoration: none;
    font-weight: $bold;
    font-style: italic;
    color: $color-3--4;
    text-transform: uppercase;
    background: transparent;
    font-size: #{$button-1__font-size}em;
    line-height: em(1.6, $button-1__font-size);
    text-align: left;
    padding: 1rem 1.2rem 1rem 4.8rem;
    outline-color: $color-3--4;
    min-width: 3.6rem;
    min-height: 3.6rem;
    @include default-icons-absolute-before('\e026', 1rem, $color-white, 50%, inherit, inherit, 0);
    &:before {
        width: 3.6rem;
        height: 3.6rem;
        margin-top: -1.8rem;
        font-style: normal;
        font-weight: normal;
        line-height: 3.6rem;
        border-radius: 50%;
        text-align: center;
        background: $color-2--3;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.14);
    }
    &:before {
        //transform: translateY(-50%);
        transition: all ease .3s;
    }
    &[type=reset] {
        &:before {
            content: "\e002";
        }
    }
    &[type=submit] {
        &:before {
            content: "\e057";
        }
    }
    &:hover,
    &:focus {
        text-decoration: none;
        //background: $color-1--3;
        //color: color-contrast($color-1--3);
        &:before {
            background: $color-3--3;
            //box-shadow: inset -.4rem .4rem 0 0 rgba($color-2--5, 0.14);
        }
        > svg {
            fill: $color-3--4;
        }
    }
    &:focus {
        outline-color: $color-3--4;
    }
    // Big
    &.button-1--big {
        // Change font size reference
        min-width: 4rem;
        min-height: 4rem;
        font-size: #{$button-1--big__font-size}em;
        line-height: 1em;
        padding: 1rem 1.2rem 1rem 5.2rem;
        &:before {
            width: 4rem;
            height: 4rem;
            margin-top: -2rem;
            line-height: 4rem;
            //left: em(2.4, $button-1--big__font-size);
            font-size: 1.6rem;
        }
    }
    // Small
    &.button-1--small {
        // Change font size reference
        min-width: 3rem;
        min-height: 3rem;
        font-size: #{$button-1--small__font-size}em;
        line-height: 1.4rem;
        padding: 0.8rem 1rem 0.8rem 4rem;
        &:before {
            width: 3rem;
            height: 3rem;
            margin-top: -1.5rem;
            line-height: 3rem;
            //left: em(1.5, $button-1--small__font-size);
            font-size: 1rem;
        }

        &.button-1--no-icon {
            padding-left: 1rem;
        }

        &.button-1--svg {
            padding-left: 1rem;
        }
    }
    &.button-1--no-text-in-mobile {
        @include breakpoint(medium down) {
            text-align: left;
            text-indent: -9999px;
            white-space: nowrap;
            position: relative;
            //padding: em(1.3, $button-1__font-size) em(2.1, $button-1__font-size);
            padding: 1rem;
            overflow: hidden;
            &:before {
                text-indent: 0;
                //    line-height: 0;
                //    display: block;
                //    position: absolute;
                //    top: 50%;
                //    left: 50%;
                //    transform: translateY(-50%) translateX(-50%);
                //    margin: 0;
                //    font-size: em(1.4, $button-1__font-size);
            }
        }
    }
    &.button-1--no-text-in-smartphone {
        @include breakpoint(small down) {
            text-align: left;
            text-indent: -9999px;
            white-space: nowrap;
            position: relative;
            padding: 1rem;
            overflow: hidden;
            &:before {
                text-indent: 0;
            }
        }
    }
    // No icon
    &.button-1--no-icon {
        //padding-left: em(2, $button-1__font-size);
        //padding-right: em(2, $button-1__font-size);
        padding-left: 1.2rem;
        &:before {
            content: "";
            margin: 0;
            width: auto;
            height: auto;
            background: transparent;
            box-shadow: none;
        }
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
    // No text
    &.button-1--no-text {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: relative;
        //padding: em(1.3, $button-1__font-size) em(1.9, $button-1__font-size);
        padding: 1rem;
        overflow: hidden;
        &:before {
            text-indent: 0;
            //line-height: 0;
            //display: block;
            //top: 50%;
            //left: 50%;
            //transform: translateY(-50%) translateX(-50%);
            //margin: 0;
        }
    }
    // With svg
    &.button-1--svg {
        //padding: em(1.4, $button-1__font-size) em(1.3, $button-1__font-size);
        padding: 1.2rem;
    }
    > svg {
        fill: $color-2--3;
        width: em(1.2, $button-1__font-size);
        height: em(1.2, $button-1__font-size);
        vertical-align: middle;
    }
    // With calendar font-icon
    &.button-1--calendar {
        &:before {
            //left: em(1, 1.4);
            content: "\e05c";
        }
    }
    // With next icon
    &.button-1--next {
        &:before {
            content: "\e027";
        }
    }
    &--variation-1 {
        //font-weight: $bold;
        //color: $color-white;
        //background: $color-1--2;
        &:before {
            //color: $color-white;
            background: $color-1--3;
            content: '\e057';
        }
        > svg {
            fill: $color-1--3;
        }
    }
}

//  @name Extenral link for button-1
a.button-1[href^="http://"]:not([href*="#{$domain}"]),
a.button-1[href^="https://"]:not([href*="#{$domain}"]),
a.button-1[href^="//"]:not([href*="#{$domain}"]) {
    @include default-icons-after('\e086', 0 0 0 .5rem, .8rem, $color-3--4, middle);
    &:after {
        transition: all ease .3s;
    }
    &:hover, &:focus {
        &:after {
            color: $color-3--4;
        }
    }
}

//a.button-1.button-1--variation-1[href^="http://"]:not([href*="#{$domain}"]),
//a.button-1.button-1--variation-1[href^="https://"]:not([href*="#{$domain}"]),
//a.button-1.button-1--variation-1[href^="//"]:not([href*="#{$domain}"]) {
//    &:after {
//        color: $color-white;
//    }
//}

// @name Grey form
// @description Form with grey background
//.form--3 {
     //@name button-1 (light)
    //.button-1 {
        //color: $color-white;
        //&:hover, &:focus {
        //    background: $color-1--3;
        //    text-decoration: none;
        //}
    //}
    //.button-1.button-1--variation-1 {
        //color: $color-white;
        //&:hover, &:focus {
        //    background: $color-1--3;
        //    text-decoration: none;
        //}
    //}
//}
