.top-menu {
    display: flex;
    justify-content: flex-end;
    background: $color-3--1;
    box-shadow: inset 7rem 0 4rem 0 rgba($color-white, .5);

    &__list {
        overflow: hidden;
        //white-space: nowrap;
    }

    &__item {
        display: inline-block;
        vertical-align: middle;
        padding: 0.8rem 3.4rem 0.7rem;

        &--accent {
            background: #cc2a3d;
            transform-origin: 0 50%;
            transform: skewX(-23deg);
            box-shadow: 3rem 0 0 0 #cc2a3d;

            .top-menu__link {
                //font-weight: $bold;
                font-weight: $medium;
                color: $color-white;
                transform-origin: 0 50%;
                transform: skewX(23deg);

                svg {
                    fill: rgba($color-white, .6);
                }
            }
        }
    }

    &__link {
        display: block;
        white-space: nowrap;
        font-family: $typo-2;
        font-weight: $light;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: $color-3--3;
        text-transform: uppercase;

        &-icon {
            display: inline-block;
            vertical-align: middle;
            margin-top: -.3rem;
            margin-right: .2rem;
            width: 1.6rem;
            height: 1.6rem;
            line-height: 1;

            img {
                vertical-align: top;
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;
            }

            svg {
                width: 1.6rem;
                height: 1.6rem;
                fill: $color-1--3;
            }
        }
    }
}

.nav__top-menu {

    .top-menu__item {
        display: block;
        text-align: center;
        padding: 0;

        &:not(:last-child) {
            border-bottom: .1rem solid $color-3--3;
        }

        &--accent {
            transform: none;

            .top-menu__link {
                transform: none;
            }
        }
    }

    .top-menu__link {
        padding: .8rem 1.5rem .7rem;
    }
}
