.medical-groupe {

    .outpatient-care__title-icon:before {
        content: "\e014";
        font-size: 3.5rem;
    }

    &__list {
    }

    &__staff {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $color-3--13;
        margin: 1.1rem 0;

        &-title {
            position: relative;
            padding-left: 1.4rem;

            &:before {
                content: "\e01b";
                font-family: "icons-default";
                font-size: 1rem;
                line-height: 1.6rem;
                color: $color-2--3;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &-list {
            padding-left: 1.4rem;

            a {
                color: inherit;
            }
        }
    }
}

.external-consultation {

    &__staff {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $color-3--13;
        margin: 1.1rem 0;

        &-title {
            position: relative;
            padding-left: 1.4rem;

            &:before {
                content: "\e01b";
                font-family: "icons-default";
                font-size: 1rem;
                line-height: 1.6rem;
                color: $color-2--3;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &-list {
            padding-left: 1.4rem;

            a {
                color: inherit;
            }
        }
    }

    &__description {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $color-3--13;
        margin: 1.8rem 0 1.5rem;
        @include breakpoint(medium down) {
            margin-bottom: 0;
        }
    }

    &__controls {
        font-family: $typo-1;
        font-style: normal;
        font-weight: $light;
        font-size: 1.3rem;
        line-height: 1.6rem;
        min-width: 4.9rem;
        min-height: 4.9rem;
        padding: 1.7rem 0 1.7rem 6rem;
        margin-top: 2rem;

        &:before {
            font-size: 1.3rem;
            width: 5rem;
            height: 5rem;
            margin-top: -2.5rem;
            line-height: 5rem;
            background: $color-1--3;
        }

        &--appoinment:before {
            content: "\e05c";
        }

        &--location:before {
            content: "\e053";
        }
    }
}

.outpatient-care {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &__column {
        flex: 1 1 50%;
        @include breakpoint(small down) {
            flex-basis: 100%;
        }

        &-content {
            padding-left: 8.5rem;
            @include breakpoint(small down) {
                padding-left: 0;
            }
        }

        &:first-child {
            padding-right: 1rem;
            @include breakpoint(small down) {
                padding-right: 0;
                margin-bottom: 3.5rem;
            }
        }
    }

    &__title {
        font-family: $typo-2;
        font-weight: $bold;
        font-style: italic;
        font-size: 1.8rem;
        line-height: 2rem;
        color: $color-3--13;
        text-transform: uppercase;
        text-align: left;
        padding: 1.8rem 0 1.8rem 8.5rem;
        min-width: 6rem;
        min-height: 6rem;
        position: relative;
        transition: all ease .3s;
        margin-bottom: 1.6rem;
        @include breakpoint(small down) {
            padding-top: 0;
            padding-left: 0;
        }

        &-icon {
            display: block;
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            background: $color-2--8;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.07);
            line-height: 4rem;
            padding: 1rem;
            text-align: center;
            position: absolute;
            top: 50%;
            margin-top: -3rem;
            left: 0;
            overflow: hidden;
            @include breakpoint(small down) {
                position: relative;
                top: auto;
                left: auto;
                margin-top: 0;
                display: table;
                margin-bottom: 1.8rem;
            }

            &:before {
                content: '\e013';
                font-family: "icons-project";
                font-size: 3rem;
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                color: $color-white;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            //svg {
            //    vertical-align: middle;
            //    line-height: 1;
            //    width: 3rem;
            //    height: 3rem;
            //    fill: $color-white;
            //}
            //
            //img {
            //    vertical-align: middle;
            //    line-height: 1;
            //    width: auto;
            //    height: auto;
            //    max-width: 3rem;
            //    max-height: 3rem;
            //}
        }
    }
}
