// Variables with default value can be overwritten by TYPO3 variables.
// For view customization enabled in controller.scss the file configuration-sitefactory

// # Global
$domain: "stratis.fr" !default;

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Montserrat:100,300,300i,400,700" !default;
$import-google-font-2: "https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,500,500i,700,700i" !default;
$import-google-font-3: "https://fonts.googleapis.com/css?family=Roboto:300,400,700" !default;

$typo-1: 'Montserrat', sans-serif !default;
$typo-2: 'Roboto Condensed', sans-serif !default;
$typo-3: 'Roboto', sans-serif !default;

// ## color-1
$color-1--1: #2178ed !default;
$color-1--2: #1955c9 !default;
$color-1--3: #164194 !default;
$color-1--4: #032973 !default;
$color-1--5: #13428b !default;
$color-1--6: #0e3787 !default;
$color-1--7: #2566e4 !default;
$color-1--8: #6599fd !default;
$color-1--9: #0066cc !default;


// ## color-2
$color-2--1: #9dded9 !default;
$color-2--2: #79c9c3 !default;
$color-2--3: #52bbb3 !default;
$color-2--4: #0b7c7a !default;
$color-2--5: #004552 !default;
$color-2--6: #9dded9 !default;
$color-2--7: #009ea0 !default;
$color-2--8: #58c9b9 !default;
$color-2--9: #49b6a7 !default;
$color-2--10: #0b8077 !default;
$color-2--11: #7ed9d2 !default;


// ## Color-3
$color-3--1: #e9e4ee !default;
$color-3--2: #bcb7c1 !default;
$color-3--3: #65606a !default;
$color-3--4: #3b393d !default;
$color-3--5: #f2eef5 !default;
$color-3--6: #f4f1f6 !default;
$color-3--7: #7a5093 !default;
$color-3--8: #727272 !default;
$color-3--9: #6c7880 !default;
$color-3--10: #e2dde7 !default;
$color-3--11: #ebeaed !default;
$color-3--12: #f8f7fa !default;
$color-3--13: #3f3e44 !default;


// ## Socials
$color-facebook: #3b5998 !default;
$color-twitter: #00acee !default;
$color-instagram: #3055d9 !default;
$color-youtube: #c4302b !default;



$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$path-root: "../" !default;
$path-images: "#{$path-root}Images/";
$path-images-bg: "#{$path-root}Images/bg/";
$path-images-fancybox: "#{$path-root}Images/fancybox/";
$path-fonts: "#{$path-root}Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1279;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;
$color-blue: #0091c4;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;
