// News in content
.news-content {
    position: relative;

    &__list {
        margin-bottom: -4rem;

        &-item {
            margin: 0 0 4rem;
        }
    }

    &__view-more {
        position: absolute;
        top: 5.5rem;
        left: -11.3rem;
        width: 6.7rem;
        height: 6.7rem;
        @include breakpoint(medium down) {
            //left: 4.3rem;
            left: 6%;
        }
        @include breakpoint(small down) {
            position: static;
            top: auto;
            left: auto;
            text-align: center;
            padding: 0 0 1.8rem;
            width: 100%;
            max-width: 21rem;
        }

        .link-view-more {
            width: 6.7rem;
            height: 6.7rem;
            @include breakpoint(small down) {
                width: 4rem;
                height: 4rem;
            }
        }
    }
}

// News in content
.news-content-article {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    @include breakpoint(small down) {
        flex-wrap: wrap;
    }

    &:after {
        display: none;
    }

    .list-type-1__picture {
        width: 21rem;
        flex-shrink: 0;
        margin-right: 3rem;
        @include breakpoint(small down) {
            margin-right: 0;
        }

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: none;
            margin: 0;
        }
    }

    .list-type-1__wrapper {
        flex-grow: 1;
        overflow: visible;
        @include breakpoint(small down) {
            width: 100%;
        }
    }

    .list-type-1__category {
        font-family: $typo-1;
        font-style: normal;
        margin: -.2rem 0 .5rem;
    }

    .list-type-1__teaser {
        margin-bottom: 0;
    }
}
