.home-rapides {
    padding: 4rem 0 8.5rem;
    position: relative;
    z-index: 5;
    @include breakpoint(medium down) {
        padding: 4rem 0;
    }
    @include breakpoint(small down) {
        padding: 4rem 0 3rem;
    }

    .section-title {
        margin-top: 0;
        @include breakpoint(medium only) {
            margin-bottom: 5rem;
        }
        @include breakpoint(small down) {
            margin-bottom: 1.2rem;
        }
    }

    &-slider {
        display: block;
        width: 100%;
        max-width: 106rem;
        margin: 0 auto;
        padding: .3rem 5rem;
        @include breakpoint(medium down) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
        @include breakpoint(small down) {
            max-width: 24rem;
        }

        &__wrapper {

        }

        &__item {
            text-align: center;
            padding: 0.2rem .8rem;
            outline: none;
            position: relative;
            @include breakpoint(small down) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .slick-arrow {
            display: block;
            z-index: 1;
            position: absolute;
            top: 2.5rem;
            left: 0;
            width: 3rem;
            height: 3rem;
            outline-color: $color-1--3;
            text-indent: -9999px;
            overflow: hidden;
            //@include breakpoint(medium down) {
            //    top: 47%;
            //}
            //@include breakpoint(small down) {
            //    top: 60%;
            //}
            &:before {
                font-family: "icons-default";
                font-size: 3rem;
                color: $color-2--4;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-indent: 0;
                transition: all 300ms ease;
            }
            &:hover:before, &:focus:before {
                color: $color-1--3;
            }
            &:focus {
                outline-color: $color-1--3;
            }
            &.slick-prev {
                @include breakpoint(medium only) {
                    left: -.6rem;
                }
                &:before {
                    content: "\e016";
                }
            }
            &.slick-next {
                left: auto;
                right: 0;
                @include breakpoint(medium only) {
                    right: -.6rem;
                }
                &:before {
                    left: 55%;
                    content: "\e017";
                }
            }
        }
    }
}

.quick-acces {
    display: inline-block;
    vertical-align: top;
    width: auto;
    max-width: 100%;
    text-decoration: none;

    //&:hover, &:focus {
    //    text-decoration: none;
    //
    //    .quick-acces__title {
    //        color: $color-2--2;
    //    }

        //.quick-acces__icon svg {
        //    stroke: $color-white;
        //}
    //}

    &:focus {
        outline-offset: 0.1rem;
    }

    &__icon {
        height: 5rem;
        text-align: center;
        //@include breakpoint(medium down) {
        //    height: 3.7rem;
        //}
        //@include breakpoint(small down) {
        //    height: 4.4rem;
        //}

        svg {
            height: 5rem;
            fill: none;
            stroke: $color-2--4;
            stroke-width: 17;
            stroke-linecap:round;
            stroke-linejoin:round;
            stroke-miterlimit:10;
            transition: all 300ms ease;
            //@include breakpoint(medium down) {
            //    height: 3.7rem;
            //}
            //@include breakpoint(small down) {
            //    height: 4.4rem;
            //}
        }
    }

    &__title {
        font-family: $typo-2;
        font-weight: $normal;
        font-style: italic;
        font-size: 1.8rem;
        line-height: 1.2em;
        color: $color-3--4;
        text-align: center;
        display: block;
        max-width: 100%;
        margin: 2.3rem 0 0;
        transition: all 300ms ease;

        a {
            color: inherit;
        }
    }
}
