// @name Cross menu

.menu-cross {
    overflow: hidden;

    li {
        vertical-align: top;
        display: block;
        padding: .2em 1.5em;
        @include default-icons-absolute-before('\e027', 1rem, $color-2--3, 0, inherit, inherit, 0.2rem);
        &:before {
            line-height: 2.2rem;
        }

        a {
            display: inline-block;
            vertical-align: top;
            font-family: $typo-2;
            font-style: italic;
            font-weight: $normal;
            font-size: 1.4rem;
            line-height: 1.8rem;
            color: $color-3--3;
            text-transform: uppercase;
            text-decoration: none;
            &:hover, &:focus {
                color: $color-1--3;
                span {
                    text-decoration: underline;
                }
            }
        }
        &.active a {
            color: $color-1--3;
            text-decoration: none;
            font-weight: $bold;
        }
    }

    &__bold, &__simple {
        @include breakpoint(medium only) {
            flex: 0 0 auto;
            width: 50%;
        }
    }

    &__bold li a {
        font-weight: $bold;
    }
}
