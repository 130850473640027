.logo {
    //float: left;
    //font-size: 1.3em;
    //@include breakpoint(small down) {
    //    width: 70%;
    //}
    a {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        max-width: 100%;
    }

    img {
        display: block;
        vertical-align: top;
        max-width: 100%;
    }
}
