.home-publications {

    .section-title {
        margin-top: 0;
    }

    &__bar {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        padding-right: 14%;
        @include breakpoint(medium down) {
            padding-right: .5rem;
        }
        @include breakpoint(small down) {
            display: block;
            max-width: 40rem;
            margin: 0 auto;
            padding-right: 0;
        }
    }

    &__focus {
        flex: 1 0 auto;
        width: 60%;
        padding-right: 7%;
        margin-bottom: 0;
        @include breakpoint(medium down) {
            flex: 0 0 auto;
            width: calc(100% + 3rem);
            padding-right: 0;
            margin-bottom: 3.7rem;
        }
        @include breakpoint(small down) {
            width: 100%;
        }
    }

    &__list {
        flex: 1 0 auto;
        width: 40%;
        margin-bottom: -2.5rem;
        @include breakpoint(medium down) {
            width: calc(100% - 5rem);
            padding-right: 1rem;
            @include breakpoint(small down) {
                width: 100%;
                padding-right: 0;
                margin-bottom: -3rem;
            }
        }

        &-item {
            margin-bottom: 2.5rem;
            @include breakpoint(small down) {
                margin-bottom: 3rem;
            }

            .publication-card {
                margin-bottom: 0;
            }

            &:nth-child(2) {
                @include breakpoint(medium down) {
                    padding-right: 5rem;
                }
                @include breakpoint(small down) {
                    padding-right: 0;
                }

                .publication-card__banner {
                    width: 6rem;
                    margin-left: 4rem;
                }
            }
        }
    }

    &__view-more {
        flex: 1 0 auto;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 1.4rem 1.6rem 0 0;
        position: relative;
        @include breakpoint(medium down) {
            flex: 0 0 auto;
            width: 5rem;
            padding: 3.5rem 0 0;
        }
        @include breakpoint(small down) {
            width: 100%;
            padding-right: 2rem;
        }

        &:before {
            content: "";
            display: block;
            width: 200rem;
            height: 300rem;
            background: $color-2--3;
            position: absolute;
            bottom: -10rem;
            left: calc(100% - 5rem);
            z-index: -1;
            transform-origin: 0 calc(100% - 13.6rem);
            transform: skewX(-24.5deg);
            @include breakpoint(medium down) {
                bottom: -150rem;
                left: calc(100% - 2.5rem);
                transform-origin: 0 calc(50% - 2.5rem);
                transform: skewX(-26.5deg);
            }
            @include breakpoint(small down) {
                display: none;
            }
        }
    }
}
