// @name Signature stratis
.stratis {
    background: transparent;
    width: 100%;
    font-family: $typo-2;
    font-weight: $normal;
    font-size: 1.2em;
    line-height: 1;
    color: $color-3--2;
    text-transform: uppercase;
    text-align: right;
    height: 2rem;
    margin-top: -2rem;
    position: relative;
    span {
        display: none;
    }
    a {
        display: inline-block;
        padding: 4px 10px;
        color: #ffffff;
        background: #c70000;
        text-decoration: none;
        margin-left: .4rem;
        &:hover, &:focus {
            color: #c70000;
            background: #ffffff;
        }
    }
}
