// @name List
.list {
    position: relative;
}

// @name List informations
.list__infos {
    margin: 3em 0;
    font-size: 1.6em;
    line-height: 1.2em;
    font-family: $typo-2;
    font-weight: $light;
    border-bottom: 1px solid $color-3--3;
    padding-bottom: em(2, 1.6);
    padding-right: em(4, 1.6);
    &:first-of-type {
        margin: 0 0 3em 0;
    }
}

// @name List RSS
.list__rss {
    position: absolute;
    top: -1rem;
    right: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: $color-2--3;
    box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.14);
    text-align: center;

    &:before {
        display: none;
    }

    &:hover, &:focus {
        background: $color-3--3;

        svg {
            fill: $color-white;
        }
    }

    svg {
        fill: $color-white;
        width: 1.3rem;
        height: 1.3rem;
    }
}

// @name List letter
.list__title-1 {
    background: $color-1--2;
    color: $color-white;
    font-size: 2em;
    font-weight: $bold;
    padding: em(.5, 2) em(1, 2);
    margin-bottom: em(3, 2);
}
