.service-section {
    //overflow-x: hidden;
    position: relative;
    z-index: 30;
    //background-color: $color-3--5;

    @include breakpoint(large up) {
        &:before {
            content: "";
            display: block;
            width: 100%;
            height: calc(100% - 15rem);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            background: $color-3--5;
            box-shadow: 0 0 7rem 5rem rgba($color-3--5, 1);
        }
    }

    &__inner {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        @include breakpoint(medium down) {
            display: block;
        }
    }

    &__offer {
        flex: 0 0 auto;
        width: calc(100% - 45rem);
        padding-right: 5.8rem;
        position: relative;
        z-index: 35;
        @include breakpoint(medium down) {
            width: 100%;
            padding-right: 0;
        }

        @include breakpoint(large up) {
            &:before {
                content: "";
                display: block;
                width: 100rem;
                height: 100%;
                background: $color-white;
                position: absolute;
                top: 0;
                left: calc(100% + 7rem);
                transform-origin: 0 0;
                transform: skewX(-25deg);
                z-index: 40;
            }
        }
    }

    &__establishment {
        flex: 0 0 auto;
        width: 45rem;
        position: relative;
        z-index: 50;
        @include breakpoint(medium down) {
            width: 100%;
        }
    }
}
