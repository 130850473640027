/* stylelint-disable */

#tarteaucitronRoot #tarteaucitronAlertBig {
    box-sizing: border-box !important;
    padding: 20px 30px !important;
}

#tarteaucitronDisclaimerAlert {
    display: block;
    margin-bottom: 10px !important;
    padding: 0 10px !important;
    text-align: center !important;
}

#fancyboxLine {
    display: none;
}
#tarteaucitronRoot div#tarteaucitronInfo a,
#tarteaucitronRoot div#tarteaucitronInfo {
    font-size: 16px !important;
}

/* stylelint-enable */
