.filter-social {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    &__item {
        padding: 0 0.4rem;
    }

    &__link {
        display: block;
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        color: $color-white;
        background: $color-facebook;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.17);
        transition: all 300ms ease;
        position: relative;
        text-decoration: none;
        overflow: hidden;
        text-indent: -9999px;

        &:hover, &:focus {
            text-decoration: none;
            background: $color-3--3;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.14);
        }

        //&.active {
        //    text-decoration: none;
        //    background: $color-2--2;
        //    color: $color-white;
        //
        //    &:before {
        //        color: $color-white;
        //    }
        //}

        &:before {
            font-family: 'icons-project';
            font-size: 1.5rem;
            color: $color-white;
            position: absolute;
            top: 50%;
            left: 50%;
            line-height: 1;
            text-indent: 0;
            transform: translate(-50%, -50%);
        }

        //&.filter-social-all {
        //    background: $color-2--2;
        //
        //    &:hover, &:focus, &.active {
        //        background: $color-white;
        //        color: $color-2--2;
        //    }
        //}

        &.filter-social-facebook {
            &:before {
                content: "\e00f";
            }
        }

        &.filter-social-twitter {
            background: $color-twitter;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.1);
            &:before {
                content: "\e010";
                font-size: 2rem;
            }

            &:hover, &:focus {
                background: $color-3--3;
                box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.14);
            }
        }

        &.filter-social-instagram {
            background: $color-instagram;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.12);
            &:before {
                content: "\e011";
            }

            &:hover, &:focus {
                background: $color-3--3;
                box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.14);
            }
        }

        &.filter-social-youtube {
            background: $color-youtube;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.07);
            &:before {
                content: "\e012";
            }

            &:hover, &:focus {
                background: $color-3--3;
                box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -.4rem .4rem 0 0 rgba($color-2--5, 0.14);
            }
        }
    }
}





.social-list {
    //display: flex;
    //flex-wrap: wrap;
    //align-items: flex-start;
    margin: 0;

    &__item {
        width: calc(25% - 3rem);
        margin: 0 1.5rem 3rem;
        @include breakpoint(medium only) {
            width: calc(50% - 2rem);
            margin: 0 1rem 2rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            margin: 0 0 1rem;
        }
    }
}





.social-block {
    position: relative;
    border: .1rem solid rgba($color-facebook, .3);
    background: rgba($color-facebook, .1);
    box-sizing: border-box;

    &--facebook {
        .social-block__icon:before {
            content: "\e00f";
        }
    }

    &--youtube {
        border: .1rem solid rgba($color-youtube, .3);
        background: rgba($color-youtube, .1);

        .social-block__icon {
            background: $color-youtube;
            &:before {
                content: "\e012";
            }
        }

        .social-block__img-holder:before {
            content: "\e027";
            font-family: "icons-default";
            font-size: 2.6rem;
            line-height: 5rem;
            color: $color-white;
            text-align: center;
            padding-left: 0.4rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            background: rgba($color-black, 0.25);
            z-index: 2;
            transition: all 150ms ease;
        }

        .social-block__account-title, .social-block__show-more, .social-block__date, .social-block__description a {
            color: $color-youtube;
        }
    }


    &--instagram {
        border: .1rem solid rgba($color-instagram, .3);
        background: rgba($color-instagram, .1);

        .social-block__icon {
            background: $color-instagram;
            &:before {
                content: "\e011";
            }
        }

        .social-block__account-title, .social-block__show-more, .social-block__date, .social-block__description a {
            color: #4c68d7;
        }
    }

    &--twitter {
        border: .1rem solid rgba($color-twitter, .3);
        background: rgba($color-twitter, .1);

        .social-block__icon {
            background: $color-twitter;
            &:before {
                content: "\e010";
            }
        }

        .social-block__account-title, .social-block__show-more, .social-block__date, .social-block__description a {
            color: #005aa6;
        }
    }

    &__icon {
        display: block;
        height: 4rem;
        width: 3.8rem;
        border-bottom-left-radius: 100%;
        background: $color-facebook;
        position: absolute;
        top: -.1rem;
        right: -.1rem;
        z-index: 8;

        &:before {
            content: "";
            font-family: 'icons-project';
            font-size: 1.5rem;
            line-height: 1em;
            color: $color-white;
            margin-right: .8rem;
            position: absolute;
            top: 40%;
            right: 0;
            transform: translateY( -50%);
        }
    }

    &__img-holder {
        display: block;
        position: relative;
        z-index: 6;
        margin: -.1rem -.1rem 0;

        &:hover, &:focus {
            img {
                opacity: 0.6;
            }

            &:before {
                transform: translate(-50%, -50%) scale(0);
            }
        }

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: none;
            //transition: all 300ms ease;
        }

        + .social-block__content {
            padding-top: 1.8rem;
        }
    }

    &__content {
        padding: 2.7rem 1.7rem 2.5rem;
    }

    &__account {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        text-decoration: none;
        margin: 0 0 1.2rem;

        //&:hover, &:focus {
        //    text-decoration: none;
        //
        //    .social-block__title {
        //        text-decoration: underline;
        //    }
        //}

        &-twitter-identificator {
            flex: 0 0 auto;
            width: 100%;
            font-family: $typo-3;
            font-weight: $light;
            font-size: 1.2rem;
            line-height: 1.2em;
            color: #606060;
            margin-left: .3rem;
            margin-bottom: 1.2rem;
            padding-left: 1.8rem;
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 1.4rem;
                height: 1.5rem;
                background: transparent url(../Images/custom/twitter-identificator-default-icon.png) 0 0 no-repeat;
                position: absolute;
                top: 0.1rem;
                left: 0;
            }

            a {
                color: inherit;
            }
        }

        &-avatar {
            width: 4.2rem;
            height: 4.2rem;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 1.1rem;
            flex-shrink: 0;

            img {
                vertical-align: top;
                height: inherit;
                min-height: 100%;
                max-height: none;
                width: inherit;
                min-width: 100%;
                max-width: none;
                object-fit: cover;
                font-family: 'object-fit: cover;';
            }
        }

        &-description {
            flex-grow: 1 1 auto;
            width: calc(100% - 5.3rem);
        }

        &-title {
            font-family: $typo-3;
            font-weight: $bold;
            font-size: 1.3rem;
            line-height: 1.2em;
            color: $color-facebook;
            margin: 0;

            a {
                color: inherit;
            }
        }

        &-subtitle {
            font-family: $typo-3;
            font-weight: $light;
            font-size: 1.2rem;
            line-height: 1.2em;
            color: #717171;
            margin: 0.2rem 0 0;
        }
    }

    &__description {
        font-family: $typo-3;
        font-weight: $light;
        font-size: 1.3rem;
        line-height: 1.2em;
        color: $color-black;
        margin: 0 0 .6rem;

        a {
            color: $color-facebook;
        }
    }

    &__show-more {
        font-family: $typo-3;
        font-weight: $light;
        display: table;
        font-size: 1.2rem;
        line-height: 1.2em;
        color: $color-facebook;
        margin: 0.6rem 0 0;
        text-decoration: underline;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    &__date {
        font-family: $typo-3;
        font-weight: $light;
        font-size: 1rem;
        line-height: 1.2em;
        color: $color-facebook;
        margin: 1rem 0 0;
        text-transform: uppercase;
    }
}





.home-socials {
    padding: 6.8rem 0 4rem;
    background: $color-3--12;
    position: relative;
    z-index: 10;
    @include breakpoint(medium down) {
        padding: 4.5rem 0 3rem;
    }
    @include breakpoint(small down) {
        padding: 3.8rem 1rem 6rem;
    }

    .wrapper-wide {
        position: relative;
        z-index: 11;

        @include breakpoint(large up) {
            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 37rem;
                max-height: 100%;
                background: rgba($color-3--11, .8);
                box-shadow: inset 0 -3.5rem 5rem 0 $color-3--12;
                margin-left: -29.5rem;
                //margin-left: 43.5rem;
                position: absolute;
                top: -6.8rem;
                //left: calc(50% + 73rem);
                left: 100%;
                transform-origin: 0 0;
                transform: skewX(-25deg);
            }
        }
    }

    &__list {
        position: relative;
        z-index: 12;
        width: 100%;
        max-width: 125rem;
        margin: 0 auto;
        @include breakpoint(medium down) {
            max-width: 60.5rem;
        }
        @include breakpoint(small down) {
            max-width: 38rem;
        }
    }

    &__head {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin-bottom: 3rem;
        position: relative;
        z-index: 12;
        @include breakpoint(medium down) {
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 4.2rem;
        }
        @include breakpoint(small down) {
            margin: 0 -1rem 3rem;
            justify-content: flex-start;
        }

        .section-title {
            margin: 0;
            min-width: 27.6rem;
            padding-left: 3rem;
            order: 2;
            @include breakpoint(medium down) {
                order: 1;
                min-width: auto;
                width: 100%;
                padding-left: 0;
            }
            @include breakpoint(small down) {
                text-align: left;

                &:after {
                    left: auto;
                    right: calc(100% - 5rem);
                }
            }

            &:after {
                background: $color-2--4;
            }
        }

    }

    &__filter-social {
        order: 1;
        @include breakpoint(medium down) {
            order: 2;
            margin-top: -2.9rem;
        }
        @include breakpoint(small down) {
            margin-top: 2rem;
        }
    }
}
