.home-events {

    &__list {
        @include breakpoint(small down) {
            width: 100%;
            max-width: 38rem;
            margin: 0 auto -1rem;
        }
    }

    &__view-more {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        @include breakpoint(medium down) {
            justify-content: center;
            margin-top: 1.5rem;
        }
        @include breakpoint(small down) {
            justify-content: flex-end;
            width: 100%;
            max-width: 38rem;
            margin: 1.5rem auto 0;
            padding-right: 2rem;
        }

        .link-view-more {
            margin-right: -3.2rem;
            @include breakpoint(medium down) {
                margin: 0;
            }
        }
    }
}
