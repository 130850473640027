//@name Page content

// Select first content element and remove margin-top
.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
.encadre > *:first-child,
// content in click and roll
.rte.ddm__sub-level > *:first-child,
.ddm__sub-level span.anchor:first-of-type + .colPos18181 .ce-bodytext > *:first-child,
    // Single view
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
    margin-top: 0;
}

// @name Title h2
$content-h2__font-size: 3.4;
.rte h2,
.rte .h2,
.h2 {
    font-family: $typo-1;
    font-size: #{$content-h2__font-size}em;
    line-height: 1.2;
    font-weight: $thin;
    color: $color-3--4;
    margin: em(6, $content-h2__font-size) 0 em(2.1, $content-h2__font-size) 0;
    @include breakpoint(small down) {
        font-size: 2.5em;
        margin: em(4.5, 2.5) 0 em(2.3, 2.5) 0;
    }
    a {
        color: $color-3--4;
        &:hover, &:focus {
            color: inherit;
        }

        svg {
            fill: $color-3--4;
        }
    }
}

// @name Title h3
$content-h3__font-size: 2.5;
.rte h3,
.rte .h3,
.h3 {
    font-family: $typo-1;
    font-size: #{$content-h3__font-size}em;
    line-height: 1.2;
    font-weight: $bold;
    color: $color-1--3;
    margin: em(4, $content-h3__font-size) 0 em(1.3, $content-h3__font-size) 0;
    @include breakpoint(small down) {
        font-size: 2.2em;
        margin: em(3, 2.2) 0 em(1.5, 2.2) 0;
    }
    a {
        color: $color-1--3;
        &:hover, &:focus {
            color: inherit;
        }

        svg {
            fill: $color-1--3;
        }
    }
}

.service .rte h3,
.service .rte .h3,
.service .h3 {
    color: $color-2--3;

    a {
        color: $color-2--3;

        svg {
            fill: $color-2--3;
        }
    }
}

// @name Title h4
$content-h4__font-size: 2;
.rte h4,
.rte .h4,
.h4 {
    font-family: $typo-1;
    font-weight: $bold;
    font-size: #{$content-h4__font-size}em;
    line-height: 1.2;
    color: $color-3--4;
    margin: em(4, $content-h4__font-size) 0 em(1.4, $content-h4__font-size) 0;
    @include breakpoint(small down) {
        font-size: 1.8em;
        margin: em(3, 1.8) 0 em(1.5, 1.8) 0;
    }
    a {
        color: $color-3--4;
        &:hover, &:focus {
            color: inherit;
        }

        svg {
            fill: $color-3--4;
        }
    }
}

// @name Title h5
$content-h5__font-size: 1.7;
.rte h5,
.rte .h5,
.h5 {
    font-family: $typo-1;
    font-weight: $bold;
    font-size: #{$content-h5__font-size}em;
    line-height: 1.2;
    color: $color-3--3;
    margin: em(4, $content-h5__font-size) 0 em(1.4, $content-h5__font-size) 0;
    @include breakpoint(small down) {
        font-size: 1.6em;
        margin: em(3, 1.6) 0 em(1.5, 1.6) 0;
    }
    a {
        color: $color-3--3;
        &:hover, &:focus {
            color: inherit;
        }

        svg {
            fill: $color-3--3;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.rte h6,
.rte .h6,
.h6 {
    font-family: $typo-1;
    font-size: #{$content-h6__font-size}em;
    line-height: 1.2;
    color: $color-black;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: inherit;
        }

        svg {
            fill: $color-black;
        }
    }
}

$content-list__font-size: 1.5;
.rte {
    // @name Link
    a {
        color: $color-1--3;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }

        svg {
            fill: $color-1--3;
        }
    }

    //  @name External link
    a[href^="http://"]:not([href*="#{$domain}"]),
    a[href^="https://"]:not([href*="#{$domain}"]),
    a[href^="//"]:not([href*="#{$domain}"]) {
        @include default-icons-after('\e06d', 0 0 0 .2rem, 1.4rem, $color-1--3, middle);
    }

    // @name Paragraph
    $content-paragraph__font-size: 1.5;
    $content-paragraph__font-size-mobile: 1.5;
    p {
        font-weight: $light;
        font-size: #{$content-paragraph__font-size}em;
        line-height: 1.2em;
        color: $color-3--4;
        margin: 0 0 em(0.6, $content-paragraph__font-size) 0;
        @include breakpoint(medium down) {
            font-size: #{$content-paragraph__font-size-mobile}em;
            margin: em(.6, $content-paragraph__font-size-mobile) 0 em(.6, $content-paragraph__font-size-mobile) 0;
        }
    }

    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.5em;
        color: $color-3--3;
        a {
            color: $color-3--3;

            svg {
                fill: $color-3--3;
            }
        }

        a[href^="http://"]:not([href*="#{$domain}"]):after,
        a[href^="https://"]:not([href*="#{$domain}"]):after,
        a[href^="//"]:not([href*="#{$domain}"]):after {
            color: $color-3--3;
        }
    }

    hr {
        margin: 2.5em 0;
    }

    // @name Content encadre
    // @description Specific style for paragraph
    $encadre__font-size: 1.6;
    .encadre {
        padding: em(1.4, $encadre__font-size) em(4, $encadre__font-size);
        color: $color-white;
        background: $color-3--4;
        margin: em(2.5, $encadre__font-size) 0 em(3, $encadre__font-size);
        @include breakpoint(small down) {
            padding-top: em(1.8, $encadre__font-size);
            padding-bottom: em(1.8, $encadre__font-size);
            margin-bottom: em(2.5, $encadre__font-size);
        }

        a {
            color: $color-2--6;
            outline-color: $color-2--6;

            &:focus {
                outline-color: $color-2--6;
            }

            svg {
                fill: $color-2--6;
            }
        }

        a[href^="http://"]:not([href*="#{$domain}"]):after,
        a[href^="https://"]:not([href*="#{$domain}"]):after,
        a[href^="//"]:not([href*="#{$domain}"]):after {
            color: $color-2--6;
        }
    }
    p.encadre,
    .encadre p {
        font-size: #{$encadre__font-size}em;
        line-height: em(2, $encadre__font-size);
        color: $color-white;
    }

    // @name Blockquote
    $content-blockquote__font-size: 1.8;
    $content-blockquote-icon-1__font-size: 2.9;
    blockquote {
        display: flex;
        align-items: center;
        min-height: 6.7rem;
        font-weight: $light;
        font-size: #{$content-blockquote__font-size}em;
        line-height: 1.2;
        color: $color-3--3;
        padding: 0 em(5, $content-blockquote__font-size) 0 em(9, $content-blockquote__font-size);
        margin: em(2.5, $content-blockquote__font-size) 0 em(2.5, $content-blockquote__font-size) 0;
        @include default-icons-absolute-before('\e071', em($content-blockquote-icon-1__font-size, $content-blockquote__font-size), $color-white, 0, inherit, inherit, 0);
        @include breakpoint(small down) {
            margin: em(3, $content-blockquote__font-size) 0 em(3, $content-blockquote__font-size) 0;
        }
        &:before {
            width: 6.7rem;
            height: 6.7rem;
            border-radius: 50%;
            background: $color-2--3;
            font-style: normal;
            box-shadow: inset -5px 5px 0 0 rgba($color-2--5, 0.14);
            text-align: center;
            line-height: 6.7rem;
        }
        p {
            font-size: 1em;
            font-weight: $light;
            color: inherit;
            @include breakpoint(medium down) {
                margin-top: 0;
            }
        }
    }

    // @name Unordered list
    ul,
    .show-hide__content ul {
        margin: 1.5rem 0 1.5rem 2.5rem;
        list-style: none;
        font-weight: $light;
        font-size: #{$content-list__font-size}rem;
        line-height: 1.2;
        color: $color-3--4;
        padding: 0;
        p {
            font-size: #{$content-paragraph__font-size}rem;
            margin: 0 0 .4rem 0;
            color: inherit;
        }
        > li {
            @include default-icons-absolute-before('\e027', 1.2rem, $color-2--2, .3rem, inherit, inherit, 0);
            padding-left: 1.5rem;
            margin-bottom: 1rem;
            > ul:first-child, > * + ul { // for specificity selector
                margin: 0 0 0 .8rem;
                list-style: none;
                padding: .5rem 0 .1rem 0;
                > li {
                    @include default-icons-absolute-before('\e007', 1rem, $color-3--4, .5rem, inherit, inherit, 0);
                    padding-left: 1.2rem;
                    margin-bottom: .4rem;
                }
            }
            > ol:first-child, > * + ol { // for specificity selector
                margin: 0 0 0 1.5rem;
                list-style: none;
                counter-reset: list;
                padding: .5rem 0 .1rem 0;
                > li {
                    padding: 0 0 0 2rem;
                    margin-bottom: .4rem;
                    position: relative;
                    &:before {
                        content: counter(list, decimal) ".";
                        counter-increment: list;
                        font-family: $typo-1;
                        font-size: 1.5rem;
                        font-weight: $light;
                        text-align: left;
                        position: absolute;
                        top: 0;
                        left: 0;
                        color: $color-3--7;
                    }
                }
            }
        }
    }

    // @name Ordered list
    ol,
    .show-hide__content ol {
        margin: 1.5rem 0 1.5rem 2.5rem;
        padding: 0;
        //list-style: decimal;
        list-style: none;
        counter-reset: list;
        font-size: #{$content-list__font-size}rem;
        line-height: 1.2;
        font-weight: $light;
        color: $color-3--4;
        p {
            font-size: #{$content-paragraph__font-size}rem;
            margin: 0 0 .4rem 0;
            color: inherit;
        }
        > li {
            padding-left: 2rem;
            margin-bottom: 1rem;
            position: relative;
            &:before {
                content: counter(list, decimal) ".";
                counter-increment: list;
                font-family: $typo-1;
                font-size: 1.5rem;
                font-weight: $light;
                text-align: left;
                position: absolute;
                top: 0;
                left: 0;
                color: $color-3--7;
            }
            > ol:first-child, > * + ol { // for specificity selector
                margin: 0 0 0 .8rem;
                padding: .5rem 0 .1rem 0;
                > li {
                    //padding: 0;
                    margin-bottom: 0.4rem;
                }
            }
            > ul:first-child, > * + ul { // for specificity selector
                margin: 0 0 0 .8rem;
                list-style: none;
                padding: .5rem 0 .1rem 0;
                > li {
                    @include default-icons-absolute-before('\e007', 1rem, $color-3--4, .5rem, inherit, inherit, 0);
                    padding-left: 1.2rem;
                    margin-bottom: .4rem;
                }
            }
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 5em 0;
        position: relative;
        @include breakpoint(small down) {
            margin: 4em 0;
        }
    }
    //.table-wrapper-fade {
    //     @include breakpoint(medium down) {
    //        position: absolute;
    //        right: 0;
    //        width: 30px;
    //        height: 100%;
    //        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
    //    }
    //}
    .table-wrapper-inner {
        //max-width: #{($tablet/10) - $column-bloc__width - $column-bloc__padding-left}em;
        max-width: 100%;
        overflow-y: auto;
        width: 100%;
        //padding-bottom: 3rem;
        //@include breakpoint(medium down) {
        //    max-width: #{($smartphone/10) - $column-bloc__width - $column-bloc__padding-left}em;
        //}
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 10px;
            background-color: $color-3--1;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-2--3;
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        caption {
            $table__caption_font-size: 1.6;
            font-family: $typo-1;
            text-align: left;
            font-weight: $light;
            font-size: #{$table__caption_font-size}em;
            color: $color-3--4;
            margin-bottom: em(1, $table__caption_font-size);
            //@include breakpoint(medium down) {
            //    text-align: left;
            //}
        }
        th, td {
            padding: 12px 20px;
            ul {
                font-size: 1em;
            }
            p {
                font-size: 1em;
                color: inherit;
            }
        }
        th {
            font-weight: $light;
            font-size: 1.4em;
            line-height: 1.2em;
            text-transform: uppercase;
            font-family: $typo-1;
            font-weight: $light;
            color: $color-1--3;
            text-align: left;
            vertical-align: top;

        }
        thead th {
            font-size: 1.4em;
            background: $color-1--3;
            font-weight: $bold;
            color: $color-white;
        }
        tbody th {
            text-align: right;
        }
        td {
            font-weight: $light;
            font-size: 1.4em;
            line-height: 1.2em;
            color: $color-black;
            background: $color-white;
            vertical-align: top;
        }
        tr:nth-child(2n) td,
        tr:nth-child(2n) th {
            background: $color-3--6;
        }
    }

    // @name column 2
    .column--2,
    .column--3 {
        margin: 4em 0 2em;
        column-gap: 2em;
    }
    .column--2 {
        columns: 2;
    }
    .column--3 {
        columns: 3;
    }
}

.rte + .list-document {
    margin-top: 2.5rem;
}
