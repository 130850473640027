.hero-carousel {
    overflow: hidden;
    position: relative;
    z-index: 60;

    &__wrapper {
        position: relative;
        z-index: 100;

        .hero-carousel-controls {
            position: absolute;
            left: 1.5rem;
            bottom: 2rem;
            z-index: 80;
        }
    }

    &__item {
        height: 59rem;
        position: relative;
        @include breakpoint(medium only) {
            height: 39rem;
        }
        @include breakpoint(small down) {
            height: 25rem;
        }
    }

    &__media {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        z-index: 4;

        &:before {
            content: "";
            display: block;
            //width: 48rem;
            width: 60rem;
            //height: 37.5rem;
            height: 58rem;
            background: rgba($color-1--3, .8);
            border-top-left-radius: 100% 100%;
            position: absolute;
            //left: calc(100% - 48rem);
            left: calc(100% - 52rem);
            bottom: -20rem;
            z-index: 6;
            @include breakpoint(medium only) {
                width: 50rem;
                height: 40rem;
                left: calc(100% - 36rem);
                bottom: -8rem;
            }
            @include breakpoint(small down) {
                width: 35rem;
                height: 35rem;
                left: calc(100% - 22rem);
                bottom: -12rem;
            }
        }

        img {
            vertical-align: top;
            height: inherit;
            min-height: 100%;
            max-height: none;
            width: inherit;
            min-width: 100%;
            max-width: none;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 4;
            min-width: 100%;
            min-height: 100%;
        }
    }

    &__content-wrapper {
        //max-width: 146rem;
        //padding: 0 3rem;
        //left: 50%;
        //transform: translateX(-50%);

        width: 100%;
        padding: 0 4rem;
        position: absolute;
        bottom: 11.8rem;
        left: 0;
        z-index: 8;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        @include breakpoint(medium down) {
            padding: 0 2rem;
        }
        @include breakpoint(small down) {
            bottom: 4rem;
            padding: 0 1.5rem;
        }

        //&:before {
        //    @include breakpoint(large up) {
        //        content: "";
        //        display: block;
        //        //width: 60rem;
        //        width: 52rem;
        //        //height: 58rem;
        //        height: 59rem;
        //        background: rgba($color-1--3, .8);
        //        border-top-left-radius: 100% 100%;
        //        position: absolute;
        //        left: calc(100% - 44.6rem);
        //        bottom: -33.8rem;
        //        z-index: -1;
        //    }
        //}
    }

    &__content {
        display: inline-block;
        vertical-align: middle;
        //max-width: 100%;
        max-width: 40rem;
        flex: 0 1 auto;
        text-align: left;
        @include breakpoint(medium only) {
            max-width: 30rem;
        }
        @include breakpoint(small down) {
            max-width: 18rem;
        }

        &-theme {
            display: inline-block;
            vertical-align: top;
            font-family: $typo-2;
            font-weight: $bold;
            font-style: italic;
            font-size: 1.7rem;
            line-height: 1.2;
            color: $color-black;
            text-transform: uppercase;
            white-space: nowrap;
            background: $color-2--3;
            padding: .7rem 3rem;
            margin-bottom: 1.4rem;
            @include breakpoint(medium only) {
                font-size: 1.5rem;
                padding-left: 2.2rem;
                padding-right: 2.2rem;
            }
            @include breakpoint(small down) {
                font-size: 1.2rem;
                padding-left: 1.2rem;
                padding-right: 1.2rem;
            }
        }

        &-title {
            font-family: $typo-1;
            font-weight: $light;
            font-size: 3.2rem;
            line-height: 1em;
            color: $color-white;
            padding-left: 3rem;
            @include breakpoint(medium only) {
                font-size: 2.2rem;
                line-height: em(2.4, 2.2);
                padding-left: 2.2rem;
            }
            @include breakpoint(small down) {
                font-size: 1.4rem;
                line-height: em(1.6, 1.4);
                padding-left: 1.2rem;
            }

            a {
                color: inherit;
                outline-color: $color-white;

                &:focus {
                    outline-color: $color-white;
                }
            }
        }
    }

    .slick-arrow {
        display: block;
        z-index: 10;
        position: absolute;
        top: 50%;
        left: 4.7rem;
        width: 4rem;
        height: 4rem;
        margin-top: -2rem;
        outline-color: $color-2--3;
        text-indent: -9999px;
        overflow: hidden;
        &:before {
            font-family: "icons-default";
            font-size: 3rem;
            color: $color-white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 1;
            text-indent: 0;
            transition: all 300ms ease;
        }
        &:hover:before, &:focus:before {
            color: $color-2--3;
        }
        &:focus {
            outline-color: $color-2--3;
        }
        &.slick-prev {
            &:before {
                content: "\e016";
            }
        }
        &.slick-next {
            left: auto;
            right: 4.7rem;
            &:before {
                content: "\e017";
            }
        }
    }

    .slick-dots {
        display: flex;
        flex-wrap: nowrap;
        position: absolute;
        bottom: 1.5rem;
        right: 3.8rem;
        z-index: 80;

        > li {
            display: block;
            padding: 0 .2rem;

            &.slick-active > button {
                background: $color-2--3;
            }

            > button {
                display: block;
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background: $color-white;
                text-indent: -5rem;
                overflow: hidden;
                transition: all 300ms ease;
                outline-color: $color-white;

                &:hover, &:focus {
                    background: $color-2--3;
                }

                &:focus {
                    outline-color: $color-white;
                }
            }
        }
    }
}

.hero-carousel-controls {
    display: none;

    @include breakpoint(large up) {
        display: inline-block;
        white-space: nowrap;

        &__item {
            display: inline-block;
            vertical-align: middle;
            padding: .4rem;
            width: 1.6rem;
            height: 1.6rem;
            overflow: hidden;
            text-indent: -10rem;
            transition: all 300ms ease;
            position: relative;
            outline-color: $color-2--3;

            &:hover, &:focus {
                &:before {
                    color: $color-2--3;
                }
            }

            &:focus {
                outline-color: $color-2--3;
            }

            &:before {
                display: block;
                font-family: "icons-default";
                font-size: 1.6rem;
                line-height: 1em;
                color: $color-white;
                text-indent: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all 300ms ease;
            }

            &--play:before {
                content: "\e075";
            }

            &--pause:before {
                content: "\e076";
            }
        }
    }
}
