// Organigramme list layout
.organigramme {

    &__list {
        list-style: none;
        margin: 0 0 -5rem;
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        width: 48%;
        margin: 0 0 5rem;
        @include breakpoint(medium down) {
            width: 50%;
        }
        @include breakpoint(small down) {
            width: 100%;
        }

        &:nth-child(odd) {
            width: 52%;
            padding-right: 2rem;
            @include breakpoint(medium down) {
                width: 50%;
            }
            @include breakpoint(small down) {
                width: 100%;
                padding-right: 0;
            }
        }
    }
}

// Organigramme card
.organigramme-card {
    display: flex;
    flex-wrap: nowrap;

    &__img {
        flex-shrink: 0;
        width: 10.7rem;
        margin-right: 1rem;

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: none;
        }
    }

    &__content {
        flex: 1 1 auto;
    }

    &__position {
        display: block;
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 1.2rem;
        line-height: 1.2em;
        text-transform: uppercase;
        color: $color-1--5;
        margin: -0.2rem 0 0.5rem;
    }

    &__title {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 2rem;
        line-height: 1.2em;
        color: $color-black;
        margin: 0 0 -.1rem;
    }

    &__description {
        font-weight: $light;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: $color-3--8;
        margin: 0;
    }

    &__tel {
        display: table;
        max-width: 100%;
        font-weight: $light;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: $color-3--8;
        margin: 1rem 0 0;
    }

    &__contact-control {
        font-size: 1.6rem;
        line-height: 1.25em;
        padding: 1.5rem 1.5rem 1.5rem 5.8rem;
        margin-top: .8rem;
        @include breakpoint(small down) {
            margin-top: 1.7rem;
        }
        &:before {
            content: "\e02a";
            font-size: 1.3rem;
            line-height: 5rem;
            width: 5rem;
            height: 5rem;
            margin-top: -2.5rem;
        }
    }
}
