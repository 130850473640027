// @name Liste type 1
// @description Basic list
.list-type-1 {
    margin-bottom: 5em;
    @extend .clear-fix;
    .list-type-1__date-2 {
        margin-top: em(-1, $date-2__font-size);
    }
}

.list-type-1__item {
    padding: 2em 0;
    @extend .clear-fix;
    @include breakpoint(small down) {
        padding: 4em 0;
    }
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }
}

.list-type-1__picture {
    display: block;
    float: left;
    //margin-right: 2rem;
    @include breakpoint(small down) {
        float: none;
        margin-right: 0;
        margin-bottom: 2em;
    }
    img {
        margin-right: 2em;
        max-width: 220px;
        height: auto;
        @include breakpoint(medium down) {
            max-width: 120px;
        }
        @include breakpoint(small down) {
            max-width: 100%;
            margin-right: 0;
        }
    }
}

.list-type-1__wrapper {
    overflow: hidden;
}

.list-type-1__title {
    font-family: $typo-1;
    font-weight: $bold;
    font-size: 2em;
    line-height: 1em;
    color: $color-black;
    margin: .25em 0 .8em;
    @include breakpoint(small down) {
        font-size: 1.6em;
        line-height: 1.2em;
        margin-bottom: em(1.8, 1.6);
    }
    a {
        color: inherit;
    }
}
