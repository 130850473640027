// Custom section title
.section-title {
    font-family: $typo-1;
    font-weight: $thin;
    font-size: 3.4rem;
    line-height: 1.2;
    color: $color-3--4;
    letter-spacing: em(-.08, 3.4);
    text-transform: uppercase;
    text-align: left;
    margin: em(6, 3.4) 0 em(5, 3.4) 0;
    padding-bottom: em(1.8, 3.4);
    position: relative;
    @include breakpoint(small down) {
        font-size: 2.5rem;
        margin: em(4, 2.5) 0 em(3.7, 2.5) 0;
        padding-bottom: em(2, 2.5);
    }

    &:after {
        content: "";
        display: block;
        width: 12rem;
        height: .7rem;
        background: $color-2--3;
        position: absolute;
        left: -7rem;
        bottom: 0;
    }

    &--variation-1:after {
        background: $color-1--3;
    }

    &--variation-2:after {
        background: $color-1--8;
    }

    &--right-layout {
        text-align: right;

        &:after {
            left: auto;
            right: -7rem;
        }
    }
}


// Custom section styles
.section {

}

// Custom section styles on home page
.section-home {

    .section-title {
        @include breakpoint(medium only) {
            font-size: 2.6rem;
            padding-bottom: em(2, 2.6);
        }
        @include breakpoint(small down) {
            padding-bottom: em(1.4, 2.5);
        }

        &:after {
            width: 15rem;
            left: auto;
            right: calc(100% - 5rem);
        }

        &--right-layout {
            &:after {
                right: auto;
                left: calc(100% - 5rem);
            }
        }
    }
}


// Custom section-in-conten styles
.section-in-content {
    margin: 5em 0;
    @include breakpoint(medium down) {
        margin: 4em 0;
    }

    .section-title {
        //padding-bottom: em(1.8, 3.4);
        margin: 0 0 2.5rem;
        //@include breakpoint(small down) {
        //    padding-bottom: em(2, 2.5);
        //}
    }

    &--line-layout {
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(large up) {
            padding-top: 1.8rem;
        }
    }

    &__col-title {
        width: 25rem;
        @include breakpoint(medium down) {
            width: 100%;
        }

        .section-title {
            @include breakpoint(large up) {
                margin: -.3rem 0 0;
                font-size: 2.6rem;
                padding-bottom: em(1.8, 2.6);
            }
            @include breakpoint(small down) {
                margin-bottom: 2rem;
            }
        }
    }

    &__col-content {
        width: calc(100% - 25rem);
        @include breakpoint(medium down) {
            width: 100%;
            padding-left: 21%;
        }
        @include breakpoint(small down) {
            //padding-left: 7%;
            padding-left: 10%;
        }
    }
}
