// @name Liste type 2
// @description Basic list by default 4 columns
// @state .list-type-2--3col - 3 columns
// @state .list-type-2--2col - 2 columns
.list-type-2 {
    margin-bottom: 5em;
    @extend .clear-fix;
    &.list-type-2--3col {
        .list-type-2__listitems {
            .list-paginated__wrapper {
                > article {
                    flex: 0 1 29.3333%;
                    @include breakpoint(small down) {
                        flex-basis: 100%;
                    }
                    @include breakpoint(medium down) {
                        flex-basis: 46%;
                    }
                }
            }
        }
    }
    &.list-type-2--2col {
        .list-type-2__listitems {
            .list-paginated__wrapper {
                > article {
                    flex: 0 1 46%;
                    @include breakpoint(small down) {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
}

.list-type-2__listitems {
    > .list-paginated__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -2%;
        > article {
            flex: 0 1 21%;
            margin: 0 2% 3em;
            @include breakpoint(small down) {
                flex-basis: 100%;
            }
            @include breakpoint(medium down) {
                flex-basis: 46%;
            }
        }
    }
}

.list-type-2__picture {
    display: block;
    margin-bottom: 1em;
    img {
        max-width: 100%;
        height: auto;
    }
    &.list-type-2__picture--video {
        @include default-icons-absolute-before('\e045', 5rem, $color-1--2, 50%, inherit, inherit, 50%);
        &:before {
            transform: translateY(-50%) translateX(-50%);
            z-index: 2;
        }
        &:after {
            content: "";
            width: 30px;
            height: 30px;
            background: $color-white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            z-index: 1;
        }
    }
}

// @description To align image below
.list-type-2__picture--2 {
    display: table-cell;
    vertical-align: bottom;
    height: 19em;
    margin-bottom: 0;
    padding-bottom: 1em;
}

.list-type-2__title {
    font-family: $typo-3;
    color: $color-black;
    font-size: 1.6em;
    line-height: 1.2em;
    font-weight: $bold;
    //margin: .2em 0 .8em;
    margin: .25em 0 .8em;

    a {
        color: inherit;
    }
}

.list-type-2__date {
    margin-top: 0;
}
