.home-discover {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    position: relative;
    @include breakpoint(medium down) {
        display: block;
        padding-bottom: 33.6rem;
        //margin-bottom: -33.6rem;
    }
    @include breakpoint(small down) {
        padding-bottom: 2rem;
        margin: 0 -1rem;
    }

    .section-title {
        width: 100%;
        margin-top: 0;
        //padding-right: 51.2rem;
        padding-right: 55.5%;
        @include breakpoint(medium down) {
            padding-right: 0;
            margin-bottom: 2rem;
        }
        @include breakpoint(small down) {
            margin-bottom: 4.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    &__article {
        flex-grow: 1;
        //width: calc(100% - 51.2rem);
        width: 44.5%;
        //padding: 0 4.5rem 3.5rem 0;
        padding: 0 5% 3.5rem 0;
        @include breakpoint(medium down) {
            width: 100%;
            padding: 0;
        }
        @include breakpoint(small down) {
            padding: 0 2rem 2rem;
        }

        &-title {
            font-weight: $bold;
            font-size: 3.4rem;
            line-height: 1;
            color: $color-3--4;
            @include breakpoint(medium down) {
                font-size: 2.6rem;
                line-height: 1.2;
            }
            @include breakpoint(small down) {
                font-size: 2.2rem;
                line-height: 1;
            }

            a {
                color: inherit;
            }
        }

        &-description {
            font-weight: $light;
            font-size: 1.8rem;
            line-height: 2.6rem;
            color: $color-3--4;
            margin-top: 2rem;
            @include breakpoint(medium down) {
                margin-top: .8rem;
                font-size: 1.6rem;
                line-height: 2rem;
            }
            @include breakpoint(small down) {
                margin-top: 1.3rem;
                line-height: 2.2rem;
            }
        }
    }

    &__picture-container {
        margin: -15.8rem 0 -3rem;
        //padding-left: 3rem;
        padding-left: 3.5%;
        //width: 51.2rem;
        width: 55.5%;
        position: relative;
        z-index: 2;
        @include breakpoint(medium down) {
            margin: 0;
            padding-left: 2rem;
            width: auto;
            position: absolute;
            left: 0;
            //top: calc(100% + 2.9rem);
            bottom: 2.9rem;
        }
        @include breakpoint(small down) {
            position: relative;
            left: auto;
            bottom: auto;
            width: 32rem;
            margin: 0 auto;
            padding: 0;
        }

        &:before {
            @include breakpoint(large up) {
                content: "";
                display: block;
                width: 20rem;
                height: 20rem;
                border-radius: 50%;
                background: rgba($color-black, .2);
                box-shadow: 0 0 5rem 4.5rem rgba($color-black, .2);
                position: absolute;
                bottom: 6rem;
                left: 8rem;
            }
            @include breakpoint(small down) {
                content: "";
                display: block;
                width: 15rem;
                height: 15rem;
                border-radius: 50%;
                background: rgba($color-black, .4);
                box-shadow: 0 0 4rem 3.5rem rgba($color-black, .4);
                position: absolute;
                bottom: 4rem;
                left: 5rem;
            }
        }

        .link-view-more {
            position: absolute;
            left: 0;
            bottom: 11rem;
            z-index: 8;
            @include breakpoint(medium down) {
                width: 3.8rem;
                height: 3.8rem;
                bottom: 6.5rem;

                &:before {
                    font-size: 1.5rem;
                }
            }
            @include breakpoint(small down) {
                width: 6rem;
                height: 6rem;
                left: 1rem;
                bottom: 2.3rem;

                &:before {
                    font-size: 2rem;
                }
            }
        }
    }

    &__picture {
        display: block;
        //width: 48.2rem;
        width: 100%;
        padding-top: 100%;
        //height: 48.2rem;
        border-radius: 50%;
        background: $color-3--1;
        overflow: hidden;
        position: relative;
        @include breakpoint(medium down) {
            width: 27.8rem;
            height: 27.8rem;
            padding-top: 0;
        }
        @include breakpoint(small down) {
            width: 32rem;
            height: 32rem;
        }

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: inset -1.1rem 1.1rem 0 0 $color-2--8;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 6;

        }

        img {
            vertical-align: top;
            height: inherit;
            min-height: 100%;
            max-height: none;
            width: inherit;
            min-width: 100%;
            max-width: none;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
        }
    }
}
