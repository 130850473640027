// @name Header page;
.header {
    position: relative;
    z-index: 500;
    min-height: 14.5rem;
    @extend .clear-fix;
    background: $color-white;
    @include breakpoint(medium down) {
        height: 7rem;
        min-height: auto;
    }

    &__logo {
        display: flex;
        align-items: stretch;
        width: 34.7rem;
        height: 100%;
        padding-right: 4rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        overflow: hidden;
        @include breakpoint(medium down) {
            width: 24rem;
            padding-right: 5rem;
        }

        .logo {
            display: flex;
            align-items: center;
            margin-left: 5rem;
            background: $color-white;
            position: relative;
            z-index: 15;
            margin-top: -1.5rem;
            @include breakpoint(medium down) {
                width: 16.7rem;
                margin: 0 0 0 2rem;
            }
            //@include breakpoint(small down) {
            //    margin: -1.5rem 0 0 2rem;
            //}

            &:before {
                content: "";
                display: block;
                width: 22rem;
                height: 22rem;
                border-radius: 50%;
                background: $color-white;
                box-shadow: inset -0.7rem 0.7rem 0 0 $color-3--1;
                position: absolute;
                //top: -4.6rem;
                top: -3rem;
                right: -4rem;
                z-index: -1;
                @include breakpoint(medium down) {
                    width: 14.5rem;
                    height: 14.5rem;
                    box-shadow: inset -0.9rem 0.9rem 0 0 rgba($color-3--1, .4);
                    top: -6rem;
                    right: -4.5rem;
                }
                @include breakpoint(small down) {
                    box-shadow: inset -1.1rem 1.1rem 0 0 rgba($color-3--1, .4);
                    top: -6.5rem;
                    right: -3rem;
                }
            }
        }
    }

    &__top-menu {
        display: none;
        @include breakpoint(large up) {
            display: flex;
            margin-left: 30.7rem;
        }
    }

    &__bottom-bar {
        //min-height: 13.3rem;
        height: 13.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 34.7rem;
        padding: 1rem 5.5rem 1rem 1rem;
        @include breakpoint(medium down) {
            //min-height: auto;
            height: auto;
            padding: 0;
            margin: 0;
        }
    }

    &__menu-main {
        //display: flex;
        //flex-wrap: wrap;
        //align-items: center;
        display: block;
        width: calc(100% - 9.2rem);
        @include breakpoint(medium down) {
            width: 100%;
        }
    }

    &__search {
        display: none;
        @include breakpoint(large up) {
            display: block;
            margin-left: 2rem;
        }
    }
}

.nav {

    &__top-menu {
        display: none;
        @include breakpoint(medium down) {
            display: block;
        }
    }

    &__search {
        display: none;
        @include breakpoint(medium down) {
            display: block;
        }
    }
}
