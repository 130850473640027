.menu-main-1__sublevel {
    display: none;
    &[aria-hidden="false"] {
        display: block;
    }
}

//
.menu-main-1__item--no-action-in-desktop {
    .menu-main-1__sublevel {
        display: block;
        @include breakpoint(medium down) {
            display: none;
            &[aria-hidden="false"] {
                display: block;
            }
        }
    }
}

/* Styles */

// All level
.menu-main-1__wrapper {
    //position: relative;
    @include breakpoint(medium down) {
        z-index: 100;
        position: static;
    }
}

.menu-main-1 {
    //@extend .wrapper-main;

    @include breakpoint(medium down) {
        .wrapper-main {
            width: 100%;
            padding: 0;
            margin: 0;
        }
    }
}

@include breakpoint(medium down) {
    body.menu-main-1--active {
        overflow-y: hidden;
        .menu-main-1__listitems-0 {
            > .menu-main-1__item {
                overflow-y: auto;
                height: 100%;
            }
        }
    }
}

.menu-main-1__item {
    > .menu-main-1__header {
        a {
            display: block;
        }
    }
    > .menu-main-1__sublevel {

    }
    //&.menu-main-1__item--current {
    //    text-decoration: underline;
    //}
}

.menu-main-1__closing-button {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 30px;
    height: 30px;
    background: transparent;
    margin: 0;
    padding: 0;
    &:before {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "\e022";
        font-family: "icons-default";
        font-size: 3rem;
        line-height: 0;
        color: $color-2--3;
        text-indent: 0;
        margin: 0;
    }
}

// Level 0 (displayed in tablet and smartphone)
.menu-main-1__listitems-0 {
    > li {
        @include breakpoint(medium down) {
            //position: absolute;
            position: fixed;
            z-index: 101;
            top: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            transition: transform ease .3s;
            transform: translate(100%, 0);
            width: 100%;
            background: rgba($color-black, .5);
            //background: $color-white;
        }
        &.menu-main-1__item--active {
            @include breakpoint(medium down) {
                transform: translate(0, 0);
                > .menu-main-1__header {
                    .menu-main-1__action {
                        //left: 0;
                        //top: 0;
                        display: none;
                    }
                }
            }
        }
        > .menu-main-1__header {
            display: none;
            @include breakpoint(medium down) {
                display: block;
                background: $color-white;
                padding: 2.5rem 0;
                border-bottom: 0.1rem solid $color-3--3;
                > span {
                    display: block;
                    text-transform: uppercase;
                    font-family: $typo-2;
                    font-weight: $bold;
                    font-size: 2rem;
                    line-height: 2rem;
                    color: $color-3--3;
                    margin: 0 0 0 2rem;
                    position: relative;

                    &:before {
                        content: "\e02f";
                        font-family: "icons-default";
                        font-weight: initial;
                        font-size: 1.6rem;
                        line-height: 1em;
                        color: $color-2--3;
                        padding-right: 1rem;
                    }
                }
                .menu-main-1__action {
                    text-align: left;
                    text-indent: -9999px;
                    white-space: nowrap;
                    overflow: hidden;
                    position: absolute;
                    top: 1.5rem;
                    left: -5.5rem;
                    width: 4rem;
                    height: 4rem;
                    border-radius: 50%;
                    background: $color-3--2;
                    box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba($color-black, 0.05), inset -0.4rem 0.4rem 0 0 rgba($color-3--4, 0.17);
                    //transition: left ease .3s, top ease .3s;
                    transition: all ease .3s;
                    &:before {
                        content: "\e02f";
                        font-family: "icons-default";
                        font-size: 1.2rem;
                        line-height: 0;
                        color: $color-3--4;
                        text-indent: 0;
                        margin: 0;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%);
                        transition: all ease .3s;
                    }

                    &:hover, &:focus {
                        background: $color-1--3;
                        &:before {
                            color: $color-white;
                        }
                    }
                }
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            @include breakpoint(medium down) {
                display: none;
                &[aria-hidden="false"] {
                    display: block;
                }
            }
            > .menu-main-1__closing-button {
                display: none;
                @include breakpoint(medium down) {
                    display: block;
                }
            }
        }
    }
}

// Level 1
.menu-main-1__listitems-1 {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(medium down) {
        background: $color-white;
        display: block;
    }
    > li {
        display: inline-block;
        vertical-align: middle;
        margin: 0.1rem;
        @include breakpoint(medium down) {
            display: block;
            vertical-align: inherit;
            border-bottom: 1px solid $color-3--3;
            margin: 0;
        }

        &.menu-main-1__item--current {
            > .menu-main-1__header > a {
                font-weight: $light;

                &:before {
                    background: $color-1--3;
                }
            }
        }

        &.menu-main-1__item--active {
            > .menu-main-1__header > a {
                background: $color-1--3;
                color: $color-white;
                text-decoration: none;

                &:before {
                    //color: $color-2--3;
                    color: $color-1--3;
                    background: $color-white;
                }
            }
        }

        > .menu-main-1__header {
            > a {
                font-family: $typo-2;
                font-weight: $bold;
                font-size: 1.8rem;
                line-height: 2rem;
                color: $color-3--3;
                //color: $color-white;
                text-transform: uppercase;
                padding: 1.5rem 1.5rem 1.5rem 4rem;
                background: transparent;
                position: relative;
                border-radius: .8rem;
                white-space: nowrap;
                transition: all 300ms ease;
                @include breakpoint(medium down) {
                    border-radius: 0;
                    padding: 3rem 1.5rem 3rem 7rem;
                    white-space: normal;
                }

                &:before {
                    content: "\e024";
                    font-family: "icons-default";
                    font-size: 1rem;
                    color: $color-white;
                    text-align: center;
                    line-height: 2.4rem;
                    display: block;
                    width: 2.2rem;
                    height: 2.2rem;
                    margin-top: -1.1rem;
                    border-radius: 50%;
                    background: $color-2--3;
                    transition: all 300ms ease;
                    position: absolute;
                    top: 50%;
                    left: 1.2rem;
                    @include breakpoint(medium down) {
                        left: 3rem;
                    }
                }

                &:hover, &:focus {
                    background: $color-1--3;
                    color: $color-white;
                    text-decoration: none;

                    &:before {
                        //color: $color-2--3;
                        color: $color-1--3;
                        background: $color-white;
                    }
                }
            }
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            z-index: 10;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            max-height: 0;
            visibility: hidden;
            overflow: hidden;
            transition: visibility ease .2s, max-height ease .2s;
            @include breakpoint(medium down) {
                position: static;
                max-height: inherit;
                display: none;
            }
            // level 1 Displayed
            &[aria-hidden="false"] {
                visibility: visible;
                max-height: 3000px;
                @include breakpoint(medium down) {
                    display: block;
                    max-height: inherit;
                    .menu-main-1__listitems-1__wrapper {
                        transform: inherit;
                        transition: inherit;
                    }
                }
                .menu-main-1__listitems-1__wrapper {
                    transform: translateY(0);
                    transition: transform ease-out .4s .2s;
                }
            }
            .menu-main-1__listitems-1__wrapper {
                transform: translateY(-110%);
                transition: transform ease-in .2s;
                height: 100%;
                background: $color-3--5;
                box-shadow: inset 36rem 0 0 0 $color-1--3;
                //padding: 2em 0;
                //padding: 0 5.5rem 0 35.8rem;
                padding: 0 15rem 0 35.5rem;
                @media(max-width: 1500px) {
                    box-shadow: inset 14rem 0 0 0 $color-1--3;
                    padding-left: 13.7rem;
                    padding-right: 5.5rem;
                }
                @include breakpoint(medium down) {
                    border-top: .1rem solid $color-white;
                    background: $color-1--3;
                    transform: inherit;
                    height: auto;
                    padding: 0;
                    box-shadow: none;
                }
                > .wrapper-main {
                    max-width: 100%;
                    padding: 0;
                }

                //a, button, p {
                //    color: $color-white;
                //    &:focus {
                //        outline-color: $color-3--4;
                //    }
                //}
            }
        }
    }
}

// Level 2
.menu-main-1__listitems-2 {
    //width: 22rem;
    width: 30rem;
    position: relative;
    height: auto;
    transition: height ease .3s;
    background: $color-1--3;
    padding: .8rem 0;
    @include breakpoint(medium down) {
        width: 100%;
        padding: 0;
    }
    > li {
        display: block;
        padding: .5rem 0;
        @include breakpoint(medium down) {
            padding: 0;
        }

        &.menu-main-1__item--active {
            > .menu-main-1__header {
                > a {
                    background: $color-3--5;
                    color: $color-3--4;
                    text-decoration: none;

                    &:before {
                        color: $color-3--4;
                        //@include breakpoint(medium down) {
                        //    transform-origin: 50% 50%;
                        //    transform: rotate(90deg);
                        //}
                    }
                }

                //> .menu-main-1__action:focus {
                //    outline-color: $color-3--4;
                //}
            }
        }

        &:last-child > .menu-main-1__sublevel {
            @include breakpoint(medium down) {
                border-bottom: none;
            }
        }

        // Level 2
        > .menu-main-1__header {
            position: relative;
            > a {
                font-family: $typo-3;
                font-weight: $bold;
                //font-weight: $medium;
                font-size: 1.6rem;
                //font-size: 1.5rem;
                line-height: 2rem;
                color: $color-white;
                text-transform: uppercase;
                padding: 1.5rem 2rem 1.5rem 3.5rem;
                //padding: 1.5rem 3.5rem 1.5rem 3.5rem;
                position: relative;
                transition: all 300ms ease;
                //outline-color: $color-white;
                @include breakpoint(medium down) {
                    padding: 2rem 2rem 2rem 7rem;
                    //padding: 2rem 5rem 2rem 7rem;
                }

                &:before {
                    content: "\e027";
                    font-family: "icons-default";
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    color: $color-2--3;
                    text-align: center;
                    transition: all 300ms ease;
                    position: absolute;
                    //top: 1.5rem;
                    top: 50%;
                    //left: 2.2rem;
                    left: 1.7rem;
                    transform: translateY(-50%);
                    @include breakpoint(medium down) {
                        //top: 2rem;
                        left: 5rem;
                    }
                }

                &:hover, &:focus {
                    background: $color-3--5;
                    color: $color-3--4;
                    text-decoration: none;

                    &:before {
                        color: $color-3--4;
                    }
                }

                //&:focus {
                //    outline-color: $color-white;
                //}
            }

            //> .menu-main-1__action {
            //    flex-basis: 3rem;
            //    position: absolute;
            //    top: 0;
            //    right: 0;
            //    text-indent: -9999px;
            //    width: 3rem;
            //    height: 100%;
            //    overflow: hidden;
            //    transition: all 300ms ease;
            //    outline-color: $color-white;
            //    @include breakpoint(medium down) {
            //        width: 5rem;
            //        border-right: 1rem solid transparent;
            //        border-left: 1rem solid transparent;
            //    }
            //
            //    &:hover {
            //        &:before {
            //            background: $color-3--2;
            //            text-decoration: none;
            //        }
            //    }
            //
            //    &:focus {
            //        outline-color: $color-white;
            //    }
            //
            //    &:before {
            //        position: absolute;
            //        top: 50%;
            //        left: 0;
            //        margin: -1.5rem 0 0;
            //        display: block;
            //        width: 100%;
            //        height: 3rem;
            //        text-align: center;
            //        background: $color-2--3;
            //        content: "\e08a";
            //        font-family: "icons-default";
            //        font-size: 1.2em;
            //        line-height: 3rem;
            //        color: $color-white;
            //        text-indent: 0;
            //    }
            //    &[aria-expanded="true"] {
            //        &:before {
            //            content: "\e023";
            //            background: $color-3--2;
            //        }
            //    }
            //}
        }
        > .menu-main-1__sublevel {
            display: block;
            position: absolute;
            left: 100%;
            top: 0;
            //width: calc(100vw - 72.5rem);
            width: calc(100vw - 80.5rem);
            visibility: hidden;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform ease .2s, visibility ease 0s .2s;
            background: transparent;
            padding: 2rem 0 2rem 5rem;
            @media(max-width: 1500px) {
                //width: calc(100vw - 49.7rem);
                width: calc(100vw - 49.2rem);
            }
            @include breakpoint(medium down) {
                display: none;
                position: static;
                width: 100%;
                transform: inherit;
                height: 0;
                transition: inherit;
                background: $color-3--5;
                border-top: 0.1rem solid $color-3--3;
                border-bottom: 0.1rem solid $color-3--3;
                padding: 1.2rem 1.5rem 1.2rem 6rem;
            }
            &[aria-hidden="false"] {
                visibility: visible;
                transform: scaleX(1);
                transition: transform ease .4s, -webkit-transform ease .4s;
                @include breakpoint(medium down) {
                    display: block;
                    transform: inherit;
                    height: auto;
                    transition: inherit;
                }
            }
        }
    }
}

.menu-main-1__closing-button {
    display: none;
}

// Level 3
.menu-main-1__listitems-3 {
    flex: 0 0 auto;
    width: 33.3%;
    padding-left: 2rem;
    @include breakpoint(medium down) {
        width: 100%;
        padding: 0;
    }

    &--wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        @include breakpoint(medium down) {
            display: block;
        }
    }

    > li {
        margin: 0.3rem 0;
        @include breakpoint(medium down) {
            margin: 0.4rem 0;
        }
        > .menu-main-1__header {
            > a {
                font-family: $typo-3;
                font-weight: $normal;
                font-size: 1.3rem;
                line-height: 1.2em;
                color: $color-3--4;
                padding: 0.5rem 0 .5rem 1rem;
                position: relative;
                transition: all 300ms ease;
                //@include breakpoint(medium down) {
                //    padding-top: .5em;
                //    padding-bottom: .5em;
                //    padding-left: 1em;
                //}

                &:before {
                    content: "\e01b";
                    font-family: "icons-default";
                    font-size: 1rem;
                    line-height: 1.2em;
                    color: $color-2--3;
                    text-align: center;
                    transition: all 300ms ease;
                    position: absolute;
                    top: 0.6rem;
                    left: 0;
                }

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }

        //> .menu-main-1__sublevel {
        //    display: block;
        //    position: absolute;
        //    left: 100%;
        //    top: 0;
        //    width: calc(118rem - 78rem);
        //    visibility: hidden;
        //    transform: scaleX(0);
        //    transform-origin: 0 0;
        //    transition: transform ease .2s, visibility ease 0s .2s;
        //    background: darken($color-1--2, 10%);
            //@include breakpoint(medium down) {
            //    display: none;
            //    position: static;
            //    width: 100%;
            //    transform: inherit;
            //    height: 0;
            //    transition: inherit;
            //    background: darken($color-1--2, 15%);
            //}
        //    &[aria-hidden="false"] {
        //        transition: transform ease .4s, visibility ease 0s 0s;
        //        visibility: visible;
        //        transform: scaleX(1);
        //        //@include breakpoint(medium down) {
        //        //    display: block;
        //        //    transform: inherit;
        //        //    height: auto;
        //        //    transition: inherit;
        //        //}
        //    }
        //}
    }
}

// Level 4
//.menu-main-1__listitems-4 {
//    padding: 1em;
//    @include breakpoint(medium down) {
//        padding: .5em 0;
//    }
//    > li {
//        margin: 0.8em 0;
//        > .menu-main-1__header {
//            a {
//                font-size: 1.5em;
//                @include breakpoint(medium down) {
//                    padding-top: .3em;
//                    padding-bottom: .3em;
//                    padding-left: 1.7em;
//                }
//            }
//        }
//    }
//}
