.documents-wrapper {
    padding: 8.5rem 0 3.6rem;
    overflow: hidden;
    position: relative;
    z-index: 10;
    @include breakpoint(medium down) {
        padding: 4.5rem 0 5.5rem;
    }
    @include breakpoint(small down) {
        padding: 6.7rem 0 0;
    }

    &__inner {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        @include breakpoint(medium down) {
            align-items: flex-end;
        }
        @include breakpoint(small down) {
            display: block;
        }
    }

    &__publications {
        width: calc(100% - 33.5rem);
        flex: 1 1 auto;
        position: relative;
        z-index: 12;
        @include breakpoint(small down) {
            width: 100%;
            z-index: 16;

            + .documents-wrapper__newsletter {
                margin-top: -3rem;
            }
        }
    }

    &__newsletter {
        width: 33.5rem;
        flex: 0 0 auto;
        position: relative;
        z-index: 14;
        @include breakpoint(small down) {
            width: calc(100% + 2rem);
            background: $color-2--3;
            margin: 0 -1rem;
            padding: 6rem 1rem 5.5rem;
        }
    }
}
