// @name Top of content
.top-of-content {
    background: $color-3--5;
    @include breakpoint(small down) {
        display: none;
    }
}

.top-of-content__wrapper {
    @extend .wrapper-main;
    padding-top: 2em;
    padding-bottom: 2.8em;
    //border-bottom: 1px solid #c5c5c5;
    position: relative;
    @include breakpoint(medium down) {
        padding-top: 2.6em;
    }

    &:before {
        content: "";
        display: block;
        width: calc(100% - 4rem);
        height: .1rem;
        background: #c5c5c5;
        position: absolute;
        bottom: 0;
        left: 2rem;
    }
}

// @name Page wrapper
.page {
    overflow-x: hidden!important;
}

// @name Main wrapper
.main {
    width: 100%;
    clear: both;
}

// @name Main section
.section-main {

}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-main;
    padding-top: 6.3em;
    padding-bottom: 6em;
    @include breakpoint(medium down) {
        padding-top: 3em;
        padding-bottom: 4em;
    }
    @include breakpoint(small down) {
        width: 100%;
        //padding-top: 3em;
        padding-bottom: 3em;
    }
}

.section-main__wrapper-2 {
    display: block;
    width: 100%;
    @include breakpoint(large up) {
        display: flex;
        flex-wrap: nowrap;
        //flex-direction: column;
        //flex-wrap: wrap;
    }

    .section-main__aside {
        width: 100%;
        max-width: 100%;
        @include breakpoint(large up) {
            width: 28rem;
            flex-shrink: 0;

            + .section-main__content {
                padding-left: 7rem;
                width: calc(100% - 28rem);
            }
        }
    }

    .section-main__content {
        width: 100%;
        max-width: 100%;
        @include breakpoint(large up) {
            width: calc(100% - 35rem);
            flex: 1 1 auto;

            + .section-main__aside {
                width: 35rem;
                padding-left: 7rem;
            }
        }
    }

    //> * {
    //    display: table-cell;
    //    vertical-align: top;
    //    @include breakpoint(small down) {
    //        display: block;
    //        vertical-align: inherit;
    //    }
    //}
}

// @name Main section content
.section-main__content {
//    width: 100%;

    //> *:last-child,
    //.colPos0:last-child > .section {
    //    margin-bottom: 0;
    //}

    > .anchor:first-child + div[class*="col"] > *:first-child,
    > div[class*="col"]:first-child > *:first-child,
    > .anchor:first-child + div[class*="col"] .side-anchor,
    > div[class*="col"]:first-child .side-anchor,
    > .anchor:first-child + div[class*="col"] > .section-in-content:first-child > *:first-child,
    > div[class*="col"]:first-child > .section-in-content:first-child > *:first-child,
    > .anchor:first-child + div[class*="col"] > .section-in-content:first-child > .side-anchor,
    > div[class*="col"]:first-child > .section-in-content:first-child > .side-anchor,
    > .section-in-content:first-child > *:first-child,
    > .section-in-content:first-child,
    > .rte:first-child > .story:first-child > .side-anchor,
    > .rte:first-child > h2:first-child,
    > .pager:first-child > ul {
        margin-top: 0;
    }
}

// @name Main section aside
//.section-main__aside {
//    > .column-bloc {
//        *:first-child {
//            margin-top: 0;
//        }
//    }
//}

// @name Main section aside - sidebar
.sidebar {
    position: relative;
    z-index: 200;
    padding: 1.3rem 0 0;
    @include breakpoint(medium down) {
        padding: 0 0 3rem;
    }
}

