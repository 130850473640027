.research-wrapper {
    position: relative;
    z-index: 20;

    &:before {
        @include breakpoint(large up) {
            content: "";
            display: block;
            //width: 48%;
            width: 47%;
            height: 100%;
            background-color: transparent;
            background-image: url(../Images/custom/bg/bg-know-how.svg);
            background-position: 0 calc(100% + 50.1rem);
            //background-position: 0 7%;
            background-repeat: no-repeat;
            background-size: 132rem 102rem;
            //background-size: 151% 211%;
            position: absolute;
            //left: 52%;
            left: 53%;
            top: 0;
            z-index: -1;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding-top: 8.4rem;
        @include breakpoint(medium down) {
            display: block;
            padding-top: 4.5rem;
        }
    }

    &__discover {
        flex: 0 1 auto;
        //width: calc(50% + 22.5rem);
        width: 66%;
        @include breakpoint(medium down) {
            width: 100%;

            + .research-wrapper__know-how {
                @include breakpoint(medium only) {
                    margin-top: -33.6rem;
                }
                @include breakpoint(small down) {
                    margin-top: -8.3rem;
                }
            }
        }
    }

    &__know-how {
        flex: 0 1 auto;
        //width: calc(50% - 22.5rem);
        width: 34%;
        @include breakpoint(medium down) {
            width: 100%;
        }
    }
}
