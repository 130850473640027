// @name Videos
.videos {
    margin: 5em 0;
    max-width: 70rem;
    @include breakpoint(medium down) {
        margin-left: auto;
        margin-right: auto;
    }
    @include breakpoint(small down) {
        margin: 4em 0;
        display: flex;
        flex-wrap: wrap;
    }
}

.video__title {
    $video__title__font-size: 1.6;
    font-family: $typo-1;
    //font-weight: $light;
    font-weight: $medium;
    font-size: #{$video__title__font-size}em;
    line-height: 1.2;
    color: $color-3--3;
    text-align: left;
    margin-bottom: em(1, $video__title__font-size);
    width: 100%;
    @include breakpoint(small down) {
        order: 2;
        font-size: 1.2em;
        text-align: right;
        margin: em(1, 1.2) 0 0;
    }
}

.videos__item {
    position: relative;
    width: 100%;
    margin: 0 auto;
    @include breakpoint(small down) {
        order: 1;
    }
    video {
        width: 100%;
    }
    .videos__ratio + iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .iframe {
        left: 0;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        border: 0;
        margin: 0;
    }

    iframe {
        height: 100%;
        position: absolute;
        width: 100%;
    }
}

.videos__ratio {
    display: block;
    width: 100%;
    height: auto;
}

.ce-textpic {
    .videos,
    .videos__item {
        margin: 0;
    }
}

// @name Videos for popin
// @description if only one, no margin
.popin {
    #content {
        .setPos {
            &:only-of-type {
                .videos {
                    margin: 0;
                    .videos__item {
                        &:only-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
