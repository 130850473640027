.list-type-1--event {
    .list-type-1__picture,
    .list-type-1__wrapper-date,
    .list-type-1__wrapper {
        display: table-cell;
        vertical-align: top;
        @include breakpoint(small down) {
            display: block;
            vertical-align: inherit;
        }
    }
    .list-type-1__picture {
        float: none;
        img {
            @include breakpoint(small down) {
                margin-right: 0;
            }
        }
    }
    .list-type-1__wrapper-date {
        padding-right: 2em;
        width: 13em;
        border-right: 1px solid $color-3--3;
        @include breakpoint(small down) {
            padding-right: 0;
            border-right: none;
            //display: table;
            width: 100%;
            border-bottom: 1px solid $color-3--3;
            padding-bottom: 2rem;
            margin-bottom: 2em;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;

            .hour-place {
                //width: 50%;
                //display: table-cell;
                //vertical-align: middle;
                //padding-bottom: 2em;
                max-width: calc(100% - 11rem);
                padding-left: 1rem;
            }
        }

        .date-1 {
            width: 11rem;
            height: 11rem;
            border-radius: 50%;
            background: $color-1--3;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.6rem 0.6rem 0 0 rgba(#111212, 0.17);
            line-height: 10.5rem;
            margin: 0;
            padding: 0;
            color: $color-white;
            margin-bottom: 1.5rem;
            @include breakpoint(small down) {
                margin-bottom: 0;
                //time:nth-of-type(1) {
                //    margin-left: 0;
                //}
            }

            time {
                margin-top: .5rem;

                & + span + time:before {
                    color: $color-white;
                }

                &:only-of-type {
                    margin-top: 0;
                }
            }
        }
    }

    .list-type-1__wrapper {
        padding-left: 2em;
        @include breakpoint(small down) {
            padding-left: 0;
        }
    }
}
/*
@include breakpoint(medium down) {
    .list-type-1--event {
      .list-type-1__picture {
        img {
          margin-right: 2em;
        }
      }
      .list-type-1__wrapper-date {

        display: table-cell;
        vertical-align: top;
        width: 13em;
        border-bottom: 0;
        margin-bottom: 0;
        .date-1, .hour-place {
          width: auto;
          display: block;
          vertical-align: inherit;
          padding-bottom: 0;
        }
        .date-1 {
          text-align: center;
          time:nth-of-type(1) {
            margin-left: 1em;
          }
        }
      }
      .list-type-1__wrapper {
        padding-left: 2em;
      }
    }
}
*/
