// Telecharger
.telecharger {
    padding: 0;

    &__list {
        list-style: none;
        background: rgba($color-2--2, .2);
        display: flex;
        flex-wrap: wrap;
        padding: 1.6rem 2.6rem;
        position: relative;
        @include breakpoint(medium down) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @include breakpoint(small down) {
            padding: 1.5rem 0.5rem;
        }

        &:before {
            content: "";
            display: block;
            width: 0.1rem;
            height: calc(100% - 6rem);
            background: #afafaf;
            position: absolute;
            top: 3rem;
            left: calc(50% - 1.4rem);
            @include breakpoint(small down) {
                display: none;
            }
        }
    }

    &__item {
        list-style: none;
        width: 50%;
        padding: 1.4rem 4.4rem 1.4rem 1.4rem;
        display: flex;
        align-items: center;
        min-height: 6.8rem;
        @include breakpoint(small down) {
            width: 100%;
            padding-left: .3rem;
            padding-right: .3rem;
        }

        &:nth-child(even) {
            padding-right: 1.4rem;
        }
    }

    &__link {
        display: block;
        padding: 0.3rem 0 0.4rem 0;
        margin-left: 4.2rem;
        text-decoration: none;
        font-weight: $light;
        font-size: 1.6rem;
        line-height: 1em;
        color: $color-black;
        position: relative;
        word-break: break-word;

        &--file-size {
            font-size: 1.2rem;
            white-space: nowrap;
        }

        &:hover {
            text-decoration: underline;
            &:before {
                text-decoration: none;
            }
        }

        &:before {
            content: '\e00e';
            font-family: "icons-project";
            font-size: 3.9rem;
            line-height: 1em;
            color: $color-1--3;
            position: absolute;
            left: -4.5rem;
            top: 50%;
            transform: translateY(-50%);
        }
        &[href$=".pdf"]:before {
            content: "\e008";
        }
        &[href$=".xls"]:before, &[href$=".xlsx"]:before {
            content: "\e009";
        }
        &[href$=".doc"]:before, &[href$=".docx"]:before {
            content: "\e00a";
        }
        &[href$=".ppt"]:before {
            content: "\e00b";
        }
        &[href$=".jpg"]:before {
            content: "\e00c";
        }
        &[href$=".zip"]:before {
            content: "\e00d";
        }
    }
}
