.doctor-card {
    @extend .clear-fix;

    &.page-doctor {
        .doctor-card__picture img {
            max-width: 220px;
            @include breakpoint(small down) {
                max-width: 100%;
            }
        }
    }

    &__picture {
        display: block;
        float: left;
        margin-right: 2rem;
        @include breakpoint(small down) {
            float: none;
            margin-right: 0;
            margin-bottom: 2em;
        }
        img {
            max-width: 220px;
            height: auto;
            @include breakpoint(medium down) {
                max-width: 120px;
            }
            @include breakpoint(small down) {
                max-width: 100%;
            }
        }
    }

    &__content {
        padding: .3rem;
        overflow: hidden;
    }

    &__name {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 2em;
        line-height: 1em;
        color: $color-black;
        margin: 0 0 .5rem;

        a {
            color: inherit;
        }
    }

    &__function {
        font-family: $typo-2;
        font-weight: $bold;
        font-style: italic;
        font-size: 1.6em;
        line-height: 1.2;
        color: $color-1--3;
        margin: 0.8rem 0;
    }

    &__specialty {
        font-family: $typo-1;
        font-weight: $normal;
        font-size: 1.6em;
        line-height: 1.2;
        color: $color-3--8;
        margin: 0.8rem 0;
    }

    &__cv {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 1.4em;
        line-height: 1.2;
        color: $color-3--4;
        text-transform: uppercase;
        margin: 1.5rem 0;

        &-title {
            margin-bottom: .5rem;
            color: $color-black;
        }

        &-list li {
            margin: .3rem 0;

            a {
                color: inherit;
                text-decoration: underline;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    &__services {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 2rem 0 -3rem;

        &-item {
            flex: 0 0 50%;
            margin-bottom: 4rem;
            @include breakpoint(small down) {
                flex-basis: 100%;
            }

            &:nth-child(odd) {
                padding-right: 2rem;
                @include breakpoint(small down) {
                    padding-right: 0;
                }
            }
        }
    }

    &__service {
        &-category {
            font-family: $typo-2;
            font-weight: $bold;
            font-style: italic;
            font-size: 1.4em;
            line-height: 1.2;
            color: $color-1--3;
            margin: 0.2rem 0;
        }

        &-title {
            font-family: $typo-1;
            font-weight: $bold;
            font-size: 1.8em;
            line-height: 1.2em;
            color: $color-black;
            margin: 0.2rem 0 .5rem;

            a {
                color: inherit;
            }
        }

        &-infos {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: center;
            justify-content: space-between;

            .list-infos__item {
                margin: .7rem 0;
                padding-left: 2rem;
            }

            .list-infos__address {
                flex: 1 0 100%;
            }

            .list-infos__phone {
                flex: 1 0 auto;
                padding-right: 2rem;
            }

            .list-infos__email {
                flex: 0 0 auto;
                padding-left: 0;

                &:before {
                    display: none;
                }

                a {
                    text-decoration: none;
                }

                .button-1 {
                    font-size: 1.4rem;

                    &:before {
                        content: "\e029";
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}
