
.rte {
    // Layout
    &.cm-wrapper {

    }

    // Generic
    .entiteImageFloatLeft {
        float: left;
        margin: .5rem 1rem 1rem 0;
    }
    .entiteImageFloatRight {
        float: right;
        margin: .5rem 0 1rem 1rem;
    }

    // Breadcrumb
    .breadcrumb.spFilDAriane {
        width: auto;
        display: block;
        vertical-align: inherit;
        margin-bottom: 4rem;
        p {
            a {
                text-decoration: none;
                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    // Paragraphs
    .spIntroduction p, .spDescription {
        $teaser-1__font-size: 1.8;
        font-size: #{$teaser-1__font-size}em;
        margin: em(1.1, $teaser-1__font-size) 0;
        color: $color-3--4;
    }

    // Specific paragraph blocs
    .spAttention, .spANoter, .spASavoir {
        overflow: hidden;
        margin: 2rem 0;
        padding: 1.5rem 1.5rem 1.5rem 4.5rem;
        background: $color-3--1;
        position: relative;
        *[aria-hidden="true"] {
            display: none;
        }
        @include default-icons-absolute-before('\e039', 1.8rem, $color-black, 1.5rem, inherit, inherit, 1.5rem);
        &:before {

        }
        p:first-of-type {
            margin-top: 0;
        }
        p:last-of-type {
            margin-bottom: 0;
        }
    }
    .spAttention {
        background: lighten($color-red, 45%);
        &:before {
            content: "\e088";
        }
    }
    .spANoter {
        background: lighten($color-blue, 55%);
        &:before {
            content: "\e0a5";
        }
    }
    .spASavoir {
        background: lighten($color-green, 65%);
    }

    // Home menu
    .co-home-menu {
        margin: 2rem 0;
        font-size: 1rem;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        &.pro {
            > * {
                flex: 1 1 33.33334%;
            }
        }
        > * {
            flex: 1 1 20%;
        }
        > li {
            padding: 1.5rem;
            border: .1rem solid $color-3--2;
            font-size: 1rem;
            margin: 0 -1px -1px 0;
            &:before {
                content: "";
                display: none;
            }
            h3 {
                font-size: 1.8rem;
                color: $color-1--2;
                text-transform: uppercase;
                font-weight: $light;
                margin: 0 0 1rem;
                a {
                    color: $color-1--2;
                    text-decoration: none;
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
        .co-home-sousmenu {
            > li {
                display: inline;
                padding: 0;
                &:before {
                    content: "";
                    display: none;
                }
                a {
                    color: $color-black;
                    text-decoration: none;
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .co-home-img {
        display: none;
    }
}
