// @name Footer
.footer {
    background: $color-3--5;
    .footer__wrapper {
        width: 100%;
        max-width: 150rem;
        margin: 0 auto;
        padding: 7em 2em 9em;
        position: relative;
        @include breakpoint(medium down) {
            max-width: 98rem;
            padding: 2.2em 2em 4.5em;
        }
        @include breakpoint(small down) {
            max-width: 32rem;
            padding: 2.4rem 1rem 7rem 2rem;
        }

        &:before {
            content: "";
            display: block;
            width: 36.5rem;
            height: 100%;
            background-image: url(../Images/custom/bg/bg-h-footer-grey.svg);
            background-position: top center;
            background-repeat: no-repeat;
            background-size: 100% auto;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: 12rem;
            @include breakpoint(medium down) {
                margin-left: 0;
                left: calc(50% - 2.5rem);
            }
            @include breakpoint(small down) {
                height: 34rem;
                top: calc(100% - 34rem);
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .footer__wrapper-2 {

        //> * {
        //    flex: 1 1 100%;
        //    margin: 0 1.5em;
        //    @include breakpoint(small down) {
        //        flex: inherit;
        //        margin: 0 0 2em 0;
        //    }
        //}

        //margin: 0 -1.5em;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        //justify-content: space-between;
        width: 100%;
        @include breakpoint(medium down) {
            flex-wrap: wrap;
            align-content: flex-start;
            justify-content: space-between;
        }
        @include breakpoint(small down) {
            flex-direction: column;
            justify-content: flex-start;
        }
        //@include breakpoint(small down) {
        //    margin: 0;
        //    display: block;
        //}
    }

    &__logo {
        flex: 0 1 auto;
        //width: 25.6rem;
        padding-top: .7rem;
        @include breakpoint(medium only) {
            order: 1;
            flex: 0 0 auto;
            width: 23.6rem;
            padding-top: 1rem;
        }
        @include breakpoint(small down) {
            width: 20.5rem;
            padding: 0 0 1.6rem 1rem;
        }
    }

    &__menu-cross {
        flex: 0 0 auto;
        width: 26.5rem;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        @include breakpoint(medium only) {
            order: 4;
            flex: 1 1 auto;
            width: calc(100% - 36rem);
            padding: 3.9rem 0 0;
            flex-direction: row;
            flex-wrap: nowrap;
        }
        @include breakpoint(small down) {
            width: 100%;
            padding: 0 0 3.7rem 1rem;
        }
    }

    &__newsletter {
        flex: 0 0 auto;
        width: 33rem;
        padding-right: 3.5rem;
        position: relative;
        @include breakpoint(medium only) {
            order: 3;
            width: 36rem;
            padding-top: 3.5rem;
            padding-left: .8rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            padding: 0 0 5.5rem 0;
        }
    }

    &__site-infos {
        flex: 1 1 auto;
        //width: calc(100% - 85.1rem);
        padding-top: .5rem;
        position: relative;
        z-index: 2;
        @include breakpoint(medium only) {
            order: 2;
            flex: 0 0 auto;
            width: calc(100% - 23.6rem);
        }
        @include breakpoint(small down) {
            padding: 0 0 0 1rem;
        }

    }
}

.footer-logo {
    display: inline-block;

    img {
        vertical-align: top;
        max-width: 100%;
    }
}
