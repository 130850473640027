.page-banner {
    overflow: hidden;
    height: 54rem;

    @include breakpoint(medium down) {
        height: 45rem;
    }

    @include breakpoint(small down) {
        height: 21.5rem;
    }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;

        img {
            display: block;
            min-width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }
}
