//.go-to-top {
//    margin-top: -4.1em;
//    display: block;
//    width: 100%;
//    position: relative;
//    right: 1%;
//    bottom: 0;
//    z-index: 7000;
//    p {
//        text-align: right;
//    }
//    a {
//        &:before {
//            content: "\e015";
//        }
//    }
//}

.go-to-top {
    margin-top: -0.1em;
    height: 0.1rem;
    display: block;
    width: 100%;
    position: relative;
    right: 2rem;
    bottom: 0;
    z-index: 7000;
    text-align: right;
    @include breakpoint(medium down) {
        display: none!important;
    }

    a {
        display: inline-block;
        vertical-align: top;
        margin-top: -6rem;
        &:before {
            content: "\e015";
        }
    }
}
