.content-localiser {

    &__to-cart-link {
        display: block;
        width: 100%;
        font-family: $typo-1;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 3.5rem;
        color: $color-white;
        text-transform: uppercase;
        text-decoration: none;
        background: $color-1--3;
        text-align: center;
        padding: 1.7rem 2rem;
        transition: all ease .3s;

        &:before {
            display: inline-block;
            vertical-align: top;
            content: "\e04d";
            font-family: icons-default;
            font-size: 3.2rem;
            color: $color-white;
            margin-right: 1.2rem;
        }

        &:hover, &:focus {
            text-decoration: none;
            background: $color-3--3;
        }

        @include breakpoint(medium up) {
            display: none;
        }
    }

    .map-content--localiser {
        @include breakpoint(small down) {
            display: none;
        }
    }
}
