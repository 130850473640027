.list-type-1--sites {
    .list-type-1 {

        &__wrapper {
            overflow: visible;
        }

        &__item {
            margin: 3rem 0;
            padding: 0 0 2em;
            border-bottom: .1rem solid $color-3--8;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        &__teaser {
            margin-bottom: 0.6rem;
        }
    }

    .sites-link-map  {
        margin-top: 0.6rem;
        @include breakpoint(small down) {
            margin-top: 1rem;
        }
    }

    .list-infos {

        &__wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            flex: 0 1 33.333%;
            padding-right: 2.5rem;
            @include breakpoint(small down) {
                flex: 0 0 100%;
                padding-right: 0;
            }
        }
    }
}
