@charset "UTF-8";

// Site factory testing
//@import "00-configuration/configuration-sitefactory";

// 00-configuration
@import "00-configuration/_configuration.scss";
@import "00-configuration/_mixins.scss";
@import "00-configuration/_framework.scss";
@import "00-configuration/_base.scss";
@import "00-configuration/_grillade.scss";
@import "00-configuration/_bp.scss";
// Desktop view [0 ; ∞]
// 01-utils
@import "01-utils/_reset.scss";
@import "01-utils/_styleguide.scss";
// 02-lib
@import "02-lib/_drop-down-menu.scss";
@import "02-lib/_access-tooltip.scss";
@import "02-lib/_accessible-autocomplete-list-aria.scss";
@import "02-lib/_tooltip-css.scss";
@import "02-lib/_fancybox.scss";
@import "02-lib/_datepicker.scss";
@import "02-lib/_jquery.timepicker.scss";
@import "02-lib/_service-public.scss";
@import "02-lib/_jquery.jscrollpane.scss";
@import "02-lib/_slick.scss";
// 03-partials
@import "03-partials/_forms.scss";
@import "03-partials/_buttons.scss";
@import "03-partials/_links.scss";
@import "03-partials/_wrappers.scss";
@import "03-partials/_layouts.scss";
@import "03-partials/_text.scss";
@import "03-partials/_heading.scss";
@import "03-partials/_titles.scss";
@import "03-partials/_pagers.scss";
@import "03-partials/_progressbar.scss";
@import "03-partials/_filters.scss";
@import "03-partials/_rte.scss";
@import "03-partials/_typo3.scss";
@import "03-partials/menus/_menu-skip.scss";
@import "03-partials/menus/_menu-stratis.scss";
@import "03-partials/menus/_menu-main.scss";
@import "03-partials/menus/_menu-cross.scss";
@import "03-partials/menus/_menu-main-1.scss";
@import "03-partials/lists/_lists.scss";
@import "03-partials/lists/_list-content.scss";
@import "03-partials/lists/_list-type-1.scss";
@import "03-partials/lists/_list-type-1--event.scss";
@import "03-partials/lists/_list-type-1--glossary.scss";
@import "03-partials/lists/_list-type-1--poll.scss";
@import "03-partials/lists/_list-type-1--home-news.scss";
@import "03-partials/lists/_list-type-1--home-events.scss";
@import "03-partials/lists/_list-type-1--search.scss";
@import "03-partials/lists/_list-type-2.scss";
@import "03-partials/lists/_list-type-2--albums.scss";
@import "03-partials/lists/_list-type-2--publications.scss";
@import "03-partials/lists/_list-type-3.scss";
@import "03-partials/lists/_list-type-4.scss";
@import "03-partials/singles/_single.scss";
@import "03-partials/singles/_single--news.scss";
@import "03-partials/singles/_single--poll.scss";
@import "03-partials/objects/_breadcrumb.scss";
@import "03-partials/objects/_tools.scss";
@import "03-partials/objects/_cookies.scss";
@import "03-partials/objects/_logo.scss";
@import "03-partials/objects/_stratis.scss";
@import "03-partials/objects/_page-number.scss";
@import "03-partials/objects/_form-navigation.scss";
@import "03-partials/objects/_galeries.scss";
@import "03-partials/objects/_parallax.scss";
@import "03-partials/objects/_videos.scss";
@import "03-partials/objects/_click-roll.scss";
//@import "03-partials/objects/_go-so-far.scss";
@import "03-partials/objects/_popin-homepage.scss";
@import "03-partials/objects/_print-banner.scss";
@import "03-partials/objects/_newsletter.scss";
@import "03-partials/objects/_search.scss";
@import "03-partials/objects/_go-to-top.scss";
@import "03-partials/objects/_subpages.scss";
@import "03-partials/objects/_sitemap.scss";
@import "03-partials/objects/_list-document.scss";
@import "03-partials/objects/_list-infos.scss";
@import "03-partials/objects/_comments.scss";
@import "03-partials/objects/_site-infos.scss";
//@import "03-partials/objects/_bloc-news.scss";
//@import "03-partials/objects/_bloc-event.scss";
//@import "03-partials/objects/_bloc-publications.scss";
//@import "03-partials/objects/_bloc-directory.scss";
//@import "03-partials/objects/_bloc-contact.scss";

@import "03-partials/_section.scss";
@import "03-partials/_telecharger.scss";
@import "03-partials/_contact-card.scss";
@import "03-partials/_annuaire-card.scss";
@import "03-partials/_organigramme.scss";
@import "03-partials/_news-content.scss";
@import "03-partials/_publications.scss";
@import "03-partials/_go-on-read.scss";
@import "03-partials/_content-localiser.scss";
@import "03-partials/_page-banner.scss";
@import "03-partials/_sidebar.scss";
@import "03-partials/_top-menu.scss";
@import "03-partials/_layout-header.scss";
@import "03-partials/_layout-footer.scss";
@import "03-partials/_hero-carousel.scss";
@import "03-partials/_home-rapides.scss";
@import "03-partials/_home-appointment.scss";
@import "03-partials/_research-wrapper.scss";
@import "03-partials/_home-discover.scss";
@import "03-partials/_home-know-how.scss";
@import "03-partials/_home-enimages.scss";
@import "03-partials/_home-meeting.scss";
@import "03-partials/_events-wrapper.scss";
@import "03-partials/_home-news.scss";
@import "03-partials/_home-events.scss";
@import "03-partials/_home-socials.scss";
@import "03-partials/_home-publications.scss";
@import "03-partials/_home-newsletter.scss";
@import "03-partials/_documents-wrapper.scss";
@import "03-partials/_home-offer.scss";
@import "03-partials/_home-establishment.scss";
@import "03-partials/_service-section.scss";
@import "03-partials/_outpatient-care.scss";
@import "03-partials/_service-contact.scss";
@import "03-partials/_doctors.scss";

@import "03-partials/lists/_services-list.scss";
@import "03-partials/lists/_list-type-1--sites.scss";
@import "03-partials/lists/_list-type-1--doctors.scss";
@import "03-partials/lists/_list-type-1--consultations.scss";

@import "03-partials/objects/_link-view-more.scss";
@import "03-partials/objects/_icons.scss";
@import "03-partials/objects/_anchor-bar.scss";

@import "03-partials/_tarteraucitron.scss";


// 05-shame
@import "05-shame/_shame.scss";

/*
@media (max-width: #{$tablet}px) {
    // 01-utils
    @import "02-tablet/01-utils/_reset.scss";
    @import "02-tablet/01-utils/_styleguide.scss";

    // 02-lib
    @import "02-tablet/02-lib/_fancybox.scss";

    // 03-partials
    @import "02-tablet/03-partials/_forms.scss";
    @import "02-tablet/03-partials/_buttons.scss";
    @import "02-tablet/03-partials/_wrappers.scss";
    @import "02-tablet/03-partials/_heading.scss";
    @import "02-tablet/03-partials/_pagers.scss";
    @import "02-tablet/03-partials/_rte.scss";
    @import "02-tablet/03-partials/menus/_menu-skip.scss";
    @import "02-tablet/03-partials/menus/_menu-stratis.scss";
    @import "02-tablet/03-partials/menus/_menu-main-1.scss";
    @import "02-tablet/03-partials/lists/_list-type-1.scss";
    @import "02-tablet/03-partials/lists/_list-type-1--event.scss";
    @import "02-tablet/03-partials/lists/_list-type-2.scss";
    @import "02-tablet/03-partials/lists/_list-type-3.scss";
    @import "02-tablet/03-partials/objects/_search.scss";
    @import "02-tablet/03-partials/objects/_cookies.scss";
    @import "02-tablet/03-partials/objects/_go-so-far.scss";
    @import "02-tablet/03-partials/objects/_galeries.scss";
    @import "02-tablet/03-partials/objects/_list-document.scss";
    @import "02-tablet/03-partials/objects/_list-infos.scss";
    @import "02-tablet/03-partials/objects/_comments.scss";
    @import "02-tablet/03-partials/objects/_bloc-publications.scss";

    // 05-shame
    @import "02-tablet/05-shame/_shame.scss";
}*/
