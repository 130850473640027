.service-item {
    margin: 2rem 0;
    padding-bottom: 2em;
    border-bottom: .1rem solid $color-black;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    &__wrapper {
        margin-bottom: 5em;
    }

    &__title {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 2em;
        line-height: 1em;
        color: $color-black;
        margin: .25em 0 .8em;
    }

    &__description {
        font-size: 1.5em;
        line-height: 1.2em;
        color: $color-3--8;
        margin: 0.53333em 0;
    }

    //&__controls {
    //    text-align: right;
    //}

    &__bottom-block {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 1.2rem;

        .service-item__doctors-wrapper {
            flex: 1 1 auto;
            width: calc(100% - 16rem);
            @include breakpoint(small down) {
                flex-basis: 100%;
            }
        }

        .service-item__go-to-service {
            flex: 0 0 auto;
            max-width: 16rem;
            white-space: nowrap;
            @include breakpoint(small down) {
                margin-top: 1rem;
                max-width: none;
                flex-shrink: 1;
                white-space: normal;
            }
        }
    }

    &__doctors {
        font-weight: $normal;
        font-size: 1.4em;
        line-height: 1.2;
        color: $color-3--4;
        padding-right: 2rem;
        margin-bottom: .7rem;
        flex: 0 0 33.333%;
        @include breakpoint(small down) {
            flex-basis: 100%;
            padding-right: 0;
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: flex-start;
        }
    }
}
