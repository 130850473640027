.anchor-bar {

    $this: &;

    margin-bottom: 1.3rem;
    @include breakpoint(medium down) {
        //margin-bottom: 0;
        margin-bottom: 2rem;
    }

    h2 {
        margin-bottom: 1.2rem;
    }

    &__select {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 1.5rem;
        line-height: 1.2;
        color: $color-3--3;
        background-color: transparent;
        border: none;
        border-top: 5px solid $color-2--3;
        padding: 2rem 10rem 2rem 2rem;
        background: transparent url("../Images/custom/select-anchor-bar-arrow.png") no-repeat calc(100% - 4.5rem) 50%;
        @include breakpoint(small down) {
            padding-right: 4rem;
            padding-left: 1rem;
            background-position: calc(100% - 1.7rem) 50%;
        }

        option {
            font-size: 1.5rem;
            font-weight: $light;
            color: $color-3--4;
            margin-left: 2rem;
            @include breakpoint(small down) {
                margin-left: 1rem;
            }
        }
    }

    &__item {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 1.5rem;
        line-height: 1.2;
        color: $color-3--3;
        text-align: left;
        white-space: normal;
        padding: 2.3rem 2.3rem 1.7rem;
        position: relative;
        width: 100%;
        box-shadow: inset 0 5px 0 0 rgba($color-3--2, .3);
        transition: all 300ms ease;

        &.is-current {
            font-weight: $bold;
            color: $color-3--4;
        }

        //&:last-child {;
            //box-shadow: inset 0 5px 0 0 $color-1--2;
        //}

        .toc-bar {
            position: absolute;
            left: 0;
            top: 0;
            height: 5px;
            background-color: $color-2--3;
        }
    }
}
