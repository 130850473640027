.list-type-1--consultations {

    .list-type-1__wrapper {
        overflow: visible;
    }

    .list-type-1__item {
        margin: 2rem 0;
        padding-bottom: 2em;
        border-bottom: .1rem solid $color-black;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }

        &__wrapper {
            margin-bottom: 5em;
        }
    }

    .consultations-infos {
        margin-top: 1.6rem;

        &__item {
            font-family: $typo-1;
            font-weight: $normal;
            font-size: 1.4em;
            line-height: 1.2;
            color: $color-3--4;
            margin: .8rem 0;

            &-title {
                font-weight: $bold;
            }

            a {
                color: inherit;
                text-decoration: underline;

                &:hover, &:focus {
                    text-decoration: none;
                }

                &.consultations-go-service {
                    text-decoration: none;
                }
            }

            &--doctors {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                align-items: flex-start;
                justify-content: space-between;

                .consultations-infos__item-title {
                    display: block;
                    flex: 1 0 100%;
                }

                .consultations-doctors {
                    flex: 1 1 auto;
                    width: calc(100% - 19.5rem);
                    margin-top: 1.5rem;
                    @include breakpoint(small down) {
                        flex-basis: 100%;
                    }
                }

                .consultations-go-service {
                    flex: 0 0 auto;
                    max-width: 19.5rem;
                    margin-top: 1.5rem;
                    @include breakpoint(small down) {
                        max-width: none;
                        flex-shrink: 1;
                    }
                }
            }
        }
    }

    .consultations-go-service {
        font-size: 1.4rem;
        //white-space: nowrap;
        text-decoration: none;
    }

    .consultations-doctors {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;

        &__item {
            flex: 0 0 33.333%;
            padding-right: 2rem;
            margin-bottom: .7rem;
            @include breakpoint(small down) {
                flex-basis: 100%;
                padding-right: 0;
            }
        }
    }
}
