.meeting-article {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 66rem;
    @include breakpoint(medium down) {
        max-width: none;
    }
    @include breakpoint(small down) {
        display: block;
    }

    &__picture {
        flex: 0 0 auto;
        display: block;
        //width: 32.5rem;
        width: 49%;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05);
        margin-right: 3rem;
        @include breakpoint(medium down) {
            width: 32.5rem;
            margin-right: 2.5rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            margin: 0 0 2.3rem;
        }

        img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: none;
        }
    }

    &__content {
        flex: 1 1 auto;
        padding: 2rem 0;
        @include breakpoint(small down) {
            padding: 0;
        }
    }

    &__category {
        font-family: $typo-2;
        font-style: italic;
        //font-weight: $bold;
        font-weight: $medium;
        font-size: 1.8rem;
        line-height: 1.2em;
        color: $color-white;
        text-transform: uppercase;
        margin-bottom: 1.3rem;
        @include breakpoint(medium down) {
            font-size: 1.6rem;
        }
        @include breakpoint(small down) {
            margin-bottom: 1rem;
        }
    }

    &__title {
        font-family: $typo-1;
        font-weight: $normal;
        font-size: 2.7rem;
        line-height: 1.2em;
        color: $color-white;
        @include breakpoint(medium down) {
            font-size: 2.3rem;
        }
        @include breakpoint(small down) {
            font-size: 2rem;
        }

        a {
            color: inherit;
            outline-color: $color-white;

            &:focus {
                outline-color: $color-white;
            }
        }
    }
}



.meeting-infos {

    &__item {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        max-width: 100%;

        &:not(:last-child) {
            margin-bottom: 2.8rem;
            @include breakpoint(small down) {
                margin-bottom: 3rem;
            }
        }

        &:first-child {
            @include breakpoint(medium only) {
                margin-left: 8rem;
            }
        }

        &:nth-child(2) {
            @include breakpoint(medium only) {
                margin-left: 4rem;
            }
        }
    }

    &__icon {
        flex: 0 0 auto;
        display: block;
        width: 6rem;
        height: 6rem;
        margin-right: 2rem;
        text-align: center;
        line-height: 6rem;

        img {
            display: inline;
            vertical-align: middle;
            line-height: 1;
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 6rem;
        }

        svg {
            vertical-align: top;
            line-height: 1;
            width: 6rem;
            height: 6rem;
            fill: $color-2--1;
        }
    }

    &__content {
        flex: 1 1 auto;
    }

    &__category {
        font-family: $typo-2;
        font-style: italic;
        //font-weight: $bold;
        font-weight: $medium;
        font-size: 1.6rem;
        line-height: 1.2em;
        color: $color-white;
        text-transform: uppercase;
        margin-bottom: .1rem;
        @include breakpoint(small down) {
            font-size: 1.4rem;
        }
    }

    &__title {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 1.8rem;
        line-height: 1.2em;
        color: $color-white;
        @include breakpoint(small down) {
            font-size: 1.6rem;
        }

        a {
            color: inherit;
            outline-color: $color-white;

            &:focus {
                outline-color: $color-white;
            }
        }
    }
}



.meeting-indicators {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5rem 5rem 4rem;
    width: 34rem;
    height: 34rem;
    border-radius: 50%;
    background: $color-2--8;
    box-shadow: inset -0.8rem 0.8rem 0 0 $color-2--9;
    overflow: hidden;
    @include breakpoint(medium down) {
        width: 31.2rem;
        height: 31.2rem;
        padding: 3rem 2.5rem;
    }
    @include breakpoint(small down) {
        width: 22rem;
        height: 22rem;
        padding: 2.5rem;
    }

    &__number {
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 4.4rem;
        line-height: 1em;
        color: $color-black;
        max-width: 100%;
        @include breakpoint(medium down) {
            font-size: 3rem;
        }
        @include breakpoint(small down) {
            font-size: 2.4rem;
        }
    }

    &__description {
        font-family: $typo-1;
        font-weight: $light;
        font-size: 2.5rem;
        line-height: 1.2em;
        color: $color-black;
        margin-top: .2rem;
        max-width: 100%;
        @include breakpoint(medium down) {
            font-size: 2.2rem;
        }
        @include breakpoint(small down) {
            font-size: 1.5rem;
        }
    }
}



.meeting {
    position: relative;
    z-index: 10;
    background: $color-1--3;
    padding: 8.5rem 0 13rem;
    background-image: url(../Images/custom/bg/bg-h-letter-blue.svg);
    background-position: calc(50% + 40rem) 0;
    background-repeat: no-repeat;
    background-size: 84rem auto;
    @include breakpoint(medium down) {
        padding: 7.1rem 0 5rem;
        background-position: calc(50% + 28rem) -7rem;
        background-size: 97rem auto;
    }
    @include breakpoint(small down) {
        padding-top: 4rem;
        background-position: -4rem calc(100% + 36rem);
        background-size: 90rem auto;
    }

    .section-title {
        color: $color-white;
        margin: 0 0 2.5rem;
        @include breakpoint(medium down) {
            margin-bottom: 4rem;
        }
        @include breakpoint(small down) {
            margin-bottom: 2.5rem;
        }
    }

    &__inner {
        margin-right: -4.5rem;
        width: calc(100% + 4.5rem);
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        @include breakpoint(medium down) {
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            margin-right: 0;
            width: 100%;
        }
        @include breakpoint(small down) {
            display: block;
            width: 100%;
            max-width: 38rem;
            margin: 0 auto;
        }
    }

    &__article {
        flex: 1 1 auto;
        width: calc(75% - 34rem);
        padding: 2rem 5rem 2rem 0;
        @include breakpoint(medium down) {
            width: 100%;
            padding: 0 0 3.7rem;
        }
        @include breakpoint(small down) {
            padding-bottom: 6.3rem;
        }
    }

    &__infos {
        flex: 0 0 auto;
        width: 25%;
        padding: 0 5rem 0 0;
        @include breakpoint(medium down) {
            width: calc(100% - 31.2rem);
            padding-top: 1rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            padding: 0 0 4.2rem;
        }
    }

    &__indicators {
        flex: 0 0 auto;
        width: 34rem;
        margin: -3.8rem 0;
        @include breakpoint(medium down) {
            margin: 0;
            width: 31.2rem;
        }
        @include breakpoint(small down) {
            width: 22rem;
            margin: 0 auto;
        }
    }
}
