.appointment-control {
    display: inline-block;
    vertical-align: middle;
    //max-width: 100%;
    max-width: 27rem;
    white-space: nowrap;
    text-decoration: none;
    @include breakpoint(medium down) {
        max-width: 20rem;
    }
    @include breakpoint(small down) {
        max-width: none;
    }

    &:hover, &:focus {
        .appointment-control__icon {
            background: $color-3--3;
        }
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        width: 9rem;
        height: 9rem;
        line-height: 9rem;
        border-radius: 50%;
        text-align: center;
        background: $color-2--4;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.4rem 0.4rem 0 0 rgba($color-2--5, 0.17);
        margin-right: 1rem;
        transition: all 300ms ease;
        @include breakpoint(medium down) {
            width: 7rem;
            height: 7rem;
            line-height: 7rem;
        }
        @include breakpoint(small down) {
            width: 6rem;
            height: 6rem;
            line-height: 6rem;
        }

        svg, img {
            vertical-align: middle;
            line-height: 1;
            height: 2rem;
            width: 2rem;
            fill: $color-white;
        }

        + .appointment-control__text {
            max-width: calc(100% - 10rem);
            @include breakpoint(medium down) {
                max-width: calc(100% - 8rem);
            }
            @include breakpoint(small down) {
                max-width: calc(100% - 7rem);
            }
        }
    }

    &__text {
        font-family: $typo-2;
        font-style: italic;
        font-weight: $bold;
        font-size: 1.8rem;
        line-height: 1em;
        color: $color-3--4;
        text-transform: uppercase;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        white-space: normal;
        @include breakpoint(medium only) {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }
    }
}

.home-appointment {
    background: $color-2--3;
    overflow: hidden;
    position: relative;
    z-index: 2;

    &__inner {
        display: flex;
        flex-wrap: nowrap;
        min-height: 29.4rem;
        position: relative;
        z-index: 4;
        @include breakpoint(medium down) {
            align-items: flex-start;
            justify-content: flex-end;
            align-content: flex-start;
            flex-wrap: wrap;
            padding-bottom: 8.3rem;
            min-height: auto;
            background: transparent url(../Images/custom/bg/bg-home-appointment.png) left bottom no-repeat;
            background-size: 18rem 17.7rem;
        }
        @include breakpoint(small down) {
            margin: 0 -1rem;
            padding: 0;
            background: none;
            justify-content: flex-start;
        }
    }

    &__title {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 8;
        padding: 4rem 28rem 4rem 0;
        @include breakpoint(large up) {
            background: transparent url(../Images/custom/bg/bg-home-appointment.png) calc(100% - 7.3rem) bottom no-repeat;
        }
        @include breakpoint(medium down) {
            width: 100%;
            //padding: 1.7rem 0 5.3rem;
            padding: 4rem 0 5.3rem;
        }
        @include breakpoint(small down) {
            padding: 4.5rem 1rem 3.5rem;
            background: transparent url(../Images/custom/bg/bg-home-appointment.png) calc(100% - 1.5rem) calc(100% + 3rem) no-repeat;
            background-size: 16.5rem 15rem;
        }

        .section-title {
            margin: 0;
            line-height: 1em;
            @include breakpoint(small down) {
                font-size: 2.2rem;
            }

            &:after {
                background-color: #2e9b96;
            }
        }
    }

    &__control-box {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding: 2.5rem 0;
        @include breakpoint(medium down) {
            padding: 0;
        }
        @include breakpoint(small down) {
            width: 100%;
        }
    }

    &__control {
        position: relative;
        z-index: 6;
        padding-right: 2rem;
        @include breakpoint(medium only) {
            padding-right: 5rem;
        }
        @include breakpoint(small down) {
            padding: 2.5rem 4rem;
            background: #44aea8;
            width: 100%;
        }

        &:before {
            content: "";
            display: block;
            //width: calc(100% - 4.5rem);
            width: 100%;
            height: 60rem;
            margin-top: -30rem;
            //background: rgba($color-2--4, .2);
            background: #44aea8;
            position: absolute;
            left: 4rem;
            top: 50%;
            transform-origin: 0 50%;
            transform: skewX(-24.5deg);
            z-index: -1;
            @include breakpoint(medium down) {
                left: 3rem;
            }
            @include breakpoint(small down) {
                display: none;
            }
        }

        &--variation-1 {
            padding-right: .5rem;
            @include breakpoint(medium only) {
                padding-right: 3rem;
            }
            @include breakpoint(small down) {
                padding: 2.5rem 4rem;
                background: #3da19b;
            }

            &:before {
                background: #3da19b;
                width: 500rem;
            }
        }
    }
}
