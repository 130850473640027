.events-wrapper {
    padding: 8.8rem 0 5.5rem;
    overflow: hidden;
    @include breakpoint(medium down) {
        padding: 4.5rem 0 7rem;
    }
    @include breakpoint(small down) {
        padding: 4rem 0 5rem;
    }

    .section-title {
        margin-top: 0;
        @include breakpoint(small down) {
            font-size: 2.4rem;
            margin-bottom: 2.5rem;
        }
    }

    @include breakpoint(large up) {
        .section-home:last-child {
            .home-news__view-more, .home-events__view-more {
                position: relative;
                padding-right: 19rem;

                &:before {
                    content: "";
                    display: block;
                    width: 100rem;
                    height: 400rem;
                    background: rgba($color-3--1, .8);
                    position: absolute;
                    left: calc(100% - 22.4rem);
                    bottom: -6rem;
                    transform-origin: 0 calc(100% - 9.4rem);
                    transform: skewX(-25deg);
                    z-index: -1;
                }

                .link-view-more {
                    margin: 0;
                }
            }
        }

        //.wrapper-wide section:only-of-type {
        //    .home-news__view-more, .home-events__view-more {
        //        padding-right: 1.7rem;
        //
        //        &:before {
        //            left: calc(100% - 5.1rem);
        //        }
        //    }
        //}
    }
}
