// @name Pager list
.pager {
    width: 100%;
    ul {
        font-family: $typo-2;
        font-weight: $bold;
        font-style: italic;
        text-transform: uppercase;
        clear: both;
        display: block;
        margin: 3em 0 5em 0;
        padding: 0;
        text-align: center;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 em(0, 1.4);
        padding: 0;
        background: none;
        font-size: 1.4em;
        a, span {
            //padding: em(1, 1.4) em(1.4, 1.4);
            //line-height: 1em;
            padding: .8rem .8rem .8rem .5rem;
            min-width: 3.4rem;
            margin-top: 0.1rem;
            margin-bottom: 0.1rem;
            font-size: 1.4rem;
            line-height: 1.8rem;
            display: inline-block;
            vertical-align: middle;
        }
        a {
            color: $color-3--4;
            background: $color-3--5;
            text-decoration: none;
            transition: all ease .2s;
            &:hover, &:focus {
                color: $color-white;
                background: $color-3--3;
            }
        }
        span {
            cursor: default;
            color: $color-white;
            background: $color-1--9;
        }
        &.pager__prev,
        &.pager__next {
            min-width: 12rem;
            @include breakpoint(medium down) {
                min-width: auto;
            }
            a,
            span {
                background: none;
                min-width: 3.6rem;
                min-height: 3.6rem;
                padding: .9rem 0;
                //padding: em(1, 1.4) em(2.4, 1.4);
                margin-top: 0;
                margin-bottom: 0;
                position: relative;
                @include breakpoint(medium down) {
                    text-indent: -9999px;
                    white-space: nowrap;
                    overflow: hidden;
                    //padding: em(1, 1.4) em(1.8, 1.4);
                }
                &:hover, &:focus {
                    &:before, &:after {
                        color: $color-white;
                        background: $color-3--3;
                    }
                }
                &:before,
                &:after {
                    font-family: "icons-default";
                    font-size: 1.1rem;
                    font-style: normal;
                    line-height: 3.6rem;
                    color: $color-white;
                    text-indent: 0;
                    text-align: center;
                    display: inline-block;
                    vertical-align: middle;
                    width: 3.6rem;
                    height: 3.6rem;
                    border-radius: 50%;
                    background: $color-2--3;
                    box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.4rem 0.4rem 0 0 rgba(0, 69, 82, 0.14);
                    transition: all .2s ease;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -1.8rem;
                }
            }
            a {
                &:hover, &:focus {
                    color: $color-3--4;
                }
            }
            // Inactive
            span {
                color: $color-3--2;
                background: transparent;
                &:before, &:after {
                    color: $color-white;
                    background: $color-3--2;
                }
                &:hover, &:focus {
                    color: $color-3--2;
                    &:before, &:after {
                        color: $color-white;
                        background: $color-3--2;
                    }
                }
            }
        }
        &.pager__prev {
            float: left;
            margin-right: 1rem;
            a,
            span {
                float: left;
                padding-left: 4.8rem;
                @include breakpoint(medium down) {
                    padding-left: 3.6rem;
                }
                &:before {
                    content: '\e026';
                }
            }
            //span {
            //    color: $color-3--2;
                // Inactive
                //&:before,
                //&:hover:before,
                //&:focus:before {
                //    color: $color-3--2;
                //}
            //}
        }
        &.pager__next {
            float: right;
            margin-left: 1rem;
            a,
            span {
                float: right;
                padding-right: 4.8rem;
                @include breakpoint(medium down) {
                    padding-right: 3.6rem;
                }
                &:after {
                    content: '\e027';
                    left: auto;
                    right: 0;
                }
            }
            //span {
            //    color: $color-3--2;
            //    // Inactive
            //    &:after,
            //    &:hover:after,
            //    &:focus:after {
            //        color: $color-3--2;
            //    }
            //}
        }
    }
}

// @name Pager infinite scroll

@keyframes spin {
    0% {
        transform: rotate(0deg) translateY(-50%);
    }
    100% {
        transform: rotate(-360deg) translateY(-50%);
    }
}

.pager-infinite {
    margin: 2em 0;
    text-align: center;
    // @dependence .button-1
    .pager-infinite__button {
        &:before {
            content: "\e05f";
            background: $color-1--2;
        }

        &:hover:before, &:focus:before {
            background: $color-3--3;
        }
    }
    .pager-infinite__loading {
        &:before {
            animation: spin 1s infinite linear;
            content: "\e05f";
            transform-origin: top;
        }
    }
}

// @name Pager single
// @dependence .pager
.pager-single {
    .pager-single__back-to-list {
        a {
            background: transparent;
            min-width: 3.6rem;
            min-height: 3.6rem;
            padding: .9rem 0 .9rem 4.8rem;
            margin-top: 0;
            margin-bottom: 0;
            position: relative;
            text-align: left;
            @media(max-width: 400px) {
                max-width: 20rem;
            }
            &:hover, &:focus {
                color: $color-3--4;
                background: transparent;
                &:before, &:after {
                    color: $color-white;
                    background: $color-3--3;
                }
            }
            &:before {
                content: "\e02f";
                font-family: "icons-default";
                font-size: 1.1rem;
                font-style: normal;
                line-height: 3.6rem;
                color: $color-white;
                text-indent: 0;
                text-align: center;
                display: block;
                width: 3.6rem;
                height: 3.6rem;
                border-radius: 50%;
                background: $color-1--2;
                box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.4rem 0.4rem 0 0 rgba(0, 69, 82, 0.14);
                transition: all .2s ease;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -1.8rem;
            }
        }
    }
}

// @name Pager single title
// TODO

// @name Pager alphabet
.pager-alphabet {
    margin-bottom: 5em;
}

.pager-alphabet__title {
    font-size: 1.4em;
    font-weight: $bold;
    color: $color-3--4;
    margin-bottom: em(1.5, 1.4);
}

.pager-alphabet__listitems {
    text-align: left;
    > li {
        display: inline-block;
        vertical-align: middle;
        margin: .2em 0.1em;
        &.pager-alphabet__item--current {
            > a {
                background: $color-1--9;
                color: $color-white;
            }
        }
        &.pager-alphabet__item--double {
            min-width: 6.5rem;
        }
        > a, > span {
            $pager-alphabet__listitems__a__font-size: 1.4;
            font-family: $typo-2;
            font-weight: $bold;
            font-style: italic;
            font-size: #{$pager-alphabet__listitems__a__font-size}em;
            background: $color-3--5;
            color: $color-3--4;
            display: block;
            min-width: 3rem;
            text-align: center;
            padding: .6rem .5rem .6rem .4rem;
        }
        > a {
            &:hover, &:focus {
                background: $color-3--3;
                color: $color-white;
                text-decoration: none;
            }
        }
        > span {
            background: $color-white;
            border: 1px solid darken($color-3--1, 3%);
            color: darken($color-3--1, 15%);
        }
    }
}

// @name Pager single xl
.pager-single-xl {
    width: 100%;
    margin-bottom: 13em;
    ul {
        font-family: $typo-2;
        font-style: italic;
        font-weight: $light;
        clear: both;
        display: block;
        margin: 3em 0 5em 0;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        @extend .clear-fix;
    }

    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 em(0, 1.4);
        padding: 0;
        background: none;
        font-size: 1.4em;
        &.pager__prev,
        &.pager__next {
            width: 49%;
            .pager__button {
                @include breakpoint(medium down) {
                    text-indent: -9999px;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            a.pager__link {
                &:hover,
                &:focus {
                    .pager__button {
                        &:before,
                        &:after {
                            background: $color-3--3;
                        }
                    }
                }
            }
            span.pager__link {
                .pager__button {
                    color: $color-3--2;
                    background: transparent;
                    &:before, &:after {
                        background: $color-3--2;
                    }
                }
                &:hover,
                &:focus {
                    .pager__button {
                        &:before,
                        &:after {
                            background: $color-3--2;
                        }
                    }
                }
            }
        }
        &.pager__prev {
            float: left;
            .pager__button {
                padding-left: 4.8rem;
                @include breakpoint(medium down) {
                    padding-left: 3.6rem;
                }
                &:before {
                    content: "\e026";
                }
            }
            .pager__article {
                text-align: left;
                padding-left: 1rem;
            }
        }
        &.pager__next {
            float: right;
            .pager__button {
                padding-right: 4.8rem;
                @include breakpoint(medium down) {
                    padding-right: 3.6rem;
                }
                &:before {
                    content: "\e027";
                    left: auto;
                    right: 0;
                }
            }
            .pager__link {
                justify-content: flex-end;
            }
            .pager__article {
                text-align: right;
                padding-right: 1rem;
            }
        }
    }
    .pager__link {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-items: flex-start;
        //@include breakpoint(medium down) {
            //flex-wrap: wrap;
            //align-items: flex-start;
        //}
        .pager__button {
            flex: 0 0 auto;
        }
        .pager__article {
            align-self: center;
        }
    }
    a.pager__link {
        &:hover,
        &:focus {
            text-decoration: none;
            > .pager__button {
                color: $color-3--4;
                background: transparent;
            }
        }
        > .pager__button {
            color: $color-3--4;
            background: transparent;
            text-decoration: none;
            transition: all ease .3s;
        }
        span.pager__link {
            color: $color-3--2;
            background: transparent;
        }
    }
    .pager__button {
        font-weight: $bold;
        background: transparent;
        min-width: 3.6rem;
        min-height: 3.6rem;
        padding: .9rem 0;
        position: relative;
        white-space: nowrap;
        @include breakpoint(medium down) {
            //order: 1;
        }

        &:hover, &:focus {
            color: $color-3--4;
            background: transparent;
            &:before, &:after {
                color: $color-white;
                background: $color-3--3;
            }
        }

        &:before, &:after {
            font-family: "icons-default";
            font-size: 1.1rem;
            font-style: normal;
            line-height: 3.6rem;
            color: $color-white;
            text-indent: 0;
            text-align: center;
            display: block;
            width: 3.6rem;
            height: 3.6rem;
            border-radius: 50%;
            background: $color-2--3;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.4rem 0.4rem 0 0 rgba(0, 69, 82, 0.14);
            transition: all .2s ease;
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -1.8rem;
        }
    }
    .pager__article {
        text-transform: none;
        font-size: em(1.3, 1.4);
        color: $color-3--8;
        line-height: normal;
        display: inline-block;
        vertical-align: middle;
        @include breakpoint(medium down) {
            //order: 2;
        }
    }
    .pager-single-xl__back-to-list {
        position: absolute;
        top: 140%;
        left: 50%;
        transform: translateX(-50%);
        a.pager__link {
            display: inline-block;
            &:hover, &:focus {
                .pager__button:before {
                    background: $color-3--3;
                }
            }
            .pager__button {
                font-weight: $bold;
                color: $color-3--4;
                display: inline-block;
                min-width: 3.6rem;
                min-height: 3.6rem;
                background: transparent;
                padding: .9rem 0 .9rem 4.8rem;
                white-space: nowrap;
                position: relative;
                &:before {
                    content: "\e02f";
                    font-family: "icons-default";
                    font-size: 1.1rem;
                    font-style: normal;
                    line-height: 3.6rem;
                    color: $color-white;
                    text-indent: 0;
                    text-align: center;
                    display: block;
                    width: 3.6rem;
                    height: 3.6rem;
                    border-radius: 50%;
                    background: $color-1--2;
                    box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.4rem 0.4rem 0 0 rgba(0, 69, 82, 0.14);
                    transition: all .2s ease;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -1.8rem;
                }
            }
        }
    }
}
