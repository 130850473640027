.home-newsletter {

    .section-title {
        margin-top: 0;
        @include breakpoint(medium down) {
            margin-bottom: 2.4rem;
        }
        @include breakpoint(small down) {
            text-align: left;
            margin-bottom: 2.9rem;
            &:after {
                left: auto;
                right: calc(100% - 5rem);
            }
        }

        &:after {
            background: $color-white;
        }
    }

    &__subtitle {
        font-weight: $light;
        font-size: 2rem;
        line-height: 1.2;
        color: $color-3--4;
        margin-bottom: 4.5rem;
        padding-right: 5rem;
        @include breakpoint(medium down) {
            font-size: 1.5rem;
            margin-bottom: 2.2rem;
            padding-right: 0.5rem;
        }
        @include breakpoint(small down) {
            width: 100%;
            max-width: 40rem;
            margin-left: auto;
            margin-right: auto;
            padding-right: 0;
            font-size: 1.8rem;
        }
    }

    form {
        margin: 0;
        @include breakpoint(small down) {
            width: 100%;
            max-width: 40rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__fields {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
    }

    &__field-wrapper {
        flex: 1 1 auto;
        width: calc(100% - 7.7rem);

        label {
            font-weight: $bold;
            font-size: 2.2rem;
            line-height: 2.4rem;
            color: $color-3--4;
            margin: 0 0 .6rem;
            @include breakpoint(small down) {
                font-size: 1.6rem;
            }
        }

        input {
            padding: 1.6rem 1.8rem;
            background: $color-white;
            border-color: $color-2--3;
        }
    }

    &__button-wrapper {
        flex: 0 0 auto;
        width: 6.7rem;
        margin: 0 0 -.9rem 1rem;
    }

    &__button-submit {
        width: 6.7rem;
        height: 6.7rem;
        border-radius: 50%;
        background: $color-2--1;
        box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.6rem 0.6rem 0 0 rgba($color-2--11, 1);
        transition: all 300ms ease;

        &:hover, &:focus {
            background: $color-3--3;
            box-shadow: -0.4rem 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.05), inset -0.6rem 0.6rem 0 0 rgba(59, 57, 61, 0.4);
            svg {
                fill: $color-white;
            }
        }

        svg {
            width: 2.1rem;
            height: 2rem;
            fill: $color-3--4;
            transition: all 300ms ease;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 2.8rem;
        @include breakpoint(small down) {
            width: 100%;
            max-width: 40rem;
            margin: 2rem auto 0;
        }

        &-item {
            font-family: $typo-2;
            font-weight: $bold;
            font-size: 1.4em;
            line-height: 1.2em;
            color: $color-3--4;
            text-transform: uppercase;
            padding-left: 2.3rem;
            position: relative;
            @include breakpoint(small down) {
                padding-left: 2rem;
            }

            &:before {
                content: "";
                font-family: "icons-default";
                font-size: 1.1rem;
                line-height: 1.2em;
                color: $color-white;
                position: absolute;
                top: 0;
                left: 0.5rem;
                @include breakpoint(small down) {
                    left: 0;
                }
            }

            a {
                color: inherit;
            }

            &--archives:before {
                content: "\e03d";
            }

            &--unsubscribe:before {
                content: "\e058";
            }
        }
    }
}
